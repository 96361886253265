import { Deposit, DepositStore } from "@vaultinum/vaultinum-api";
import { BaseLang, BreadcrumbItems, Button, CreditCardIcon, Input, message, ModalHelper, Spin, Tag, useLang } from "@vaultinum/vaultinum-sdk";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import BaseInfoCard from "../../../components/BaseInfoCard";
import ModelItemPageHeader from "../../../components/ModelItemPageHeader";
import { DEPOSIT_PAGE, ESCROW_PAGE, getEscrowLink } from "../../../services/routing.service";
import { getDepositsByStores, getDepositStore, setPaymentMethodAsPaidOffline } from "../../../services/vaultService";
import Deposits from "../account/Deposits";

function getPaymentStatus(depositStore: DepositStore) {
    const { paymentMethod, subscriptionId } = depositStore;
    if (paymentMethod === Deposit.PaymentMethod.OFFLINE) {
        return <Tag type="default" message="OFFLINE" />;
    }

    if (subscriptionId && paymentMethod === Deposit.PaymentMethod.ONLINE) {
        return <Tag type="success" message="STRIPE" />;
    }

    return <Tag type="warning" message="PENDING" />;
}

function PaymentOfflineForm({ subscriptionIdRef }: { subscriptionIdRef: React.MutableRefObject<string> }) {
    const [subscriptionId, setSubscriptionId] = useState<string>(subscriptionIdRef.current);

    function onSubscriptionIdChange(e: React.ChangeEvent<HTMLInputElement>) {
        const newSubscriptionId = e.target.value;
        subscriptionIdRef.current = newSubscriptionId;
        setSubscriptionId(newSubscriptionId);
    }

    return (
        <div className="flex flex-col space-y-4">
            <span>Are you sure you want to set this deposit as paid offline?</span>
            <Input.Text
                value={subscriptionId}
                onChange={onSubscriptionIdChange}
                label="Subscription Id"
                placeholder="Insert any reference to the payment (stripe invoice id, transaction id, payment id...)"
            />
        </div>
    );
}

export default function DepositPage(): JSX.Element {
    const { depositStoreId, escrowId } = useParams<{ depositStoreId: string; escrowId: string }>();
    const lang = useLang<BaseLang>();
    const [deposits, setDeposits] = useState<Deposit[] | undefined>();
    const [depositStore, setDepositStore] = useState<DepositStore | null>();
    const subscriptionIdRef = useRef<string>("");

    useEffect(() => getDepositsByStores(depositStoreId ? [depositStoreId] : [], setDeposits), [depositStoreId]);
    useEffect(() => {
        if (!depositStoreId) {
            return;
        }
        const deposit = deposits?.find(deposit => deposit.depositStoreId === depositStoreId);
        if (!deposit) {
            return;
        }
        void (async () => {
            try {
                const store = await getDepositStore(deposit.owner.accountId, depositStoreId);
                if (!store) {
                    void message.error({ content: "Deposit store not found" });
                    return;
                }
                subscriptionIdRef.current = store.subscriptionId;
                setDepositStore(store);
            } catch (error) {
                void message.error({ content: "Failed to load deposit store" });
            }
        })();
    }, [depositStoreId, deposits]);

    if (!depositStoreId || !deposits) {
        return <Spin />;
    }

    const depositItems: BreadcrumbItems = [
        {
            label: "Deposits",
            href: DEPOSIT_PAGE
        },
        {
            label: depositStoreId
        }
    ];

    const escrowItems: BreadcrumbItems = [
        {
            label: "Escrows",
            href: ESCROW_PAGE
        },
        {
            label: escrowId || "",
            href: (escrowId && getEscrowLink(escrowId)) || ""
        },
        {
            label: "Deposits",
            href: (escrowId && getEscrowLink(escrowId)) || ""
        },
        {
            label: depositStoreId
        }
    ];

    async function updateDepositPaymentMethod() {
        if (!depositStore) {
            return;
        }
        try {
            await setPaymentMethodAsPaidOffline(depositStore, subscriptionIdRef.current);
            const updatedDeposits = deposits?.map(deposit => {
                if (deposit.depositStoreId === depositStoreId) {
                    return { ...deposit, paymentMethod: Deposit.PaymentMethod.OFFLINE };
                }
                return deposit;
            });
            setDeposits(updatedDeposits);
        } catch (error) {
            void message.error({ content: "Failed to update payment method" });
        }
    }

    function displayConfirmModal() {
        ModalHelper.Modal({
            title: "Payment confirmation",
            children: <PaymentOfflineForm subscriptionIdRef={subscriptionIdRef} />,
            onConfirm: updateDepositPaymentMethod,
            lang
        });
    }

    const isLatestVersionDeposit = (deposit: Deposit) => depositStore?.latestVersion === deposit.version;

    return (
        <div className="space-y-2">
            <ModelItemPageHeader breadcrumbItems={escrowId ? escrowItems : depositItems} />
            {deposits.filter(isLatestVersionDeposit).map(deposit => (
                <BaseInfoCard
                    key={deposit.iddn}
                    name="Deposit"
                    creationDate={deposit.createdDate}
                    attributes={{
                        paymentStatus: getPaymentStatus(depositStore ?? ({} as DepositStore)),
                        ...(depositStore?.subscriptionId && {
                            subscription: (
                                <a
                                    title="Go to Stripe dashboard"
                                    href={`${process.env.REACT_APP_STRIPE_DASHBOARD}/subscriptions/${depositStore.subscriptionId}`}
                                    className="link"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    children={depositStore.subscriptionId}
                                />
                            )
                        })
                    }}
                    footerRight={
                        <Button
                            type="default"
                            icon={CreditCardIcon}
                            children={
                                depositStore?.paymentMethod === Deposit.PaymentMethod.OFFLINE && !depositStore.subscriptionId
                                    ? "SET SUBSCRIPTION ID"
                                    : "SET AS PAID OFFLINE"
                            }
                            isLoading={false}
                            onClick={displayConfirmModal}
                            isDisabled={depositStore?.paymentMethod === Deposit.PaymentMethod.ONLINE && !!depositStore.subscriptionId}
                        />
                    }
                />
            ))}
            <Deposits deposits={deposits} isEscrowContext={!!escrowId} />
        </div>
    );
}
