import { SupportedLanguageCode, Survey } from "@vaultinum/vaultinum-api";
import {
    AddIcon,
    BaseLang,
    Button,
    Buttons,
    Column,
    DeleteIcon,
    Input,
    Modal,
    ModalHelper,
    Select,
    Table,
    UploadIcon,
    User,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import Flag from "../../../../components/Flag";
import { LANGS } from "../../../../constants";
import { deleteSurveyLang, newSurveyLang, setSurveyLang } from "../../../../services/surveyService";

function getColumns(lang: BaseLang, surveyVersion: Survey.Version, setSelectedUploadLang: (lang: SupportedLanguageCode) => void): Column<Survey.Lang>[] {
    return [
        {
            header: "Lang",
            accessorKey: "lang",
            size: 150
        },
        {
            header: "Name",
            accessorFn: row => row.lang,
            cell: cell => {
                const surveyLang = cell.row.original;
                return <span>{LANGS.find(lang => surveyLang.lang === lang.code)?.name}</span>;
            },
            size: 150
        },
        {
            header: "Actions",
            enableSorting: false,
            enableColumnFilter: false,
            cell: cell => {
                const surveyLang = cell.row.original;
                return (
                    <div className="flex gap-2">
                        <Buttons.Icon
                            isLoading={false}
                            type="default"
                            icon={UploadIcon}
                            onClick={() => setSelectedUploadLang(surveyLang.lang)}
                            title="Upload version"
                        />
                        <Button
                            isLoading={false}
                            type="default"
                            icon={DeleteIcon}
                            onClick={() =>
                                ModalHelper.Confirm({
                                    title: "Delete survey language",
                                    children: `This will delete the lang [${surveyLang.lang}] and all its translations`,
                                    onConfirm: () => deleteSurveyLang(surveyVersion.surveyKey, surveyVersion.version, surveyLang.lang),
                                    lang
                                })
                            }
                        />
                    </div>
                );
            },
            size: 100
        }
    ];
}

const UploadLangModal = ({
    surveyKey,
    surveyVersion,
    lang,
    close
}: {
    surveyKey: string;
    surveyVersion: number;
    lang: SupportedLanguageCode;
    close: () => void;
}) => {
    const baselang = useLang<BaseLang>();
    const [langJSON, setLangJSON] = useState("");
    const [working, setWorking] = useState(false);
    const onModalOk = async () => {
        setWorking(true);
        try {
            const surveyLang: Survey.Lang = JSON.parse(langJSON);
            await setSurveyLang(surveyKey, surveyVersion, lang, surveyLang);
            close();
        } finally {
            setWorking(false);
        }
    };
    return (
        <Modal size="md" title={`Upload survey lang: ${lang}`} okText="Upload" onConfirm={onModalOk} onClose={close} isLoading={working} isOpen lang={baselang}>
            <Input.TextArea value={langJSON} onChange={e => setLangJSON(e.target.value)} rows={6} disabled={working} />
        </Modal>
    );
};

export default function AddOrRemoveSurveyLangModal({
    user,
    isOpen,
    onClose,
    surveyVersion,
    surveyLangs
}: {
    user: User;
    isOpen: boolean;
    onClose: () => void;
    surveyVersion: Survey.Version;
    surveyLangs: Survey.Lang[];
}) {
    const lang = useLang<BaseLang>();
    const [selectedLang, setSelectedLang] = useState<null | SupportedLanguageCode>(null);
    const [selectedUploadLang, setSelectedUploadLang] = useState<null | SupportedLanguageCode>(null);

    const addLang = async (lang: SupportedLanguageCode) => {
        await newSurveyLang(surveyVersion.surveyKey, surveyVersion.version, lang, user.uid);
        setSelectedLang(null);
    };

    const columns = getColumns(lang, surveyVersion, setSelectedUploadLang);

    return (
        <>
            {selectedUploadLang && (
                <UploadLangModal
                    surveyKey={surveyVersion.surveyKey}
                    surveyVersion={surveyVersion.version}
                    lang={selectedUploadLang}
                    close={() => setSelectedUploadLang(null)}
                />
            )}
            <Modal isOpen={isOpen && !selectedUploadLang} onClose={onClose} title="Add or edit survey language" hideFooter={true} lang={lang}>
                <span className="float-right flex space-x-2 pb-2">
                    <Select.Basic
                        value={selectedLang}
                        onChange={setSelectedLang}
                        placeholder="Pick a language"
                        options={LANGS.filter(lang => !surveyLangs.some(surveyLang => surveyLang.lang === lang.code)).map(lang => ({
                            value: lang.code,
                            label: (
                                <span className="flex items-center">
                                    <Flag countryCode={lang.code} />
                                    {lang.name}
                                </span>
                            )
                        }))}
                    />
                    <Buttons.Icon
                        isLoading={false}
                        icon={AddIcon}
                        isDisabled={!selectedLang}
                        onClick={() => addLang(selectedLang as SupportedLanguageCode)}
                        title="Add new language"
                    />
                </span>
                <Table<Survey.Lang> data={surveyLangs} columns={columns} />
            </Modal>
        </>
    );
}
