import { New, Promotion, PROMOTIONS_COLLECTION, Update } from "@vaultinum/vaultinum-api";
import { collection, converter, DataEventSource, doc, doPost, doPut, getFirestore, getItem, onSnapshot } from "@vaultinum/vaultinum-sdk";
import { doDelete } from "../services/apiService";

const PROMOTION_ENDPOINT = "promotion";

const promotionListener = new DataEventSource<Promotion[]>(onUpdate =>
    onSnapshot(collection(getFirestore(), PROMOTIONS_COLLECTION).withConverter(converter<Promotion>()), querySnapshot =>
        onUpdate(querySnapshot.docs.map(doc => doc.data()))
    )
);

export async function getPromotion(promotionId: string): Promise<Promotion | null> {
    return getItem(doc(collection(getFirestore(), PROMOTIONS_COLLECTION), promotionId).withConverter(converter<Promotion>()));
}

export function getPromotions(onUpdate: (promotions: Promotion[]) => void): () => void {
    return promotionListener.addListener(onUpdate);
}

export function createPromotion(promotion: New<Promotion>): Promise<string> {
    return doPost(PROMOTION_ENDPOINT, promotion, process.env.REACT_APP_API_HOST);
}

export function updatePromotion(promotionId: string, promotion: Update<Promotion>): Promise<string> {
    return doPut(`${PROMOTION_ENDPOINT}/${promotionId}`, promotion, process.env.REACT_APP_API_HOST);
}

export function deletePromotion(promotionId: string): Promise<string> {
    return doDelete(`${PROMOTION_ENDPOINT}/${promotionId}`, process.env.REACT_APP_API_HOST);
}
