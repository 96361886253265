import { Promotion, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import {
    BaseLang,
    Button,
    Buttons,
    Column,
    copyToClipboard,
    DeleteIcon,
    EyeIcon,
    LinkIcon,
    ModalButton,
    openNotificationWithIcon,
    Spin,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDomains } from "../../../services/domainService";
import { deletePromotion, getPromotions } from "../../../services/promotionService";
import { PROMOTION_DETAIL_PAGE } from "../../../services/routing.service";
import { StandardModelListView } from "../components/StandardModelListView";
import PromotionModalWithButton from "./PromotionModalWithButton";
import { formatDates, formatJoinLink, formatOffer } from "./PromotionTool";

function getColumns(domains: WhiteLabelDomain[]): Column<Promotion>[] {
    return [
        {
            header: "Status",
            cell: cell => formatDates(cell.row.original)
        },
        {
            header: "Name",
            accessorKey: "name",
            cell: cell => cell.getValue<string>()
        },
        {
            header: "White Label Domain",
            accessorKey: "whiteLabelDomainId",
            cell: cell => <>{domains.find(domain => domain.id === cell.getValue<string>())?.fqdn}</>
        },
        {
            header: "Emails",
            accessorKey: "emails",
            cell: cell => Object.keys(cell.row.original.emails).length.toString(),
            enableColumnFilter: false
        },
        {
            header: "Offer",
            cell: cell => formatOffer(cell.row.original.offer)
        }
    ];
}

export default function PromotionsPage(): JSX.Element {
    const navigate = useNavigate();
    const lang = useLang<BaseLang>();
    const [isLoading, setIsLoading] = useState(false);
    const [domains, setDomains] = useState<WhiteLabelDomain[]>();

    useEffect(() => getDomains(setDomains), []);

    async function handlePromotionDeletion(promotion: Promotion) {
        setIsLoading(true);
        try {
            await deletePromotion(promotion.id);
            openNotificationWithIcon({ type: "success", description: "Promotion deleted successfully" });
        } catch {
            openNotificationWithIcon({ type: "error", description: "An error occured while deleting the promotion" });
        } finally {
            setIsLoading(false);
        }
    }

    function redirecToPromotion(promotionId: string) {
        navigate(PROMOTION_DETAIL_PAGE.replace(":promotionId", promotionId));
    }

    const extraActions = useCallback(
        (promotion: Promotion) => (
            <>
                <Buttons.Icon
                    isLoading={false}
                    type="default"
                    title="Copy promotion link"
                    onClick={() => copyToClipboard(formatJoinLink(promotion, domains?.find(domain => domain.id === promotion.whiteLabelDomainId)))}
                    icon={LinkIcon}
                />
                <Button isLoading={false} onClick={() => redirecToPromotion(promotion.id)} icon={EyeIcon} />
                <PromotionModalWithButton promotion={promotion} domains={domains} />
                <ModalButton.Confirm
                    title="Promotion deletion"
                    icon={DeleteIcon}
                    children="Are you sure you want to delete this promotion?"
                    onConfirm={() => handlePromotionDeletion(promotion)}
                    buttonProps={{
                        type: "default",
                        title: "Delete promotion",
                        isLoading: isLoading,
                        isDisabled: isLoading,
                        icon: DeleteIcon
                    }}
                    lang={lang}
                />
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [domains, isLoading]
    );

    if (!domains) {
        return <Spin />;
    }

    return (
        <>
            <div className="flex">
                <PromotionModalWithButton domains={domains} />
            </div>
            <StandardModelListView<Promotion>
                name="Promotions"
                getModelItems={getPromotions}
                columns={getColumns(domains)}
                extraActions={extraActions}
                storageKey="table-promotions"
                isVirtualized
            />
        </>
    );
}
