import { Account, LANG_EN, StaffUser, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { Column, Select, Spin, Tooltip, message, useIndustryList, useUrlSearch } from "@vaultinum/vaultinum-sdk";
import { keyBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import Flag from "react-flagkit";
import { Route, Routes } from "react-router-dom";
import { getAllAccounts, setAccountTags } from "../../../services/accountService";
import { getDomains } from "../../../services/domainService";
import { getAccountLink } from "../../../services/routing.service";
import { Domain } from "../components/Domain";
import { StandardModelListView } from "../components/StandardModelListView";
import AccountPage from "./AccountPage";

function isWhiteLabelOwner(accountId: string, whiteLabelDomain: WhiteLabelDomain): boolean {
    return accountId === whiteLabelDomain.ownerAccountId;
}

function formatCompanyName(account: Account, whiteLabelDomain: WhiteLabelDomain | null): JSX.Element {
    if (whiteLabelDomain && isWhiteLabelOwner(account.id, whiteLabelDomain)) {
        return (
            <Tooltip title={`Owner of ${whiteLabelDomain.fqdn}`} placement="bottom">
                <span className="font-bold text-slate-primary">{account.companyName}</span>
            </Tooltip>
        );
    }
    return <>{account.companyName}</>;
}

function renderNationality(companyNationality: string) {
    return <Flag country={companyNationality} alt={companyNationality} title={companyNationality} />;
}

function AccountTagsSelector({ account }: { account: Account }) {
    const [isLoading, setIsLoading] = useState(false);

    async function updateAccountTags(tags: Account.Tag[] | null) {
        setIsLoading(true);
        try {
            await setAccountTags(account, tags ?? []);
        } catch {
            void message.error("Failed to update account tags");
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Select.Multiple
            isLoading={isLoading}
            value={account.tags || []}
            onChange={updateAccountTags}
            options={[
                { label: "Ignored", value: Account.Tag.IGNORED },
                { label: "KYS Reviewer", value: Account.Tag.KYS_REVIEWER },
                { label: "Deposit API Account", value: Account.Tag.DEPOSIT_API_ACCOUNT }
            ]}
        />
    );
}

function AccountsList() {
    const industryList = useIndustryList(LANG_EN);
    const industryListIndex = useMemo(() => keyBy(industryList, industry => industry.value), [industryList]);
    const [domains, setDomains] = useState<WhiteLabelDomain[]>();
    const { domainId } = useUrlSearch() as { domainId?: string };
    const [whiteLabelDomain, setWhiteLabelDomain] = useState<WhiteLabelDomain | null>(null);

    useEffect(() => getDomains(setDomains), []);
    useEffect(() => setWhiteLabelDomain((domains || []).find(domain => domain.id === domainId) ?? null), [domains, domainId]);

    const columns = useMemo<Column<Account>[]>(
        () => [
            {
                header: "Company Name",
                cell: cell => formatCompanyName(cell.row.original, whiteLabelDomain),
                accessorKey: "companyName"
            },
            {
                header: "Industry",
                accessorFn: row => industryListIndex[row.companyIndustry]?.label ?? "-"
            },
            {
                header: "Domain",
                accessorKey: "whiteLabelDomainId",
                cell: cell => Domain({ domainId: cell.getValue<string>(), domains }),
                filters: [
                    {
                        label: "N/A",
                        value: null,
                        onFilter: row => !row.whiteLabelDomainId
                    },
                    ...(domains || []).map(domain => ({
                        label: domain.fqdn,
                        value: domain.id,
                        onFilter: (row: Account) => row.whiteLabelDomainId === domain.id
                    }))
                ],
                ...(domainId && { defaultFilteredValues: [domainId] }),
                size: 175
            },
            {
                header: "Nationality",
                accessorFn: row => row.companyNationality || "N/A",
                cell: cell => renderNationality(cell.getValue<string>()),
                size: 150
            },
            {
                header: () => "Tags",
                accessorKey: "tags",
                cell: cell => <AccountTagsSelector account={cell.row.original} />,
                size: 300
            }
        ],
        [industryListIndex, domains, domainId, whiteLabelDomain]
    );

    if (!domains) {
        return <Spin />;
    }

    return (
        <StandardModelListView<Account>
            name="Organisations"
            getModelItems={getAllAccounts}
            modelPageLink={account => getAccountLink(account)}
            columns={columns}
            searchOptions={{ hideIgnored: true }}
            {...(!domainId && { storageKey: "table-organisations" })}
            actionColumnSize={200}
            isVirtualized
        />
    );
}

export default function AccountsPage({ staffUser }: { staffUser: StaffUser }) {
    return (
        <Routes>
            <Route index element={<AccountsList />} />
            <Route path=":accountId" element={<AccountPage staffUser={staffUser} />} />
        </Routes>
    );
}
