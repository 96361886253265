import { Account, App, StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { message, SectionTitle, Select } from "@vaultinum/vaultinum-sdk";
import { setAppSetting } from "../../../services/accountService";

export default function DepositPlan({
    account,
    staffUser,
    appSettings,
    depositsSize
}: {
    account: Account;
    staffUser: StaffUser;
    appSettings?: Account.AppSettings.Deposit;
    depositsSize?: string;
}): JSX.Element {
    async function onPlanChange(value: App.Deposit.Plan | null) {
        try {
            await setAppSetting(account, App.Code.DEPOSIT, "plan", value);
            void message.success(`Deposit plan updated to ${value}`);
        } catch (error) {
            void message.error(`Error while updating deposit plan: ${error}`);
        }
    }

    return (
        <div className="flex place-content-between">
            <div>
                <SectionTitle title="Plan" />
                {staffUser.roles.includes(StaffUserRole.ADMIN) ? (
                    <Select.Basic
                        className="w-80"
                        options={Object.entries(App.Deposit.Plan).map(([key, value]) => ({ label: key, value }))}
                        onChange={onPlanChange}
                        value={appSettings?.plan}
                    />
                ) : (
                    appSettings?.plan
                )}
            </div>
            {!!depositsSize && (
                <div>
                    <SectionTitle title="Deposits total size" />
                    <div className="text-primary text-xl">{depositsSize}</div>
                </div>
            )}
        </div>
    );
}
