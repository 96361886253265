import { Column as TanstackColumn } from "@tanstack/react-table";
import classNames from "classnames";
import { useState } from "react";
import {
    Button,
    Buttons,
    CheckIcon,
    ColumnFilter,
    Dropdown,
    DropdownCommon,
    EMPTY_VALUES,
    EmptyFilter,
    FilterSortBgFilledIcon,
    FilterSortIcon,
    FunnelBgFilledIcon,
    IconProps
} from "..";
import { FilterController } from "../../../controllers/filter.controller";
import { useLang } from "../../../lang";
import { LocalStorageFilterSortService, SortDirection } from "../../../services";
import { Filters } from "./Filters";
import { CustomFilter } from "./TableTools";

function getIcon(isFiltered: boolean, isSorted: boolean): (props: IconProps) => JSX.Element {
    if (isFiltered) {
        return FunnelBgFilledIcon;
    }
    if (isSorted) {
        return FilterSortBgFilledIcon;
    }
    return FilterSortIcon;
}

function SortItem({ label, isActive, onClick }: { label: string; isActive: boolean; onClick: () => void }) {
    return (
        <div
            onClick={onClick}
            className={classNames("flex cursor-pointer items-center gap-2 px-4 py-3 text-slate-dark", {
                "bg-blue-extra-light": isActive,
                "hover:bg-grey-extra-light": !isActive
            })}
        >
            <span>{label}</span>
            {isActive && (
                <span className="ml-auto">
                    <CheckIcon color="slate" />
                </span>
            )}
        </div>
    );
}

export function FiltersSortDropdown<T extends object, V = unknown>({
    column,
    storageKey,
    onFiltersLazyLoad
}: {
    column: TanstackColumn<T, unknown> & { filters?: ColumnFilter<T>[] };
    storageKey?: string;
    onFiltersLazyLoad?: (columnKey: V) => Promise<unknown[]>;
}) {
    const filterController = storageKey ? new FilterController({ localStorageFilterSortService: new LocalStorageFilterSortService(storageKey) }) : undefined;
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [availableFilters, setAvailableFilters] = useState<(string | CustomFilter<T>)[]>([]);
    const columnFilters = column.getFilterValue() as unknown[];
    const [existingFilters, setExistingFilters] = useState<unknown[]>(
        columnFilters?.map(val => (EMPTY_VALUES.includes(val as EmptyFilter) ? null : val)) || (filterController?.loadFilter(column.id) ?? [])
    );
    const lang = useLang();
    const isSortable = column.getCanSort();
    const isFilterable = column.getCanFilter() || column.filters;
    const currentSortOrder = column.getIsSorted() || filterController?.loadSort(column.id) || false;

    const onSort = (direction: SortDirection) => {
        if (currentSortOrder === direction) {
            column.clearSorting();
            filterController?.saveSort(column.id, false);
        } else {
            column.toggleSorting(direction === SortDirection.DESCENDING);
            filterController?.saveSort(column.id, direction);
        }
        setIsOpened(false);
    };

    const setFilter = (value: unknown, isActive: boolean) => {
        if (isActive) {
            setExistingFilters(prev => [...prev, value]);
        } else {
            setExistingFilters(prev => prev.filter(val => val !== value));
        }
    };

    const onSubmit = () => {
        column.setFilterValue(existingFilters?.length ? existingFilters : undefined);
        filterController?.saveFilter(column.id, existingFilters);
        setIsOpened(false);
    };

    return (
        <Dropdown open={isOpened} onOpenChange={setIsOpened}>
            <DropdownCommon.Trigger>
                {(isSortable || isFilterable) && (
                    <button type="button" className="flex h-full items-center p-1 focus:outline-none focus-visible:outline-none">
                        {getIcon(!!columnFilters?.length, !!column.getIsSorted())({ color: "slate" })}
                    </button>
                )}
            </DropdownCommon.Trigger>
            <DropdownCommon.Content>
                <>
                    {isSortable && (
                        <>
                            <SortItem
                                label={lang.filtering.sortAsc}
                                onClick={() => onSort(SortDirection.ASCENDING)}
                                isActive={currentSortOrder === SortDirection.ASCENDING}
                            />
                            <SortItem
                                label={lang.filtering.sortDesc}
                                onClick={() => onSort(SortDirection.DESCENDING)}
                                isActive={currentSortOrder === SortDirection.DESCENDING}
                            />
                            {isFilterable && <DropdownCommon.Separator />}
                        </>
                    )}
                    {isFilterable && (
                        <>
                            <div className="p-4">
                                <Filters<T, V>
                                    column={column}
                                    existingFilters={existingFilters}
                                    onFilter={setFilter}
                                    onSelectAll={setExistingFilters}
                                    onFiltersLazyLoad={onFiltersLazyLoad}
                                    availableFilters={availableFilters}
                                    setAvailableFilters={setAvailableFilters}
                                />
                            </div>
                            <DropdownCommon.Separator />
                            <div className="flex justify-end gap-2 p-4">
                                <Buttons.Cancel size="sm" onClick={() => setIsOpened(false)} isLoading={false} />
                                <Button size="sm" isLoading={false} onClick={onSubmit} children={lang.filtering.apply} />
                            </div>
                        </>
                    )}
                </>
            </DropdownCommon.Content>
        </Dropdown>
    );
}
