import { StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { User } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppName } from "./helpers";
import { ProtectedRoute } from "./ProtectedRoute";
import { getIgnoredAccountIds } from "./services/accountService";
import {
    ABSOLUTE_KYS1_PAGE,
    ABSOLUTE_KYS2_PAGE,
    ABSOLUTE_PRIVATE_ROOT_PAGE,
    ACCOUNTS_PAGE,
    DEPOSIT_DETAIL_PAGE,
    DEPOSIT_PAGE,
    DOMAINS_PAGE,
    ESCROW_DEPOSIT_DETAIL_PAGE,
    ESCROW_DETAIL_PAGE,
    ESCROW_PAGE,
    EVENTS_PAGE,
    LOGIN_PAGE,
    LPR_PAGE,
    MAINTENANCE_PAGE,
    NDA_PAGE,
    PRIVATE_ROOT_PAGE,
    PROMOTIONS_PAGE,
    PROMOTION_DETAIL_PAGE,
    PUBLIC_ROOT_PAGE,
    REGISTER_PAGE,
    STAFF_USERS_PAGE,
    STAFF_USER_PROFILE_PAGE,
    USER_PROFILES_PAGE
} from "./services/routing.service";
import AccountsPage from "./views/private/account/AccountsPage";
import DomainsPage from "./views/private/domains/DomainsPage";
import EventsPage from "./views/private/events/EventsPage";
import HomePage from "./views/private/home/HomePage";
import Kys1Page from "./views/private/kys1/Kys1Page";
import Kys2Page from "./views/private/kys2/Kys2Page";
import LPRPage from "./views/private/lpr/LPRPage";
import MaintenancePage from "./views/private/maintenance/MaintenancePage";
import NDARouter from "./views/private/non-disclosure-agreement/NDARouter";
import ProfilePage from "./views/private/profile/ProfilePage";
import PromotionPage from "./views/private/promotion/PromotionPage";
import PromotionsPage from "./views/private/promotion/PromotionsPage";
import StaffUsersPage from "./views/private/staff-users/StaffUsersPage";
import UserProfilesPage from "./views/private/users/UserProfilesPage";
import DepositPage from "./views/private/vault/DepositPage";
import DepositView from "./views/private/vault/DepositView";
import EscrowPage from "./views/private/vault/EscrowPage";
import EscrowsView from "./views/private/vault/EscrowsView";
import { LandingPage, LoginPage, RegisterPage, UnauthorizedPage } from "./views/public";

const PrivateRoutes = ({ user, roles, staffUser }: { user: User; roles: StaffUserRole[]; staffUser: StaffUser | null }) => {
    const [ignoredAccountIds, setIgnoredAccountIds] = useState<string[]>([]);

    useEffect(() => getIgnoredAccountIds(setIgnoredAccountIds), []);
    const wasAdminWhenLogged = roles.includes(StaffUserRole.ADMIN);
    if (roles.length === 0 || !staffUser) {
        return <UnauthorizedPage />;
    }
    return (
        <div className="flex h-full max-h-full flex-col gap-2 overflow-auto bg-slate-50 px-8 py-4">
            <Routes>
                <Route path={PRIVATE_ROOT_PAGE} element={<HomePage roles={roles} staffUser={staffUser} />} />
                <Route path={STAFF_USERS_PAGE} element={<StaffUsersPage wasAdminWhenLogged={wasAdminWhenLogged} />} />
                <Route
                    path={`${USER_PROFILES_PAGE}/*`}
                    element={
                        <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                            <UserProfilesPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={`${ACCOUNTS_PAGE}/*`}
                    element={
                        <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN, StaffUserRole.CLIENT_SERVICE]}>
                            <AccountsPage staffUser={staffUser} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={`${DOMAINS_PAGE}/*`}
                    element={
                        <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN]}>
                            <DomainsPage staffUser={staffUser} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={`${LPR_PAGE}/*`}
                    element={
                        <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN]}>
                            <LPRPage wasAdminWhenLogged={wasAdminWhenLogged} />
                        </ProtectedRoute>
                    }
                />
                {process.env.REACT_APP_BRAND_NAME !== AppName.EVIDENCY && (
                    <>
                        <Route
                            path={`${EVENTS_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <EventsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${PROMOTIONS_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN]}>
                                    <PromotionsPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={PROMOTION_DETAIL_PAGE}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN]}>
                                    <PromotionPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={DEPOSIT_PAGE}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <DepositView ignoredAccountIds={ignoredAccountIds} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${DEPOSIT_DETAIL_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <DepositPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${ESCROW_DEPOSIT_DETAIL_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <DepositPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={ESCROW_PAGE}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <EscrowsView ignoredAccountIds={ignoredAccountIds} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${ESCROW_DETAIL_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <EscrowPage staffUser={staffUser} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${ABSOLUTE_KYS1_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.KYS1_REVIEWER]}>
                                    <Kys1Page user={user} staffUser={staffUser} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${ABSOLUTE_KYS2_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.ADMIN]}>
                                    <Kys2Page user={user} staffUser={staffUser} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${NDA_PAGE}/*`}
                            element={
                                <ProtectedRoute staffUser={staffUser} requiredRoles={[StaffUserRole.CLIENT_SERVICE]}>
                                    <NDARouter />
                                </ProtectedRoute>
                            }
                        />
                    </>
                )}
                <Route path={MAINTENANCE_PAGE} element={<MaintenancePage />} />
                <Route path={STAFF_USER_PROFILE_PAGE} element={<ProfilePage staffUser={staffUser} />} />
                <Route path="*" element={<Navigate to={ABSOLUTE_PRIVATE_ROOT_PAGE} />} />
            </Routes>
        </div>
    );
};

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path={LOGIN_PAGE} element={<LoginPage />} />
            <Route path={REGISTER_PAGE} element={<RegisterPage />} />
            <Route path={PUBLIC_ROOT_PAGE} element={<LandingPage />} />
            <Route path="*" element={<Navigate to={PUBLIC_ROOT_PAGE || "/"} />} />
        </Routes>
    );
};

export default function AppRouter({ user, roles, staffUser }: { user?: User | null; roles: StaffUserRole[]; staffUser: StaffUser | null }) {
    return (
        <>
            {user && <PrivateRoutes user={user} roles={roles} staffUser={staffUser} />}
            {!user && <PublicRoutes />}
        </>
    );
}
