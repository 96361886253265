import { ACCOUNT_COLLECTION, ACCOUNT_REPORTS_COLLECTION, Deposit, DepositStore, Escrow, isDefined, LANG_EN, LANG_FR, Report } from "@vaultinum/vaultinum-api";
import { DEPOSIT_COLLECTION, DEPOSIT_STORE_COLLECTION, ESCROW_COLLECTION, VerifiedDepositReport } from "@vaultinum/vaultinum-deposit-api";
import {
    collection,
    collectionGroup,
    converter,
    doc,
    getAuth,
    getFirestore,
    getItem,
    getItems,
    onSnapshot,
    openNotificationWithIcon,
    orderBy,
    query,
    QueryConstraint,
    updateDoc,
    where
} from "@vaultinum/vaultinum-sdk";
import fileDownload from "js-file-download";
import { sortBy } from "lodash";
import { getReportFileName } from "../common/ReportTools";
import { doApiPost, doGet, doPost, downloadPDF } from "./apiService";

export type DepositStoreWithDeposits = DepositStore & { deposits: Deposit[] };
export const PAID_OFFLINE = "PAID_OFFLINE";

export function getDepositsByAccount(accountId: string | undefined, onUpdate: (deposits: Deposit[]) => void): () => void {
    if (!accountId) {
        onUpdate([]);
        return () => {};
    } else {
        return getItems(
            query(
                collection(getFirestore(), DEPOSIT_COLLECTION).withConverter(converter<Deposit>()),
                where("owner.accountId", "==", accountId),
                orderBy("createdDate", "desc")
            ),
            onUpdate
        );
    }
}

export function getDeposits(): Promise<Deposit[]>;
export function getDeposits(onUpdate: (deposits: Deposit[]) => void): () => void;
export function getDeposits(onUpdate: (deposits: Deposit[]) => void, filterRevoked: boolean): () => void;
export function getDeposits(onUpdate?: (deposits: Deposit[]) => void, filterRevoked?: boolean): Promise<Deposit[]> | (() => void) {
    const constraints: QueryConstraint[] = filterRevoked ? [where("status", "!=", Deposit.Status.REVOKED)] : [orderBy("createdDate", "desc")];
    const depositsQuery = query(collection(getFirestore(), DEPOSIT_COLLECTION).withConverter(converter<Deposit>()), ...constraints);
    if (onUpdate) {
        return getItems(depositsQuery, onUpdate);
    }
    return getItems(depositsQuery);
}

export function getVerifiedDepositReport(deposit: Deposit, onUpdate: (report: VerifiedDepositReport | null) => void): () => void {
    return onSnapshot(
        query(
            collection(getFirestore(), ACCOUNT_COLLECTION, deposit.owner.accountId, ACCOUNT_REPORTS_COLLECTION).withConverter(
                converter<VerifiedDepositReport>()
            ),
            where("depositIddn", "==", deposit.iddn),
            where("reportType", "==", Report.ReportType.VERIFIED_DEPOSIT)
        ),
        querySnapshot => {
            if (querySnapshot.size !== 1) {
                return onUpdate(null);
            }
            return onUpdate(querySnapshot.docs[0].data());
        }
    );
}

export async function uploadVerifiedDepositReport(formData: FormData): Promise<VerifiedDepositReport> {
    return doApiPost("vault-deposit/report", formData, process.env.REACT_APP_VAULT_API_HOST);
}

export async function uploadEscrowContract(formData: FormData): Promise<VerifiedDepositReport> {
    return doApiPost("vault-escrow/contract", formData, process.env.REACT_APP_VAULT_API_HOST);
}

export const downloadEscrowContract = (escrow: Escrow) => {
    const url = `vault-escrow/${escrow.id}/contract`;
    const fileName = getReportFileName("escrow-contract", escrow.id, escrow.creationDate);
    return downloadPDF(url, fileName, process.env.REACT_APP_VAULT_API_HOST);
};

export function getDepositsByStores(depositStoresIds: string[]): Promise<Deposit[]>;
export function getDepositsByStores(depositStoresIds: string[], onUpdate: (deposits: Deposit[]) => void): () => void;
export function getDepositsByStores(depositStoresIds: string[], onUpdate: (deposits: Deposit[]) => void): () => void;
export function getDepositsByStores(depositStoresIds: string[], onUpdate?: (deposits: Deposit[]) => void): Promise<Deposit[]> | (() => void) {
    if (onUpdate) {
        const constraints: QueryConstraint[] = [where("depositStoreId", "in", depositStoresIds), orderBy("createdDate", "desc")];
        return getItems(query(collection(getFirestore(), DEPOSIT_COLLECTION).withConverter(converter<Deposit>()), ...constraints), onUpdate);
    }
    return getItems(collection(getFirestore(), DEPOSIT_COLLECTION).withConverter(converter<Deposit>()));
}

export function getDepositStoresForAccount(accountId: string | undefined, onUpdate: (depositStores: DepositStore[] | null) => void): () => void {
    if (!accountId) {
        onUpdate(null);
        return () => {};
    }
    return getItems(
        query(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, DEPOSIT_STORE_COLLECTION).withConverter(converter<DepositStore>())),
        onUpdate
    );
}

export async function getDepositStore(accountId: string, depositStoreId: string): Promise<DepositStore | null> {
    return getItem(
        doc(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, DEPOSIT_STORE_COLLECTION).withConverter(converter<DepositStore>()), depositStoreId)
    );
}

export function getDepositStoresWithoutEscrowId(onUpdate: (depositStores: DepositStore[]) => void): () => void {
    return getItems(
        query(collectionGroup(getFirestore(), DEPOSIT_STORE_COLLECTION).withConverter(converter<DepositStore>()), where("escrowId", "==", null)),
        onUpdate
    );
}

export function getEscrows(onUpdate: (escrows: Escrow[]) => void): () => void {
    return getItems(
        query(collection(getFirestore(), ESCROW_COLLECTION).withConverter(converter<Escrow>()), orderBy("creationDate", "desc")).withConverter(
            converter<Escrow>()
        ),
        onUpdate
    );
}

export function getEscrowsAsSupplierForAccount(accountId: string | undefined, onUpdate: (escrows: Escrow[] | undefined) => void): () => void {
    if (!accountId) {
        onUpdate(undefined);
        return () => {};
    }
    return getItems(
        query(
            collection(getFirestore(), ESCROW_COLLECTION).withConverter(converter<Escrow>()),
            where("supplier.accountId", "==", accountId),
            orderBy("creationDate", "desc")
        ).withConverter(converter<Escrow>()),
        onUpdate
    );
}

export function getEscrowsAsBeneficiaryForAccount(accountId: string | undefined, onUpdate: (escrows: Escrow[] | undefined) => void): () => void {
    if (!accountId) {
        onUpdate(undefined);
        return () => {};
    }
    return getItems(
        query(
            collection(getFirestore(), ESCROW_COLLECTION).withConverter(converter<Escrow>()),
            where("beneficiary.accountId", "==", accountId),
            orderBy("creationDate", "desc")
        ).withConverter(converter<Escrow>()),
        onUpdate
    );
}

async function getCertificate(iddn: string, depositStoreId: string, lang: string) {
    const data: Blob = await doGet(`certificate/${lang}/${depositStoreId}/${iddn}`, {
        responseType: "blob",
        headers: { refreshToken: getAuth().currentUser?.refreshToken || "" }
    });
    fileDownload(data, encodeURIComponent(`certificate-${iddn}.pdf`));
}

export const downloadCertificate = async (depositStoreId: string, iddn: string) => {
    try {
        await getCertificate(iddn, depositStoreId, LANG_EN);
    } catch (error) {
        try {
            await getCertificate(iddn, depositStoreId, LANG_FR);
        } catch (e) {
            openNotificationWithIcon({ type: "error", description: `Error while downloading certificate ${iddn}` });
        }
    }
};

export const archiveEscrow = (escrowId: string, isArchived: boolean) => {
    return updateDoc(doc(collection(getFirestore(), ESCROW_COLLECTION).withConverter(converter<Escrow>()), escrowId), {
        isArchived
    });
};

export const setEscrowAsPaidOfflineAndPendingDeposit = (escrowId: string, verificationLevel: Deposit.VerificationLevel) => {
    return updateDoc(doc(collection(getFirestore(), ESCROW_COLLECTION).withConverter(converter<Escrow>()), escrowId), {
        paymentId: PAID_OFFLINE,
        progressStatus: Escrow.ProgressStatus.PENDING_DEPOSIT,
        depositVerification: {
            level: verificationLevel
        }
    });
};

export const terminateEscrow = (escrowId: string) => {
    return updateDoc(doc(collection(getFirestore(), ESCROW_COLLECTION).withConverter(converter<Escrow>()), escrowId), {
        progressStatus: Escrow.ProgressStatus.TERMINATED
    });
};

export const setPaymentMethodAsPaidOffline = (depositStore: DepositStore, subscriptionId?: string) => {
    return updateDoc(
        doc(
            collection(getFirestore(), ACCOUNT_COLLECTION, depositStore.accountId, DEPOSIT_STORE_COLLECTION).withConverter(converter<DepositStore>()),
            depositStore.id
        ),
        {
            paymentMethod: Deposit.PaymentMethod.OFFLINE,
            ...(subscriptionId && { subscriptionId })
        }
    );
};

export async function sealVerifiedDeposit(deposit: Deposit) {
    return doPost(
        "deposit/verify",
        {
            params: {
                bundleUuid: deposit.bundleId,
                depositIddn: deposit.iddn
            }
        },
        {
            headers: { refreshToken: getAuth().currentUser?.refreshToken || "" }
        }
    );
}

export async function transferDeposits(iddns: string[], targetAccountId: string) {
    for (const iddn of iddns) {
        await doPost(
            "deposit/transfer",
            { params: { iddn, targetAccountId } },
            {
                headers: { refreshToken: getAuth().currentUser?.refreshToken || "" }
            }
        );
    }
}

export function getEscrow(escrowId: string): Promise<Escrow | null>;
export function getEscrow(escrowId: string, onUpdate: (escrow: Escrow | null) => void): () => void;
export function getEscrow(escrowId: string, onUpdate?: (escrow: Escrow | null) => void): Promise<Escrow | null> | (() => void) {
    if (onUpdate) {
        return getItem(
            doc(collection(getFirestore(), ESCROW_COLLECTION).withConverter(converter<Escrow>()), escrowId).withConverter(converter<Escrow>()),
            onUpdate
        );
    }
    return getItem(doc(collection(getFirestore(), ESCROW_COLLECTION).withConverter(converter<Escrow>()), escrowId).withConverter(converter<Escrow>()));
}

export function getDepositStoresWithDeposits(depositStores: DepositStore[], deposits: Deposit[]): DepositStoreWithDeposits[] {
    return depositStores
        .map(element => {
            const availableDeposits = deposits.filter(deposit => deposit.depositStoreId === element.id);

            if (availableDeposits.length) {
                return {
                    ...element,
                    deposits: sortBy(availableDeposits, ["version"])
                };
            }

            return undefined;
        })
        .filter(isDefined);
}

export function removeCancelledDepositRequests(deposit: Deposit) {
    return updateDoc(doc(collection(getFirestore(), DEPOSIT_COLLECTION).withConverter(converter<Deposit>()), deposit.iddn), {
        releaseRequest: (!deposit.releaseRequest || deposit.releaseRequest.status === Deposit.ActionRequestStatus.CANCELLED) && null,
        deleteRequest: (!deposit.deleteRequest || deposit.deleteRequest.status === Deposit.ActionRequestStatus.CANCELLED) && null
    });
}
