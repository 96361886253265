import { New, Overwrite, Promotion, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import {
    AppCode,
    BaseLang,
    Button,
    Controller,
    Form,
    Input,
    Label,
    Modal,
    openNotificationWithIcon,
    Select,
    useForm,
    UseFormReturn,
    useLang,
    useModal,
    useRequiredString,
    yup
} from "@vaultinum/vaultinum-sdk";
import dayjs from "dayjs";
import { omit } from "lodash";
import { useState } from "react";
import ScopeSelector from "../../../components/ScopeSelector";
import { createPromotion, updatePromotion } from "../../../services/promotionService";

type PromotionFormType = Overwrite<New<Promotion>, { emails: string }>;

function PromotionForm({
    form,
    isWorking,
    domains,
    promotion
}: {
    form: UseFormReturn<PromotionFormType>;
    isWorking: boolean;
    domains?: WhiteLabelDomain[];
    promotion?: Promotion;
}) {
    const countEmail = Object.keys(promotion?.emails || {}).length;
    return (
        <Form>
            <div className="flex divide-x">
                <div className="flex w-1/2 flex-col space-y-4 px-4">
                    <Controller
                        name="name"
                        control={form.control}
                        render={({ field }) => (
                            <Input.Text
                                {...field}
                                label="Name"
                                placeholder="Name"
                                disabled={isWorking}
                                required
                                errorMessage={form.formState.errors.name?.message}
                            />
                        )}
                    />
                    <Controller
                        name="emails"
                        control={form.control}
                        render={({ field }) => (
                            <Input.TextArea
                                {...field}
                                {...(countEmail
                                    ? {
                                          label: `Emails to add (${countEmail} already linked)`
                                      }
                                    : {
                                          label: "Emails to add"
                                      })}
                                placeholder="One email per line"
                                disabled={isWorking || !!promotion}
                                required={!promotion}
                                errorMessage={form.formState.errors?.emails?.message}
                            />
                        )}
                    />
                    <Controller
                        name="startDate"
                        control={form.control}
                        render={({ field }) => (
                            <Input.DatePicker
                                {...field}
                                label="Start Date"
                                disabled={isWorking}
                                required
                                errorMessage={form.formState.errors.startDate?.message}
                                selected={field.value}
                            />
                        )}
                    />
                    <Controller
                        name="endDate"
                        control={form.control}
                        render={({ field }) => (
                            <Input.DatePicker
                                {...field}
                                label="End Date"
                                disabled={isWorking}
                                required
                                errorMessage={form.formState.errors.endDate?.message}
                                selected={field.value}
                                minDate={form.getValues("startDate")}
                            />
                        )}
                    />
                </div>
                <div className="flex w-1/2 flex-col space-y-4 px-4">
                    <div>
                        <Label required children="Apps" />
                        <Controller
                            name="offer"
                            control={form.control}
                            render={() => (
                                <Input.Checkbox
                                    id="full-audit-app"
                                    label="Full Audit"
                                    defaultChecked={!!promotion?.offer[AppCode.FULL_AUDIT]}
                                    disabled={isWorking || !!promotion}
                                    onChange={e => {
                                        const offerValue = form.getValues("offer") || {};
                                        if (e.target.checked) {
                                            offerValue[AppCode.FULL_AUDIT] = { scopes: [] };
                                        } else {
                                            delete offerValue[AppCode.FULL_AUDIT];
                                        }
                                        form.setValue("offer", offerValue);
                                    }}
                                />
                            )}
                        />
                    </div>
                    {!!form.watch().offer?.[AppCode.FULL_AUDIT] && (
                        <Controller
                            name="offer.full-audit.scopes"
                            control={form.control}
                            render={({ field }) => <ScopeSelector {...field} mode="multiple" disabled={isWorking || !!promotion} required />}
                        />
                    )}
                    {!!domains?.length && (
                        <Controller
                            name="whiteLabelDomainId"
                            control={form.control}
                            render={({ field }) => (
                                <Select.Basic
                                    {...field}
                                    label="Domains"
                                    isDisabled={isWorking || !!promotion}
                                    options={domains.filter(element => element.isActive).map(domain => ({ label: domain.fqdn, value: domain.id }))}
                                />
                            )}
                        />
                    )}
                </div>
            </div>
        </Form>
    );
}

function formatPromotion({ startDate, endDate, emails, whiteLabelDomainId = null, ...rest }: PromotionFormType): New<Promotion> {
    return {
        ...rest,
        startDate: dayjs(startDate).startOf("day").toDate(),
        endDate: dayjs(endDate).endOf("day").toDate(),
        emails: emails
            .split(/\r?\n/)
            .map(email => email.trim())
            .filter(email => email.length > 0)
            .reduce((acc, email) => ({ ...acc, [email]: {} }), {}),
        whiteLabelDomainId
    };
}

export default function PromotionModalWithButton({ domains, promotion }: { domains?: WhiteLabelDomain[]; promotion?: Promotion }): JSX.Element {
    const lang = useLang<BaseLang>();
    const { isOpen, doOpen, doClose } = useModal();
    const [isWorking, setIsWorking] = useState(false);

    const schema = yup.object().shape({
        name: useRequiredString(),
        emails: promotion ? yup.string() : yup.string().required(),
        startDate: yup.date().required(),
        endDate: yup.date().required(),
        whiteLabelDomainId: promotion && !promotion.whiteLabelDomainId ? yup.mixed().nullable() : yup.string(),
        offer: yup.object().shape({
            [AppCode.FULL_AUDIT]: yup.object().shape({
                scopes: yup.array().required().min(1)
            })
        })
    });

    const form = useForm<PromotionFormType>({
        schema,
        mode: "onChange",
        defaultValues: { ...omit(promotion, ["id", "creationDate", "updateDate"]), emails: "" }
    });

    function close() {
        if (!promotion) {
            form.reset();
        }
        doClose();
    }

    async function onConfirm() {
        if (!form.formState.isValid) {
            return;
        }
        setIsWorking(true);
        try {
            const formattedPromotion = formatPromotion(form.getValues());
            if (promotion) {
                const { emails, ...update } = formattedPromotion;
                await updatePromotion(promotion.id, {
                    ...update,
                    ...(Object.keys(emails).length && { emails })
                });
            } else {
                await createPromotion(formattedPromotion);
            }
            openNotificationWithIcon({ type: "success", message: `Promotion ${promotion ? "updated" : "created"} successfully` });
        } catch (e) {
            throw new Error(`An error occured while  ${promotion ? "updating" : "creating"} the promotion`);
        } finally {
            setIsWorking(false);
        }
    }

    return (
        <>
            <Button type={promotion ? "default" : "primary"} onClick={doOpen} isLoading={isWorking}>
                {promotion ? "Edit" : "New Promotion"}
            </Button>
            {isOpen && (
                <Modal
                    size="lg"
                    isOpen={isOpen}
                    title={promotion ? "Edit Promotion" : "New Promotion"}
                    children={<PromotionForm form={form} isWorking={isWorking} domains={domains} promotion={promotion} />}
                    okText={promotion ? "Update" : "Create"}
                    isDisabled={!form.formState.isValid}
                    isLoading={isWorking}
                    lang={lang}
                    onClose={close}
                    onConfirm={onConfirm}
                />
            )}
        </>
    );
}
