import { IconSvgProps } from "../../../../../../common";

export default function TechStackSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.69493 15.8906C8.54239 15.8906 8.42798 15.7852 8.42798 15.6445V12.2344C8.42798 12.0938 8.54239 11.9883 8.69493 11.9883C8.84747 11.9883 8.96188 12.0938 8.96188 12.2344V15.6445C8.96188 15.7852 8.84747 15.8906 8.69493 15.8906Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.06355 14.0977H4.11863C3.96609 14.0977 3.85168 13.9922 3.85168 13.8516C3.85168 13.7109 3.96609 13.6055 4.11863 13.6055H5.7966V11.1797C5.7966 11.0391 5.91101 10.8984 6.06355 10.8984C6.21609 10.8984 6.3305 11.0391 6.3305 11.1797V13.8516C6.3305 13.9922 6.21609 14.0977 6.06355 14.0977Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8814 14.0977H11.9746C11.822 14.0977 11.6695 13.9922 11.6695 13.8516V11.1797C11.6695 11.0391 11.822 10.8984 11.9746 10.8984C12.1271 10.8984 12.2415 11.0391 12.2415 11.1797V13.6055H13.8814C14.0339 13.6055 14.1864 13.7109 14.1864 13.8516C14.1864 13.9922 14.0339 14.0977 13.8814 14.0977Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.97459 15.1523C2.17374 15.1523 1.56357 14.5898 1.56357 13.8516C1.56357 13.1133 2.17374 12.5508 2.97459 12.5508C3.77543 12.5508 4.3856 13.1133 4.3856 13.8516C4.3856 14.5898 3.77543 15.1523 2.97459 15.1523Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0254 15.1523C14.2246 15.1523 13.6144 14.5898 13.6144 13.8516C13.6144 13.1133 14.2246 12.5508 15.0254 12.5508C15.8263 12.5508 16.4364 13.1133 16.4364 13.8516C16.4364 14.5898 15.8263 15.1523 15.0254 15.1523Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.69492 18C7.93221 18 7.28391 17.4023 7.28391 16.6992C7.28391 15.9609 7.93221 15.3984 8.69492 15.3984C9.49577 15.3984 10.1059 15.9609 10.1059 16.6992C10.1059 17.4023 9.45763 18 8.69492 18Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.4364 7.66406L15.2924 7.59375C14.9492 7.55859 14.6822 7.34766 14.6059 7.06641C14.4153 6.50391 14.1864 5.97656 13.8814 5.48438C13.6907 5.20312 13.7288 4.88672 13.9576 4.64062L14.7203 3.86719L13.7669 2.98828L12.928 3.69141C12.661 3.90234 12.3178 3.9375 12.0127 3.76172C11.4788 3.48047 10.9068 3.26953 10.2966 3.12891C9.99153 3.05859 9.76271 2.77734 9.72458 2.46094L9.68644 1.40625H8.35169L8.27542 2.46094C8.23729 2.77734 8.00847 3.02344 7.70339 3.12891C7.09322 3.26953 6.52119 3.48047 5.98729 3.79688C5.6822 3.9375 5.33898 3.90234 5.07203 3.69141L4.23305 2.98828L3.27966 3.86719L4.04237 4.67578C4.27119 4.88672 4.30932 5.23828 4.11864 5.51953C3.81356 5.97656 3.54661 6.50391 3.39407 7.06641C3.3178 7.38281 3.05085 7.59375 2.70763 7.59375L1.56356 7.66406V9.59766C1.56356 9.98438 1.22034 10.3008 0.800847 10.3008C0.381356 10.3008 0 9.98438 0 9.59766V6.99609C0 6.60938 0.34322 6.32812 0.762712 6.29297L2.05932 6.22266C2.17373 5.87109 2.32627 5.55469 2.51695 5.23828L1.63983 4.32422C1.33475 4.04297 1.37288 3.62109 1.67797 3.375L3.66102 1.51172C3.9661 1.26562 4.42373 1.23047 4.72881 1.47656L5.6822 2.28516C6.02542 2.14453 6.40678 2.00391 6.75 1.89844L6.82627 0.667969C6.86441 0.316406 7.20763 0 7.58898 0H10.411C10.7924 0 11.1356 0.28125 11.1737 0.667969L11.25 1.86328C11.6314 2.00391 11.9746 2.14453 12.3178 2.28516L13.3093 1.47656C13.6144 1.23047 14.072 1.26562 14.339 1.51172L16.322 3.33984C16.6271 3.62109 16.6653 4.04297 16.3602 4.32422L15.4831 5.20312C15.6737 5.51953 15.8263 5.87109 15.9407 6.22266L17.2373 6.25781C17.6568 6.29297 18 6.60938 18 6.96094V9.5625C18 9.94922 17.6568 10.3008 17.1992 10.3008C16.7797 10.3008 16.4364 9.98438 16.4364 9.5625V7.66406Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00001 12.4805C6.48306 12.4805 4.42374 10.6172 4.42374 8.26172C4.42374 5.94141 6.48306 4.04297 9.00001 4.04297C11.517 4.04297 13.5763 5.94141 13.5763 8.26172C13.5763 10.582 11.517 12.4805 9.00001 12.4805Z"
                fill={color}
            />
        </svg>
    );
}
