import { Event } from "@vaultinum/vaultinum-api";
import { Route, Routes } from "react-router-dom";
import { EventsList } from "../components/EventsList";

export default function EventsPage(): JSX.Element {
    return (
        <Routes>
            <Route index element={<EventsList defaultLevelsFiltered={[Event.Level.WARN, Event.Level.ERROR]} />} />
        </Routes>
    );
}
