import { FiltersService } from "./filter.service";

export enum SortDirection {
    ASCENDING = "asc",
    DESCENDING = "desc"
}

export interface FiltersSortService extends FiltersService {
    saveSort: (filterKey: string, direction: SortDirection | false) => void;
    loadSorts: () => Record<string, SortDirection | false>;
    loadSort: (filterKey: string) => SortDirection | false;
}
