import { Account } from "@vaultinum/vaultinum-api";
import { ColCards, ColCardWithRadioProps } from "../../../design-system";
import { useLang } from "../../../lang";
import { formatFrequency } from "../../tools";

const availableFrequencies = [
    Account.GitOperation.Frequency.ONE_SHOT,
    Account.GitOperation.Frequency.WEEKLY,
    Account.GitOperation.Frequency.MONTHLY,
    Account.GitOperation.Frequency.EVERY_THREE_MONTHS,
    Account.GitOperation.Frequency.EVERY_SIX_MONTHS
];

export default function FrequencySelector({
    selectedFrequency,
    setSelectedFrequency
}: {
    selectedFrequency: Account.GitOperation.Frequency;
    setSelectedFrequency: (frequency: Account.GitOperation.Frequency) => void;
}): JSX.Element {
    const lang = useLang();

    const frequencies: ColCardWithRadioProps<Account.GitOperation.Frequency>[] = availableFrequencies.map(frequency => ({
        children: formatFrequency(frequency, lang),
        radio: {
            id: frequency
        }
    }));

    return (
        <div className="flex gap-4">
            {frequencies.map(frequency => (
                <ColCards.WithRadio<Account.GitOperation.Frequency>
                    key={frequency.radio.id}
                    title={{
                        text: frequency.title,
                        position: "center"
                    }}
                    children={frequency.children}
                    radio={{
                        id: frequency.radio.id,
                        selectedId: selectedFrequency,
                        onValueChange: setSelectedFrequency
                    }}
                />
            ))}
        </div>
    );
}
