import { CodeAnalysisReport, FULL_AUDIT_COLLECTION, FULL_AUDIT_REPORTS_COLLECTION, FULL_AUDIT_REPORTS_FILES_COLLECTION } from "@vaultinum/vaultinum-kys-api";
import { collection, CollectionReference, converter, DocumentData, getFirestore, getItems } from "@vaultinum/vaultinum-sdk";

function getCodeReportFilesCollection(fullAuditId: string, reportId: string): CollectionReference<DocumentData> {
    return collection(getFirestore(), FULL_AUDIT_COLLECTION, fullAuditId, FULL_AUDIT_REPORTS_COLLECTION, reportId, FULL_AUDIT_REPORTS_FILES_COLLECTION);
}

export function getCodeAnalysisReportFiles(report: CodeAnalysisReport | undefined | null, onUpdate: (files: CodeAnalysisReport.File[]) => void): () => void;
export function getCodeAnalysisReportFiles(report?: CodeAnalysisReport | null): Promise<CodeAnalysisReport.File[]>;
export function getCodeAnalysisReportFiles(
    report?: CodeAnalysisReport | null,
    onUpdate?: (files: CodeAnalysisReport.File[]) => void
): Promise<CodeAnalysisReport.File[]> | (() => void) {
    if (!report) {
        if (onUpdate) {
            onUpdate([]);
            return () => {};
        }
        return Promise.resolve([]);
    }
    if (onUpdate) {
        return getItems(getCodeReportFilesCollection(report.fullAuditId, report.id).withConverter(converter<CodeAnalysisReport.File>()), onUpdate);
    }
    return getItems(getCodeReportFilesCollection(report.fullAuditId, report.id).withConverter(converter<CodeAnalysisReport.File>()));
}
