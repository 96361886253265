/**
 * @deprecated - Do not update, this file is kept to handle legacy code. It will be moved to kys-web-app in future.
 */
import {
    AssetRequest,
    FULL_AUDIT_ASSET_REQUESTS_COLLECTION,
    FULL_AUDIT_COLLECTION,
    FULL_AUDIT_REPORTS_COLLECTION,
    FullAudit,
    Report,
    SurveyReport
} from "@vaultinum/vaultinum-api";
import {
    collection,
    collectionGroup,
    CollectionReference,
    converter,
    deleteField,
    doc,
    DocumentReference,
    getFirestore,
    getItems,
    Query,
    query,
    Unsubscribe,
    updateDoc,
    where
} from "../../../common";

/*
 *  Full Audit
 */
function fullAuditCollection(): CollectionReference<FullAudit> {
    return collection(getFirestore(), FULL_AUDIT_COLLECTION).withConverter(converter<FullAudit>());
}

export function fullAuditDoc(fullAuditId: string): DocumentReference<FullAudit> {
    return doc(fullAuditCollection(), fullAuditId);
}

export function getFullAudits(): Promise<FullAudit[]>;
export function getFullAudits(onUpdate: (fullAudits: FullAudit[]) => void): Unsubscribe;
export function getFullAudits(onUpdate?: (fullAudits: FullAudit[]) => void): Unsubscribe | Promise<FullAudit[]> {
    if (onUpdate) {
        return getItems(fullAuditCollection(), onUpdate);
    }
    return getItems(fullAuditCollection());
}

export function deleteFullAuditCodeAnalysisReportId(fullAuditId: string): Promise<void> {
    return updateDoc(fullAuditDoc(fullAuditId), { ["reportIds.codeAuditReportId"]: deleteField() });
}

/*
 *  Full Audit Asset requests
 */

export function fullAuditAssetRequestsCollection(fullAuditId: string): CollectionReference<AssetRequest> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_ASSET_REQUESTS_COLLECTION).withConverter(converter<AssetRequest>());
}

/*
 *  Full Audit Reports
 */

const CONVERTER = converter<SurveyReport>();

export function reportGroupCollection(): Query<SurveyReport> {
    return collectionGroup(getFirestore(), FULL_AUDIT_REPORTS_COLLECTION).withConverter(CONVERTER);
}

function reportCollection(fullAuditId: string): CollectionReference<SurveyReport> {
    return collection(fullAuditDoc(fullAuditId), FULL_AUDIT_REPORTS_COLLECTION).withConverter(CONVERTER);
}

export function reportDoc(fullAuditId: string, reportId: string): DocumentReference<SurveyReport> {
    return doc(reportCollection(fullAuditId), reportId);
}

export function getSurveyReports(onUpdate: (surveyReports: SurveyReport[]) => void): () => void;
export function getSurveyReports(fullAuditId: string): Promise<SurveyReport[]>;
export function getSurveyReports(fullAuditId: string, onUpdate: (surveyReports: SurveyReport[]) => void): () => void;
export function getSurveyReports(
    idOrUpdate: string | ((surveyReports: SurveyReport[]) => void),
    onUpdate?: (surveyReports: SurveyReport[]) => void
): Promise<SurveyReport[]> | (() => void) {
    const constraints = [where("reportType", "==", Report.ReportType.SURVEY), where("fullAuditId", "!=", null)];
    if (typeof idOrUpdate === "function") {
        const q = query(reportGroupCollection(), ...constraints);
        return getItems(q, idOrUpdate);
    }
    const q = query(reportCollection(idOrUpdate), ...constraints);
    if (onUpdate) {
        return getItems(q, onUpdate);
    }
    return getItems(q);
}
