import queryString from "query-string";
import { addQueryParamsToUrl } from "../../sdk/services/routingService";
import { FiltersService } from "./filter.service";

export class UrlFilterService implements FiltersService {
    saveFilter(filterKey: string, filters?: unknown[]): void {
        const urlObj = new URL(window.location.href);
        urlObj.searchParams.delete(filterKey);

        const queryParams: Record<string, unknown> = {};
        if (filters && filters.length > 0) {
            queryParams[filterKey] = filters.filter(filter => filter !== undefined).map(filter => (filter === null ? "null" : String(filter)));
        }

        const updatedUrl = addQueryParamsToUrl(urlObj.href, queryParams);
        window.history.replaceState({}, "", updatedUrl);
    }

    loadFilters(): Record<string, unknown[]> {
        const currentUrl = window.location.href;
        const queryParams = queryString.parseUrl(currentUrl).query;
        return Object.keys(queryParams).reduce<Record<string, unknown[]>>(
            (acc, key) => ({
                ...acc,
                [key]: Array.isArray(queryParams[key]) ? (queryParams[key] as unknown[]) : [queryParams[key]]
            }),
            {}
        );
    }

    loadFilter(filterKey: string): unknown[] {
        return this.loadFilters()[filterKey] ?? [];
    }
}
