import { FileExtractLanguage } from "../FileExtract";
import ReactMarkdown from "react-markdown";

export function MarkdownFileExtract({ extract }: { extract: string }): JSX.Element {
    return (
        <ReactMarkdown
            components={{
                h1: props => <h1 {...props} className="text-2xl font-bold border-b border-grey-light pb-2" />,
                h2: props => <h2 {...props} className="text-xl font-bold border-b border-grey-light pb-2 mb-2" />,
                h3: props => <h3 {...props} className="text-lg font-bold" />,
                h4: props => <h4 {...props} className="text-base font-semibold mb-3" />,
                p: props => <p {...props} className="text-sm my-2" />,
                ul: props => <ul {...props} className="ml-6 list-disc space-y-1" />,
                ol: props => <ol {...props} className="ml-6 list-decimal space-y-1" />,
                li: props => <li {...props} className="ml-2" />,
                a: props => <a {...props} className="text-blue-dark hover:underline" target="_blank" rel="noopener noreferrer" />,
                blockquote: props => <blockquote {...props} className="border-l-4 border-0 border-grey-light px-2 text-grey-primary" />,
                code: props => <code {...props} className="bg-grey-light rounded-md px-1 text-dark" />,
                pre: props => <pre {...props} className="bg-grey-dark text-white p-4 rounded-md overflow-x-auto" />
            }}
            children={extract}
        />
    );
}

export default function Preview({ extract, languageSupport }: { extract: string; languageSupport?: FileExtractLanguage }): JSX.Element | null {
    if (languageSupport === "markdown") {
        return <MarkdownFileExtract extract={extract} />;
    }

    return null;
}
