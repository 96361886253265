import { getScoreFromQuestionEvaluation, query, Question, Section, Survey, VariantRecord } from "@vaultinum/vaultinum-api";
import { Segment, Space } from "@vaultinum/vaultinum-sdk";
import { min, truncate } from "lodash";
import { SetStateAction, useEffect, useRef, useState } from "react";
import Sunburst, { SunburstChartInstance } from "sunburst-chart";

type Node = {
    name: string;
    children?: Node[];
    value?: number;
    color?: string;
    tooltip?: string;
};

const COLOR = {
    DISABLED: "#eee",
    SURVEY: "#fcfcfc",
    QUESTION: "#cacaca",
    ROOT_SECTION: "#c4eaff",
    SUB_SECTION: "#ebf0ff"
};

function formatEvaluationColor(score: number): string {
    if (score === -1) {
        return "#e6fff7";
    }
    if (score === 0) {
        return "#E33737";
    }
    if (score <= 0.25) {
        return "#FBAA5F";
    }
    if (score <= 0.5) {
        return "#e0bc00";
    }
    if (score <= 1) {
        return "#32AE27";
    }
    return COLOR.DISABLED;
}

const getDataFromOption = (option: Question.Option, surveyLang: Survey.Lang, focusTag: string, variantRecord: VariantRecord, disabled: boolean): Node => {
    const name = truncate(surveyLang.options[option.id].text, { length: 10 });
    let color = COLOR.DISABLED;
    if (!disabled) {
        const validEvaluations = option.evaluations
            .filter(tagEval => tagEval.tag && tagEval.evaluation !== Question.Evaluation.None)
            .filter(tagEval => !focusTag || tagEval.tag === focusTag);
        if (validEvaluations.length) {
            color = formatEvaluationColor(min(validEvaluations.map(tagEval => getScoreFromQuestionEvaluation(tagEval.evaluation))) || 0);
        } else {
            disabled = true;
        }
    }
    if (option.questions?.length) {
        return {
            name,
            tooltip: surveyLang.options[option.id].text,
            color,
            children: option.questions.map(question => getDataFromQuestion(question, surveyLang, focusTag, variantRecord, disabled))
        };
    }
    return {
        name,
        tooltip: surveyLang.options[option.id].text,
        color,
        value: 1
    };
};

const getDataFromQuestion = (question: Question, surveyLang: Survey.Lang, focusTag: string, variantRecord: VariantRecord, disabled: boolean): Node => {
    const matchingVariant = query(question).isIncludedWithVariant(variantRecord);
    return {
        name: truncate(surveyLang.questions[question.id]?.text || "", { length: 25 }),
        tooltip: surveyLang.questions[question.id]?.text || "",
        color: matchingVariant && !disabled ? COLOR.QUESTION : COLOR.DISABLED,
        children: question.options.map(option => getDataFromOption(option, surveyLang, focusTag, variantRecord, !matchingVariant || disabled))
    };
};

const getDataFromSection = (section: Section, surveyLang: Survey.Lang, level: number, focusTag: string, variantRecord: VariantRecord): Node => ({
    name: surveyLang.sections[section.id].title,
    tooltip: surveyLang.sections[section.id].title,
    color: level === 0 ? COLOR.ROOT_SECTION : COLOR.SUB_SECTION,
    children: [
        ...(section.sections || []).map(subSection => getDataFromSection(subSection, surveyLang, level + 1, focusTag, variantRecord)),
        ...(section.questions || []).map(question => getDataFromQuestion(question, surveyLang, focusTag, variantRecord, false))
    ]
});

const getDataFromSurvey = (surveyVersion: Survey.Version, surveyLang: Survey.Lang, focusTag: string, variantRecord: VariantRecord): Node => ({
    name: surveyLang.survey.title,
    tooltip: surveyLang.survey.title,
    color: COLOR.SURVEY,
    children: surveyVersion.sections.map(section => getDataFromSection(section, surveyLang, 0, focusTag, variantRecord))
});

export default function SurveyScoringOverview({ surveyVersion, surveyLang }: { surveyVersion: Survey.Version; surveyLang: Survey.Lang }) {
    const chartElement = useRef<HTMLDivElement>(null);
    const [selectedEvalTag, setSelectedEvalTag] = useState("");
    const [selectedVariant, setSelectedVariant] = useState<VariantRecord>(
        (surveyVersion.variants || []).reduce<VariantRecord>(
            (variantRecord, variant) => {
                variantRecord.variants[variant.key] = variant.values[0];
                if (variant.defaultValue && variantRecord.defaultVariants) {
                    variantRecord.defaultVariants[variant.key] = variant.defaultValue;
                }
                return variantRecord;
            },
            { variants: {}, defaultVariants: {} }
        )
    );
    const chart = useRef<SunburstChartInstance>();

    useEffect(() => {
        if (!chartElement.current) {
            return;
        }
        const data = getDataFromSurvey(surveyVersion, surveyLang, selectedEvalTag, selectedVariant);
        if (!chart.current) {
            chart.current = Sunburst()
                .data(data)
                .color("color")
                .tooltipTitle(node => node.tooltip)(chartElement.current);
        } else {
            chart.current.data(data);
        }
    }, [chartElement, surveyVersion, surveyLang, selectedEvalTag, selectedVariant]);

    const evalTagOptions = [
        { label: "All", value: "" },
        ...surveyVersion.evaluationTags.map(evalTag => ({
            label: evalTag,
            value: evalTag
        }))
    ];

    return (
        <div>
            <h2>Question & evaluation distribution</h2>
            <Space>
                <div>Categories</div>
                <Segment.Root onValueChange={(value: SetStateAction<string>) => setSelectedEvalTag(value)} value={selectedEvalTag}>
                    {evalTagOptions.map(tag => (
                        <Segment.Item currentValue={tag} selectedValue={selectedEvalTag} key={tag.value} />
                    ))}
                </Segment.Root>
                <div>Variants</div>
                {surveyVersion.variants?.map(variant => {
                    let options = variant.values.map(value => ({
                        label: `${variant.key}=${value}`,
                        value
                    }));
                    if (!variant.defaultValue) {
                        options = [{ label: `${variant.key}=All`, value: "" }, ...options];
                    }
                    return (
                        <Segment.Root
                            key={variant.key}
                            onValueChange={(newValue: string) =>
                                setSelectedVariant({
                                    ...selectedVariant,
                                    variants: {
                                        ...selectedVariant.variants,
                                        [variant.key]: newValue
                                    }
                                })
                            }
                            value={selectedVariant.variants?.[variant.key]}
                        >
                            {options.map(option => (
                                <Segment.Item currentValue={option} selectedValue={selectedVariant.variants?.[variant.key]} key={option.value} />
                            ))}
                        </Segment.Root>
                    );
                })}
            </Space>
            <div ref={chartElement} />
        </div>
    );
}
