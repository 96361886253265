import { SupportedLanguageCode, Survey } from "@vaultinum/vaultinum-api";
import { List, Select } from "@vaultinum/vaultinum-sdk";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import Flag from "../../../../components/Flag";
import { LANGS } from "../../../../constants";
import { getSurveyVersionRevisions } from "../../../../services/surveyService";
import SurveyHistoryEvent from "./SurveyHistoryEvent";

export default function SurveyHistoryView({ surveyVersion }: { surveyVersion: Survey.Version }) {
    const [selectedLang, setSelectedLang] = useState<SupportedLanguageCode | null>();
    const [revisions, setRevisions] = useState<Survey.Version.Revision[]>([]);

    useEffect(
        () => getSurveyVersionRevisions(surveyVersion.surveyKey, surveyVersion.version, setRevisions, { lang: selectedLang }),
        [surveyVersion, selectedLang]
    );

    const revisionsGroupedByDate = Object.entries(groupBy(revisions, revision => revision.changedDate));

    return (
        <span className="flex h-full flex-col gap-4">
            <Select.Basic
                options={LANGS.map(lang => ({
                    label: (
                        <span className="flex items-center">
                            <Flag countryCode={lang.code} />
                            {lang.name}
                        </span>
                    ),
                    value: lang.code
                }))}
                value={selectedLang}
                onChange={setSelectedLang}
                placeholder="Filter by language"
                className="w-34"
            />
            <List
                list={revisionsGroupedByDate}
                render={([changedDate, revisions]) => <SurveyHistoryEvent changedDate={changedDate} revisions={revisions} />}
                isVirtualized
                disableAnimation
            />
        </span>
    );
}
