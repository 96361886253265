import { IbanElement, useElements } from "@stripe/react-stripe-js";
import { StripeIbanElementChangeEvent } from "@stripe/stripe-js";
import * as emailValidator from "email-validator";
import { useEffect, useMemo, useState } from "react";
import { Fieldset, FieldsetInput, Input } from "../../../../design-system";
import { CommonLang, useLang } from "../../../../lang";
import { usePaymentMethodFormContext } from "../../../contexts";

const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"], // Value forced by StripeJS documentation
    placeholderCountry: "FR"
};

function isNameValid(name: string | undefined): boolean {
    return !!name?.trim();
}

function isEmailValid(email: string | undefined): boolean {
    return emailValidator.validate(email?.trim() || "");
}

export default function SepaForm(): JSX.Element {
    const lang = useLang<CommonLang>();
    const elements = useElements();
    const [fieldsetType, setFieldsetType] = useState<"danger" | "success" | undefined>();
    const [hasAccepted, setHasAccepted] = useState<boolean>(false);
    const { isDisabled, setIsFormValid, accountHolderEmail, accountHolderName, setAccountHolderEmail, setAccountHolderName, setIbanElement } =
        usePaymentMethodFormContext();

    useEffect(() => {
        setIsFormValid(isNameValid(accountHolderName) && isEmailValid(accountHolderEmail) && fieldsetType === "success" && hasAccepted);
    }, [accountHolderName, accountHolderEmail, fieldsetType, hasAccepted]);

    const emailFieldsetType = useMemo(() => {
        if (!accountHolderEmail?.trim()) {
            return undefined;
        }
        if (isEmailValid(accountHolderEmail)) {
            return "success";
        }
        return "danger";
    }, [accountHolderEmail]);

    function onIbanElementChange({ error, empty, complete }: StripeIbanElementChangeEvent) {
        const ibanElement = elements?.getElement(IbanElement);
        if (ibanElement) {
            setIbanElement(ibanElement);
        }
        if (error) {
            setFieldsetType("danger");
        } else if (complete) {
            setFieldsetType("success");
        } else if (empty) {
            setFieldsetType(undefined);
        }
    }

    return (
        <>
            <div className="flex flex-col gap-4 sm:flex-row">
                <FieldsetInput
                    label={lang.payment.payment.sepaDebit.accountHolder.name}
                    onChange={e => setAccountHolderName(e.target.value)}
                    placeholder={lang.payment.payment.sepaDebit.accountHolder.namePlaceholder}
                    isRequired
                    isDisabled={isDisabled}
                    type={isNameValid(accountHolderEmail) ? "success" : undefined}
                    className="w-full sm:w-1/2"
                />
                <FieldsetInput
                    label={lang.shared.email}
                    inputType="email"
                    onChange={e => setAccountHolderEmail(e.target.value)}
                    placeholder="email@email.com"
                    isRequired
                    isDisabled={isDisabled}
                    type={emailFieldsetType}
                    className="w-full sm:w-1/2"
                />
            </div>
            <Fieldset label={lang.payment.payment.sepaDebit.iban} type={fieldsetType} isRequired>
                <IbanElement onChange={onIbanElementChange} options={{ ...IBAN_ELEMENT_OPTIONS, disabled: isDisabled }} className="max-w-2xl bg-white py-1" />
            </Fieldset>
            <Input.Checkbox
                id="mandate-acceptance"
                required
                checked={hasAccepted}
                onChange={e => setHasAccepted(e.target.checked)}
                label={lang.payment.payment.sepaDebit.mandateAcceptance}
            />
        </>
    );
}
