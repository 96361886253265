import { Account, LANG_EN, StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import { BaseLang, DeleteIcon, IconTag, ModalButton, formatAddress, openNotificationWithIcon, useIndustryList, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import BaseInfoCard from "../../../components/BaseInfoCard";
import { deleteAccount } from "../../../services/accountService";
import { AccountInvitationModalWithButton } from "../components/AccountInvitationModalWithButton";

export default function AccountCard({ account, staffUser }: { account: Account; staffUser: StaffUser }) {
    const lang = useLang<BaseLang>();
    const industryList = useIndustryList(LANG_EN);
    const [isLoading, setIsLoading] = useState(false);
    async function onDeleteAccount() {
        setIsLoading(true);
        try {
            await deleteAccount(account.id);
        } catch {
            openNotificationWithIcon({ type: "error", description: "Failed to delete the account" });
        } finally {
            setIsLoading(false);
        }
    }
    const accountIndustry = industryList.find(industry => industry.value === account.companyIndustry)?.label || "-";
    return (
        <BaseInfoCard
            country={account.companyNationality}
            creationDate={account.creationDate}
            name={account.companyName}
            attributes={{
                id: account.id,
                industry: accountIndustry,
                size: account.companySize,
                address: account.companyAddress ? formatAddress(account.companyAddress) : undefined
            }}
            {...(account.tags?.includes(Account.Tag.IGNORED) && { footerLeft: <IconTag.Warning message="Ignored" /> })}
            {...(staffUser.roles.includes(StaffUserRole.ADMIN) && {
                footerRight: (
                    <div className="flex flex-col gap-2">
                        <AccountInvitationModalWithButton account={account} />
                        <ModalButton.Confirm
                            icon={DeleteIcon}
                            buttonProps={{
                                children: "Delete account",
                                type: "default",
                                isLoading: false,
                                icon: DeleteIcon,
                                isDisabled: isLoading
                            }}
                            title="Delete account"
                            children={`This will permanently delete account ${account.companyName}`}
                            onConfirm={onDeleteAccount}
                            lang={lang}
                        />
                    </div>
                )
            })}
        />
    );
}
