import { Account, UserProfile } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { useEffect } from "react";
import { Alert, BaseCardContainer, Button, InfoCircleIcon } from "../../../design-system";
import { CommonLang, getLangCode, useLang } from "../../../lang";
import { usePaymentContext } from "../../contexts";
import { formatName } from "../../helpers";
import { useModal } from "../../hooks";
import { updateAccountInfo } from "../../services";
import { LogoSpinner } from "../LogoSpinner";
import UpdateCompanyInformationModal from "./UpdateCompanyInformationModal";

function InformationEntry({ label, value, isRequired = true }: { label: string; value?: string; isRequired?: boolean }): JSX.Element {
    const lang = useLang();
    const emptyRender = isRequired ? <span className="text-danger">-</span> : <span className="italic">{lang.payment.billing.notProvided}</span>;
    return (
        <div className="block md:flex">
            <div className={classNames("w-60 font-bold", isRequired && !value ? "text-danger" : "text-primary")}>{label}</div>
            <span className="font-light">{value || emptyRender}</span>
        </div>
    );
}

function AddressEntry({ label, account }: { label: string; account: Account }): JSX.Element {
    const lang = useLang();
    const address = account.billingDetails?.address;

    useEffect(() => {
        void (async function () {
            if (!account.billingDetails?.address) {
                await updateAccountInfo(account);
            }
        })();
    }, [account.billingDetails]);

    if (address?.city && address?.country && address.line1 && address?.postalCode) {
        return <InformationEntry label={label} value={`${address.line1}, ${address.city}, ${address.postalCode}, ${address.country}`} />;
    }

    return (
        <div className="flex font-bold text-danger">
            <div className="w-60">{label}</div>
            <ul>
                {!address?.line1 && <li>{lang.payment.billing.address1}</li>}
                {!address?.city && <li>{lang.payment.billing.city}</li>}
                {!address?.postalCode && <li>{lang.payment.billing.zipCode}</li>}
                {!address?.country && <li>{lang.payment.billing.country}</li>}
            </ul>
        </div>
    );
}

export default function BillingInformation({ userProfile }: { userProfile: UserProfile }): JSX.Element {
    const lang = useLang<CommonLang>();
    const { account, isProceeding, isBillingInformationComplete, setIsBillingInformationComplete } = usePaymentContext();
    const { isOpen, doOpen, doClose } = useModal();

    useEffect(() => {
        setIsBillingInformationComplete(
            !!userProfile.lastName &&
                !!userProfile.firstName &&
                !!account &&
                !!account.companyName &&
                !!account.billingDetails?.address?.city &&
                !!account.billingDetails?.address?.country &&
                !!account.billingDetails?.address?.line1 &&
                !!account.billingDetails?.address?.postalCode
        );
    }, [account, userProfile]);

    if (!account) {
        return <LogoSpinner />;
    }

    return (
        <div className="space-y-4" data-id="billing-information">
            <div className="flex items-center justify-between space-x-3">
                <div className="flex items-center gap-2">
                    <InfoCircleIcon color="slate" shade="extraLight" size="md" />
                    <span className="text-lg font-bold text-primary">{lang.payment.billing.billingInformation}</span>
                </div>
                <Button
                    size="sm"
                    type="default"
                    onClick={doOpen}
                    isLoading={isProceeding}
                    isDisabled={isProceeding}
                    data-id="btn-billing-update"
                    children={lang.shared.update}
                />
            </div>
            <BaseCardContainer
                children={
                    <div className="space-y-3 p-5">
                        <div className="flex space-x-4 font-light">
                            <p>{lang.payment.billing.billingDisclaimer}</p>
                        </div>
                        {!isBillingInformationComplete && (
                            <Alert.Danger data-id="billing-information-missing" message={lang.payment.billing.formWarningMissingDetails} />
                        )}
                        <InformationEntry label={lang.shared.name} value={formatName(userProfile)} />
                        <InformationEntry label={lang.payment.billing.companyName} value={account.billingDetails?.name || account.companyName} />
                        <AddressEntry label={lang.payment.billing.address} account={account} />
                        <InformationEntry label={lang.payment.billing.vatNumber} value={account.billingDetails?.vatNumber} isRequired={false} />
                        <UpdateCompanyInformationModal
                            closeModal={doClose}
                            account={account}
                            langCode={getLangCode(userProfile)}
                            onlyUpdateBillingInfo
                            isOpen={isOpen}
                            requireAddress
                        />
                    </div>
                }
            />
        </div>
    );
}
