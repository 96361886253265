import { Alert, BaseLang, CheckIcon, ModalButton, openNotificationWithIcon, useLang } from "@vaultinum/vaultinum-sdk";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleAsyncActionWithMessages } from "../../../../../common/AsyncTools";
import { CodeAuditContext } from "../../../../../contexts/CodeAuditContext";
import { archiveAndRemoveCodeAuditReportFromFullAudit } from "../../../../../services/codeAnalysisReportService";
import { ABSOLUTE_KYS2_PAGE } from "../../../../../services/routing.service";

export default function ReOpenCodeUploadModal(): JSX.Element {
    const lang = useLang<BaseLang>();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { report } = useContext(CodeAuditContext);

    async function reopen() {
        if (!report) {
            return;
        }
        setIsLoading(true);
        try {
            await handleAsyncActionWithMessages(() =>
                archiveAndRemoveCodeAuditReportFromFullAudit(report)
                    .then(() => "Code audit review reopened")
                    .catch(() => {
                        throw new Error("An error occured while reopening the code upload");
                    })
            );
            navigate(ABSOLUTE_KYS2_PAGE);
        } catch {
            openNotificationWithIcon({ type: "error", description: "An error occured while reopening the code upload" });
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <ModalButton
            icon={CheckIcon}
            buttonProps={{
                children: "Re-open code upload",
                isLoading: false,
                icon: CheckIcon,
                isDisabled: isLoading
            }}
            title="Re-open code upload"
            children={
                <div className="space-y-2">
                    <h4>Are you sure you want to re-open the code upload?</h4>
                    <Alert.Warning message="This will archive the current code report of the audit and will allow the client to re-upload ZIPs" />
                </div>
            }
            onConfirm={reopen}
            lang={lang}
            size="md"
        />
    );
}
