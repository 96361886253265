import { Result } from "antd";
import { ResultStatusType } from "antd/lib/result";
import { ReactNode } from "react";
import { Skeleton } from "../Skeleton";

/**
 * Responsive card with a left and right panel used for small form pages (authentication pages for example).
 * Left panel (called "extra") should not contain important elements since it gets hidden on small screens.
 */
export default function CardWithFeedback({
    extra,
    title,
    subtitle,
    isLoading,
    status,
    successTitle,
    successExtra,
    errorTitle,
    errorExtra,
    children
}: {
    extra: ReactNode;
    title?: string;
    subtitle?: string;
    isLoading?: boolean;
    status?: "success" | "error";
    successTitle?: ReactNode;
    successExtra?: ReactNode;
    errorTitle?: ReactNode;
    errorExtra?: ReactNode;
    children?: ReactNode;
}): JSX.Element {
    const getContent = (resultStatus?: ResultStatusType): ReactNode => {
        switch (resultStatus) {
            case "success":
                return <Result status={resultStatus} title={successTitle} extra={successExtra} />;
            case "error":
                return <Result status={resultStatus} title={errorTitle} extra={errorExtra} />;
            default:
                return children;
        }
    };

    return (
        <div className="bg-white drop-shadow min-w-1/2 max-w-screen-lg rounded-2xl shadow-md">
            <div className="grid grid-cols-1 md:grid-cols-2">
                {isLoading ? (
                    <div className="flex flex-col gap-4 p-6">
                        {Array.from({ length: 3 }).map((_, index) => (
                            <Skeleton key={index} className="h-4 w-full" />
                        ))}
                        <Skeleton className="h-4 w-2/3" />
                    </div>
                ) : (
                    <div>
                        {!status && (
                            <div className="flex flex-col items-center text-center p-6">
                                <h1 className="font-bold text-2xl text-slate-dark">{title}</h1>
                                <div className="max-w-96 text-slate-primary">{subtitle}</div>
                            </div>
                        )}
                        <div className="p-6">{getContent(status)}</div>
                    </div>
                )}
                <div className="hidden items-center justify-center p-4 md:flex border-l">{extra}</div>
            </div>
        </div>
    );
}
