import { Note } from "@vaultinum/vaultinum-api";
import { ReactNode } from "react";
import { Drawer, SurveyIcon, useLang } from "../../../../common";
import NoteForm from "./NoteForm";

export default function NoteDrawer({
    onClose,
    onDelete,
    onSave,
    isVisible,
    notes,
    isReviewer,
    header,
    accountName
}: {
    onClose: () => void;
    onDelete: (noteId: string) => Promise<void>;
    onSave: (text: string, visibility: Note.Visibility, noteId?: string) => void | Promise<void>;
    isVisible: boolean;
    notes?: (Note & { userName: string })[];
    isReviewer?: boolean;
    header?: ReactNode;
    accountName?: string;
}): JSX.Element {
    const lang = useLang();

    return (
        <Drawer
            header={{
                title: lang.accountInformation.notes.title,
                Icon: SurveyIcon
            }}
            children={
                <div className="w-full">
                    <NoteForm isReviewer={isReviewer} notes={notes} header={header} accountName={accountName} onSave={onSave} onDelete={onDelete} />
                </div>
            }
            isVisible={isVisible}
            onClose={onClose}
            size="lg"
        />
    );
}
