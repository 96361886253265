import { StaffUser, StaffUserRole } from "@vaultinum/vaultinum-api";
import {
    AgreementLogo,
    ColCard,
    CompanyIcon,
    DepositLogo,
    EscrowLogo,
    FullAuditLogo,
    GitIcon,
    IconProps,
    MailIcon,
    OnlineAssessmentLogo,
    ReportIcon,
    ScopeCyberIcon,
    Separator,
    UnplugIcon,
    UserIcon
} from "@vaultinum/vaultinum-sdk";
import { ElementType } from "react";
import { useNavigate } from "react-router-dom";
import { AppName } from "../../../helpers";
import {
    ABSOLUTE_KYS1_PAGE,
    ABSOLUTE_KYS2_PAGE,
    ACCOUNTS_PAGE,
    DEPOSIT_PAGE,
    DOMAINS_PAGE,
    ESCROW_PAGE,
    EVENTS_PAGE,
    LPR_PAGE,
    NDA_PAGE,
    PROMOTIONS_PAGE,
    STAFF_USERS_PAGE,
    USER_PROFILES_PAGE
} from "../../../services/routing.service";

const operations = [
    {
        title: "Staff Users",
        href: STAFF_USERS_PAGE,
        icon: ScopeCyberIcon,
        requiredRoles: [StaffUserRole.ADMIN],
        visibleIn: [AppName.VAULTINUM, AppName.EVIDENCY]
    },
    {
        title: "User Profiles",
        href: USER_PROFILES_PAGE,
        icon: UserIcon,
        requiredRoles: [StaffUserRole.CLIENT_SERVICE],
        visibleIn: [AppName.VAULTINUM, AppName.EVIDENCY]
    },
    {
        title: "Organisations",
        href: ACCOUNTS_PAGE,
        icon: CompanyIcon,
        requiredRoles: [StaffUserRole.ADMIN, StaffUserRole.CLIENT_SERVICE],
        visibleIn: [AppName.VAULTINUM, AppName.EVIDENCY]
    },
    {
        title: "Domains",
        href: DOMAINS_PAGE,
        icon: UnplugIcon,
        requiredRoles: [StaffUserRole.ADMIN],
        visibleIn: [AppName.VAULTINUM, AppName.EVIDENCY]
    },
    {
        title: "Events",
        href: EVENTS_PAGE,
        icon: GitIcon,
        requiredRoles: [StaffUserRole.ADMIN, StaffUserRole.CLIENT_SERVICE],
        visibleIn: [AppName.VAULTINUM]
    },
    {
        title: "Promotions",
        href: PROMOTIONS_PAGE,
        icon: MailIcon,
        requiredRoles: [StaffUserRole.ADMIN],
        visibleIn: [AppName.VAULTINUM]
    }
];

const vault = [
    {
        title: "IP Deposit",
        href: DEPOSIT_PAGE,
        icon: DepositLogo,
        requiredRoles: [StaffUserRole.CLIENT_SERVICE],
        visibleIn: [AppName.VAULTINUM]
    },
    {
        title: "Escrow",
        href: ESCROW_PAGE,
        icon: EscrowLogo,
        requiredRoles: [StaffUserRole.CLIENT_SERVICE],
        visibleIn: [AppName.VAULTINUM]
    }
];

const kys = [
    {
        title: "Non-disclosure Agreement",
        href: NDA_PAGE,
        icon: AgreementLogo,
        requiredRoles: [StaffUserRole.ADMIN],
        visibleIn: [AppName.VAULTINUM]
    },
    {
        title: "Code Audit",
        href: ABSOLUTE_KYS2_PAGE,
        icon: FullAuditLogo,
        requiredRoles: [StaffUserRole.ADMIN],
        visibleIn: [AppName.VAULTINUM]
    },
    {
        title: "Survey Editor",
        href: ABSOLUTE_KYS1_PAGE,
        icon: OnlineAssessmentLogo,
        requiredRoles: [StaffUserRole.KYS1_REVIEWER],
        visibleIn: [AppName.VAULTINUM]
    }
];

const evidency = [
    {
        title: "LPR",
        href: LPR_PAGE,
        icon: ReportIcon,
        requiredRoles: [StaffUserRole.ADMIN],
        visibleIn: [AppName.EVIDENCY]
    }
];

function Item({ title, href, icon: Icon }: { title: string; href: string; icon?: ElementType<IconProps> }) {
    const navigate = useNavigate();
    return (
        <div className="rounded-lg border transition-all ease-in-out hover:scale-105 hover:border-pink-dark focus:border-pink-dark">
            <ColCard
                children={
                    <div className="flex flex-col w-full items-center justify-center gap-2 p-4">
                        {Icon && <Icon color="slate" size="lg" />}
                        {title}
                    </div>
                }
                onClick={() => navigate(href)}
            />
        </div>
    );
}

function Section({
    title,
    items,
    staffUser
}: {
    title: string;
    items: { title: string; href: string; requiredRoles: StaffUserRole[]; icon?: ElementType<IconProps>; visibleIn?: AppName[] }[];
    staffUser: StaffUser | null;
}) {
    return (
        <>
            <h3 className="pb-2 font-light">{title}</h3>
            <div className="grid grid-cols-4 gap-4 pb-2">
                {items
                    .filter(item => item.visibleIn?.includes((process.env.REACT_APP_BRAND_NAME || AppName.VAULTINUM) as AppName))
                    .map(({ title, href, icon, requiredRoles }) => {
                        if (!requiredRoles || (staffUser && requiredRoles.some(role => staffUser.roles.includes(role)))) {
                            return <Item title={title} href={href} icon={icon} key={title} />;
                        }
                        return null;
                    })}
            </div>
        </>
    );
}

export default function HomePage({ staffUser, roles }: { staffUser: StaffUser | null; roles: StaffUserRole[] }) {
    if (!roles.length) {
        return null;
    }

    return (
        <div className="flex h-screen flex-col items-center">
            <h1 className="pt-4 pb-10">Vaultinum Reserved Section</h1>
            <div className="flex max-w-5xl flex-col gap-8">
                <Section title="Operations" items={operations} staffUser={staffUser} />
                {process.env.REACT_APP_BRAND_NAME !== AppName.EVIDENCY && (
                    <>
                        <Separator />
                        <Section title="KYS" items={kys} staffUser={staffUser} />
                        <Separator />
                        <Section title="Deposit / Escrow" items={vault} staffUser={staffUser} />
                    </>
                )}
                {process.env.REACT_APP_BRAND_NAME === AppName.EVIDENCY && (
                    <>
                        <Separator />
                        <Section title="Evidency" items={evidency} staffUser={staffUser} />
                    </>
                )}
            </div>
        </div>
    );
}
