/* eslint-disable @typescript-eslint/prefer-for-of */
import classNames from "classnames";
import { Chart, ReactGoogleChartProps } from "react-google-charts";
import { CommonChartParams, commonAxisOption, getLegendConfig, getOnClickEvent } from "../config";
import { NoData } from "../shared/NoData";

export enum ORIENTATION {
    VERTICAL = "vertical",
    HORIZONTAL = "horizontal"
}

export type BarChartParams = CommonChartParams & {
    titles: string[]; // title under each bar
    colors?: string[];
    orientation?: ORIENTATION;
    xTitle?: string;
    yTitle?: string;
    xMaxValue?: number;
    yMaxValue?: number;
    isStacked?: boolean;
    onClick?: (selectedItem: { datasetIndex: number; dataIndex: number }) => void;
};

type ChartDataHeader = string | { role: string; type?: string; p?: { html: boolean } };
type ChartDataRow = string | number | null;
type ChartData = ChartDataHeader[] | ChartDataRow[];

function buildChartData(
    dataset: CommonChartParams["dataset"],
    titles: string[],
    colors?: string[],
    tooltip?: (item: { label: string; values: (string | number)[] }, index: number) => string
): ReactGoogleChartProps["data"] {
    const data: ChartData[] = [];

    // Build data header
    const header: ChartDataHeader[] = ["key"];

    for (let i = 0; i < dataset.length; i++) {
        header.push(dataset[i].label);
        if (tooltip) {
            header.push({ role: "tooltip", type: "string", p: { html: true } });
        }
    }

    // If the dataset contains only one item, add the "style" role to the header
    if (dataset.length === 1) {
        // The "style" role is used to define visual styling, such as color, for each data point in the chart. In this case, it enables individual bars to have different colors
        header.push({ role: "style" });
    }

    data.push(header);

    // Build data row
    for (let i = 0; i < titles.length; i++) {
        const row: ChartDataRow[] = [titles[i]];

        // For each dataset, extract the corresponding data value for the current title
        for (let j = 0; j < dataset.length; j++) {
            row.push(dataset[j].data[i]);

            // If a tooltip function is provided, generate the tooltip for this data point
            if (tooltip) {
                row.push(tooltip({ label: titles[i], values: [dataset[j].data[i]] }, i));
            }
        }

        // If there's only one dataset, add color to the row (for styling purposes)
        if (dataset.length === 1) {
            row.push(colors ? colors[i] : null);
        }

        data.push(row);
    }

    return data;
}

const BAR_THICKNESS = 17;
const PADDING = 40;

export function BarChart({
    titles,
    dataset,
    colors,
    orientation = ORIENTATION.VERTICAL,
    xTitle,
    yTitle,
    width = "100%",
    height = "auto",
    isStacked = false,
    legendPosition = "top",
    xMaxValue,
    yMaxValue,
    tooltip,
    onClick
}: BarChartParams): JSX.Element {
    if (!dataset.length) {
        return <NoData width={width} height={height} />;
    }
    const chartProps = {
        width,
        options: {
            legend: getLegendConfig(legendPosition, "center", { fontSize: 14, margin: 15 }),
            vAxis: {
                title: yTitle,
                ...(yMaxValue ? { viewWindow: { min: 0, max: yMaxValue } } : {}),
                ...commonAxisOption
            },
            hAxis: {
                title: xTitle,
                ...(xMaxValue ? { viewWindow: { min: 0, max: xMaxValue } } : {}),
                ...commonAxisOption
            },
            bar: { groupWidth: "90%" },
            isStacked,
            colors,
            tooltip: { isHtml: true }
        },
        data: buildChartData(dataset, titles, colors, tooltip),
        chartEvents: onClick
            ? [getOnClickEvent(({ row: dataIndex = 0, column: datasetIndex = 0 }) => onClick({ dataIndex, datasetIndex: datasetIndex - 1 }))]
            : [],
        legendToggle: true
    };

    if (orientation === ORIENTATION.VERTICAL) {
        return <Chart chartType="ColumnChart" height={height} {...chartProps} />;
    }

    const rowCount = dataset[0]?.data.length ?? 0;
    const chartHeight = typeof height === "number" ? Math.max(height, rowCount * BAR_THICKNESS + PADDING * 2) : height;
    return (
        <div style={{ height }} className={classNames({ "overflow-y-auto": chartHeight > height })}>
            <Chart
                chartType="BarChart"
                height={chartHeight}
                {...{
                    ...chartProps,
                    options: {
                        ...chartProps.options,
                        chartArea: { top: PADDING, bottom: PADDING, right: PADDING, left: 250 },
                        vAxis: { ...chartProps.options.vAxis, textStyle: { fontSize: 15 } }
                    }
                }}
            />
        </div>
    );
}
