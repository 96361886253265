import { SurveyReport } from "@vaultinum/vaultinum-api";
import { COLOR_SCHEME } from "../../design-system/referentials";

const TOLERANCE = 5;
const VERY_NEGATIVE_COEFF = 2 / 3;
const ReportColors = {
    COLOR_SLATE: COLOR_SCHEME.slate.primary,
    COLOR_GREEN: COLOR_SCHEME.green.primary,
    COLOR_GREEN_BRIGHTEN_10: COLOR_SCHEME.green.light,
    COLOR_GREEN_BRIGHTEN_20: COLOR_SCHEME.green.extraLight,
    COLOR_ORANGE: COLOR_SCHEME.orange.primary,
    COLOR_RED: COLOR_SCHEME.red.primary,
    COLOR_RED_BRIGHTEN_10: COLOR_SCHEME.red.light,
    COLOR_RED_BRIGHTEN_20: COLOR_SCHEME.red.extraLight,
    COLOR_UNKNOWN: "#7BC27B",
    COLOR_YELLOW: "#E0BC00"
};

export function formatBenchmarkColor(evaluation: SurveyReport.Evaluation): string {
    switch (evaluation) {
        case SurveyReport.Evaluation.POSITIVE:
            return ReportColors.COLOR_GREEN;
        case SurveyReport.Evaluation.IN_RANGE:
            return ReportColors.COLOR_GREEN_BRIGHTEN_10;
        case SurveyReport.Evaluation.NEGATIVE:
            return ReportColors.COLOR_ORANGE;
        case SurveyReport.Evaluation.VERY_NEGATIVE:
            return ReportColors.COLOR_RED;
        default:
            return ReportColors.COLOR_SLATE;
    }
}

function formatScoreToBenchmarkEvaluation(score: number, benchmarkScore: number): SurveyReport.Evaluation {
    if (!benchmarkScore) {
        return SurveyReport.Evaluation.NONE;
    }
    if (score >= benchmarkScore) {
        return SurveyReport.Evaluation.POSITIVE;
    }
    if (score >= benchmarkScore - TOLERANCE) {
        return SurveyReport.Evaluation.IN_RANGE;
    }
    if (score <= benchmarkScore * VERY_NEGATIVE_COEFF) {
        return SurveyReport.Evaluation.VERY_NEGATIVE;
    }
    if (score < benchmarkScore - TOLERANCE) {
        return SurveyReport.Evaluation.NEGATIVE;
    }
    return SurveyReport.Evaluation.NONE;
}

export function formatRiskColor(risk?: SurveyReport.Evaluation, variant?: number): string {
    switch (risk) {
        case SurveyReport.Evaluation.VERY_NEGATIVE:
            return (
                (variant && [ReportColors.COLOR_RED, ReportColors.COLOR_RED_BRIGHTEN_10, ReportColors.COLOR_RED_BRIGHTEN_20][variant]) || ReportColors.COLOR_RED
            );
        case SurveyReport.Evaluation.NEGATIVE:
            return ReportColors.COLOR_ORANGE;
        case SurveyReport.Evaluation.IN_RANGE:
            return ReportColors.COLOR_GREEN_BRIGHTEN_10;
        case SurveyReport.Evaluation.POSITIVE:
            return (
                (variant && [ReportColors.COLOR_GREEN, ReportColors.COLOR_GREEN_BRIGHTEN_10, ReportColors.COLOR_GREEN_BRIGHTEN_20][variant]) ||
                ReportColors.COLOR_GREEN
            );
        case SurveyReport.Evaluation.NONE:
        default:
            return ReportColors.COLOR_UNKNOWN;
    }
}

export function formatScoreColor(score: number): string {
    if (score > 75) {
        return formatRiskColor(SurveyReport.Evaluation.POSITIVE);
    }
    if (score > 50) {
        return formatRiskColor(SurveyReport.Evaluation.IN_RANGE);
    }
    if (score > 25) {
        return formatRiskColor(SurveyReport.Evaluation.NEGATIVE);
    }
    return formatRiskColor(SurveyReport.Evaluation.VERY_NEGATIVE);
}

export function formatScoreToBenchmarkColor(score: number, benchmarkScore: number): string {
    const evaluation = formatScoreToBenchmarkEvaluation(score, benchmarkScore);
    return formatBenchmarkColor(evaluation);
}
