import { Account, Deposit, Escrow, isDefined } from "@vaultinum/vaultinum-api";
import { CodeIcon, Column, CreditCardIcon, FolderIcon, IconTag, SurveyIcon, Tag, Tooltip } from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { Dictionary, keyBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import AccountLink from "../../../components/AccountLink";
import { SearchBarValues } from "../../../components/SearchBar";
import { escrowProgressStatusToType } from "../../../helpers";
import { getAllAccounts } from "../../../services/accountService";
import { getEscrowLink } from "../../../services/routing.service";
import { getDeposits, getEscrows, PAID_OFFLINE } from "../../../services/vaultService";
import { StandardModelListView } from "../components/StandardModelListView";
import { DepositEscrowActions } from "./DepositEscrowActions";
import { DepositTagAction, DepositTagColor } from "./DepositTagAction";
import { PaymentLink } from "./PaymentLink";

function getColumns(accounts: Account[], depositStoreIndex: Dictionary<Deposit>): Column<Escrow>[] {
    const getBeneficiaryDetails = (escrow: Escrow): string | undefined => {
        const companyName = accounts.find(account => account.id === escrow.beneficiary?.accountId)?.companyName;
        return escrow.beneficiary?.company?.name || companyName || escrow.beneficiary?.contacts.map(contact => contact.email).join(", ");
    };

    const getSupplierDetails = (escrow: Escrow): string | undefined => {
        const companyName = accounts.find(account => account.id === escrow.supplier?.accountId)?.companyName;
        return escrow.supplier?.company?.name || companyName || escrow.supplier?.contacts.map(contact => contact.email).join(", ");
    };

    return [
        {
            header: "Parties",
            enableSorting: false,
            accessorFn: row => [
                row.supplier?.company?.name || row.supplier?.contacts?.map(contact => contact.email).join(", "),
                row.beneficiary?.company?.name || row.beneficiary?.contacts?.map(contact => contact.email).join(", ")
            ],
            cell: cell => {
                const escrow = cell.row.original;
                const supplierAccountId = accounts.find(account => account.id === escrow.supplier?.accountId)?.id;
                const beneficiaryAccountId = accounts.find(account => account.id === escrow.beneficiary?.accountId)?.id;
                const isAccessClause = escrow.type === Escrow.Type.ACCESS_CLAUSE;
                return (
                    <div className="flex cursor-default flex-col space-y-1">
                        <Tooltip placement="left" title="Supplier">
                            <div className="flex items-center gap-2">
                                <span>🧑‍💻</span>
                                {supplierAccountId ? (
                                    <>
                                        <AccountLink accountId={supplierAccountId} text={getSupplierDetails(escrow)} />
                                        {escrow.payerAccountId === supplierAccountId && (
                                            <Tooltip title="Payer">
                                                <Tag size="sm" icon={CreditCardIcon} type="info" />
                                            </Tooltip>
                                        )}
                                    </>
                                ) : (
                                    escrow.supplier?.contacts?.map(contact => contact.email).join(", ") || <span className="italic text-grey-light">N/A</span>
                                )}
                            </div>
                        </Tooltip>
                        <Tooltip placement="left" title="Beneficiary">
                            <div className="flex items-center gap-2">
                                <span>🔖</span>
                                {isAccessClause && <span className="text-neutral-300">N/A</span>}
                                {!isAccessClause &&
                                    (beneficiaryAccountId ? (
                                        <>
                                            <AccountLink accountId={beneficiaryAccountId} text={getBeneficiaryDetails(escrow)} />
                                            {escrow.payerAccountId === beneficiaryAccountId && (
                                                <Tooltip title="Payer">
                                                    <Tag size="sm" icon={CreditCardIcon} type="info" />
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : (
                                        escrow.beneficiary?.contacts?.map(contact => contact.email).join(", ") || (
                                            <span className="italic text-grey-light">N/A</span>
                                        )
                                    ))}
                            </div>
                        </Tooltip>
                    </div>
                );
            }
        },
        {
            header: "Escrow Type",
            accessorFn: row => row.type?.replace("_", " "),
            size: 130
        },
        {
            header: "Escrow Status",
            accessorKey: "progressStatus",
            cell: cell => {
                const escrow = cell.row.original;
                // A user can see its escrow as active. As admin, we want to directly see the verification pending status from the main view
                // Only the first deposit can be verified
                const deposit = escrow.depositStoreIds?.[0] ? depositStoreIndex[escrow.depositStoreIds[0]] : null;
                const verificationPending = deposit?.status === Deposit.Status.VERIFICATION_PENDING;
                const type = verificationPending ? "warning" : escrowProgressStatusToType(escrow.progressStatus);
                const status = verificationPending ? Deposit.Status.VERIFICATION_PENDING : escrow.progressStatus;
                return <Tag type={type} message={status.replace("_", " ")} />;
            },
            size: 170
        },
        {
            header: "Payment",
            accessorFn: row => row.paymentId,
            cell: cell => {
                const escrow = cell.row.original;
                if (escrow.paymentId) {
                    return <PaymentLink isPaidOffline={escrow.paymentId === PAID_OFFLINE} subscriptionId={escrow.paymentId} />;
                }
                return <DepositTagAction color={DepositTagColor.ORANGE} children="PENDING" />;
            },
            size: 130
        },
        {
            header: "Deposit Stores",
            accessorKey: "depositStoreIds",
            enableColumnFilter: false,
            cell: cell => {
                const depositStoreIds = cell.getValue<string[]>();
                return (
                    <div className="flex justify-center space-x-2">
                        <Tooltip
                            title="Source code deposit"
                            children={<DepositTagAction color={depositStoreIds?.[0] ? DepositTagColor.GREEN : DepositTagColor.GREY} children={<CodeIcon />} />}
                        />
                        <Tooltip
                            title="Additional data deposit"
                            children={
                                <DepositTagAction color={depositStoreIds?.[1] ? DepositTagColor.GREEN : DepositTagColor.GREY} children={<FolderIcon />} />
                            }
                        />
                    </div>
                );
            },
            size: 140
        },
        {
            header: "Contract",
            accessorFn: row => !!row.isContractAvailable,
            cell: cell => {
                const isContractAvailable = cell.getValue<boolean>();
                return (
                    <Tooltip
                        title={isContractAvailable ? "Contract uploaded" : "Contract not uploaded"}
                        children={
                            <div
                                className={classNames("h-6 w-6 rounded-md p-1", {
                                    "bg-green-light bg-opacity-30 text-green-dark": isContractAvailable,
                                    "bg-grey-light bg-opacity-30 text-grey-dark": !isContractAvailable
                                })}
                            >
                                <SurveyIcon />
                            </div>
                        }
                    />
                );
            },
            size: 100
        },
        {
            header: "Archived",
            accessorFn: row => !!row.isArchived,
            cell: cell => {
                const isArchived = cell.getValue<boolean>();
                return (
                    isArchived && (
                        <div className="flex justify-center">
                            <IconTag.Archived size="sm" />
                        </div>
                    )
                );
            },
            size: 110,
            defaultFilteredValues: [false]
        }
    ];
}

export default function EscrowsView({ ignoredAccountIds }: { ignoredAccountIds: string[] }): JSX.Element {
    const [deposits, setDeposits] = useState<Deposit[]>([]);
    const [accounts, setAccounts] = useState<Account[]>([]);

    const depositStoreIndex = useMemo(() => keyBy(deposits, deposit => deposit.depositStoreId), [deposits]);

    useEffect(() => getDeposits(setDeposits, true), []);
    useEffect(() => getAllAccounts(setAccounts), []);

    function getEscrowDeposits(escrow: Escrow) {
        return escrow.depositStoreIds?.length ? escrow.depositStoreIds.map(depositStoreId => depositStoreIndex[depositStoreId]).filter(isDefined) : null;
    }

    function applySearchOptions(escrow: Escrow, { search, hideIgnored }: SearchBarValues) {
        const deposits = getEscrowDeposits(escrow);

        if (deposits?.length && !deposits?.some(deposit => `${deposit?.iddn} ${deposit?.name}`.toLowerCase().includes(search))) {
            return false;
        }
        if (
            hideIgnored &&
            (ignoredAccountIds?.includes(escrow.accountId) || (escrow.beneficiary?.accountId && ignoredAccountIds?.includes(escrow.beneficiary.accountId)))
        ) {
            return false;
        }
        return true;
    }

    const columns = useMemo(() => getColumns(accounts, depositStoreIndex), [accounts, depositStoreIndex]);

    return (
        <StandardModelListView<Escrow>
            name="Escrows"
            getModelItems={getEscrows}
            applySearchOptions={applySearchOptions}
            modelPageLink={escrow => getEscrowLink(escrow.id)}
            columns={columns}
            extraActions={escrow => {
                const deposits = getEscrowDeposits(escrow);
                return <DepositEscrowActions escrow={escrow} deposits={deposits || []} />;
            }}
            storageKey="table-escrows"
            actionColumnSize={250}
            isVirtualized
        />
    );
}
