import { LanguageCode } from "@vaultinum/vaultinum-api";
import { Select } from "../../../design-system";
import { getCountriesData } from "../../tools";

export default function NationalitySelect({
    langCode,
    label,
    disabled,
    onChange,
    isRequired,
    ...props
}: {
    langCode: LanguageCode;
    label: string;
    onChange: (option: string | null) => void;
    disabled?: boolean;
    isRequired?: boolean;
}): JSX.Element {
    const translatedCountryList = getCountriesData(langCode);
    translatedCountryList.sort((a, b) => a.name.localeCompare(b.name));
    const countries = translatedCountryList.map(({ cca2, name }) => ({ value: cca2, label: name }));

    return <Select.Search {...props} label={label} options={countries} isDisabled={disabled} onChange={onChange} required={isRequired} />;
}
