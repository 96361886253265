import { useLang } from "../../../../lang";
import { WarningIcon } from "../../Icons";

export function NoData({ width, height, hasFilters = false }: { width: number | string; height: number | string; hasFilters?: boolean }): JSX.Element {
    const lang = useLang();
    return (
        <div className="flex flex-col gap-2 items-center justify-center text-grey-primary" style={{ width, height }}>
            <WarningIcon size="lg" color="grey" />
            {lang.chart.noData(hasFilters)}
        </div>
    );
}
