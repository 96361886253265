import { SupportedLanguageCode, Survey } from "@vaultinum/vaultinum-api";
import { BaseLang, Button, Modal, openNotificationWithIcon, useLang } from "@vaultinum/vaultinum-sdk";
import { useContext, useState } from "react";
import { translateSurvey } from "../services/surveyLangService";
import { SurveyBuilderContext } from "../views/private/kys1/survey-builder/SurveyBuilderContext";
import TranslateSurvey from "./TranslateSurvey";

export default function TranslateSurveyModalWithButton({
    surveyVersion,
    surveyLangs,
    sourceLang
}: {
    surveyVersion: Survey.Version;
    surveyLangs: Survey.Lang[];
    sourceLang: SupportedLanguageCode;
}): JSX.Element {
    const lang = useLang<BaseLang>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedTargetLang, setSelectedTargetLang] = useState<SupportedLanguageCode | null>();
    const { isWorking, setIsWorking } = useContext(SurveyBuilderContext);
    const [createSuggestionComments, setCreateSuggestionComments] = useState<boolean>(false);

    const translate = async () => {
        if (selectedTargetLang) {
            setIsWorking(true);
            try {
                await translateSurvey(surveyVersion, sourceLang, selectedTargetLang, createSuggestionComments);
                closeModal();
            } catch (e) {
                openNotificationWithIcon({ type: "error", message: "An error occured during translation process" });
            } finally {
                setIsWorking(false);
            }
        }
    };

    const onChange = (selectedLang: SupportedLanguageCode | null) => {
        setSelectedTargetLang(selectedLang);
    };

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const onCreateSuggestionCommentsChange = (checked: boolean) => {
        setCreateSuggestionComments(checked);
    };

    return (
        <>
            {sourceLang && (
                <>
                    <Button isLoading={isWorking} onClick={openModal}>
                        Translate
                    </Button>
                    <Modal
                        title="Translate survey"
                        onConfirm={translate}
                        isLoading={isWorking}
                        onClose={closeModal}
                        isOpen={isOpen}
                        okText="Translate"
                        children={
                            <TranslateSurvey
                                surveyVersion={surveyVersion}
                                sourceLang={sourceLang}
                                selectedTargetLang={selectedTargetLang}
                                surveyLangs={surveyLangs}
                                onSelectedLangChange={onChange}
                                createSuggestionComments={createSuggestionComments}
                                onCreateSuggestionCommentsChange={onCreateSuggestionCommentsChange}
                            />
                        }
                        lang={lang}
                    />
                </>
            )}
        </>
    );
}
