import { Account, LANG_EN } from "@vaultinum/vaultinum-api";
import { Column, formatDateTime } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import TableLayout from "../../../components/TableLayout";
import { getPartners } from "../../../services/accountService";

export default function AccountPartners({ account }: { account: Account }) {
    const [partners, setPartners] = useState<Account.Partner[]>();
    useEffect(() => getPartners(account.id, setPartners), [account]);

    const partnersColumns: Column<Account.Partner>[] = [
        {
            header: "Id",
            accessorKey: "partnerAccountId"
        },
        {
            header: "Name",
            accessorKey: "name"
        },
        {
            header: "Email",
            accessorKey: "mainContactEmail"
        },
        {
            header: "Creation date",
            accessorKey: "creationDate",
            cell: cell => formatDateTime(cell.getValue<Date>(), LANG_EN)
        }
    ];

    return <TableLayout<Account.Partner> title="Partners" items={partners} columns={partnersColumns} />;
}
