import {
    calculateSurveyScoreImpact,
    calculateTagScoresSummary,
    getNodeTargetValue,
    query,
    Question,
    ScoreImpact,
    sum,
    Survey,
    updateSurveyLangOption,
    updateSurveyLangOptionFix
} from "@vaultinum/vaultinum-api";
import { AntdTable, Input, Segment, Space, Tag, Tooltip } from "@vaultinum/vaultinum-sdk";
import { round } from "lodash";
import { ReactNode, useMemo, useState } from "react";
import { includesWithCase } from "../../../../common/StringTools";
import SearchBar, { SearchBarValues } from "../../../../components/SearchBar";
import { SearchContext } from "../../../../contexts/SearchContext";
import SurveyIcons from "../components/SurveyIcons";
import SurveyNodeEditableCell from "../components/SurveyNodeEditableCell";
import "./SurveyRecommendationView.css";

type OptionTreeNode = {
    key: string;
    type: "why" | "fix";
    questionId: string;
    children?: OptionTreeNode[];
    tags?: string[];
    text?: ReactNode;
};

const QuestionScoreImpact = ({
    surveyVersion,
    scoreImpact,
    questionId,
    surveyLang
}: {
    surveyVersion: Survey.Version;
    scoreImpact: ScoreImpact;
    questionId: string;
    surveyLang: Survey.Lang;
}) => {
    const parentSection = query(surveyVersion).getSectionWithQuestion(questionId);
    return (
        <Tooltip
            placement="right"
            title={
                <>
                    <p className="underline">{(parentSection && surveyLang.sections[parentSection.id]?.title) || "Missing section title translation"}</p>
                    <p>{surveyLang.questions[questionId]?.text || "Missing question translation"}</p>
                </>
            }
        >
            <div>
                <Tag type="info" message={String(calculateTagScoresSummary(scoreImpact.questions[questionId], scoreImpact.tagWeights, 2))} />
            </div>
        </Tooltip>
    );
};

export default function SurveyRecommendationView({ surveyVersion, surveyLang }: { surveyVersion: Survey.Version; surveyLang: Survey.Lang }): JSX.Element {
    const [showWhy, setShowWhy] = useState(false);
    const [searchValues, setSearchValues] = useState<SearchBarValues>({ search: "" });
    const [selectedSection, setSelectedSection] = useState<string>("");
    const surveyTagScores = useMemo(() => calculateSurveyScoreImpact(surveyVersion), [surveyVersion]);

    const columns = [
        {
            title: "Score",
            width: 45,
            className: "",
            render: (_text: string, node: OptionTreeNode) => {
                if (node.type === "fix" && showWhy) {
                    return null;
                }
                return <QuestionScoreImpact surveyVersion={surveyVersion} scoreImpact={surveyTagScores} questionId={node.questionId} surveyLang={surveyLang} />;
            },
            sorter: (a: OptionTreeNode, b: OptionTreeNode) =>
                calculateTagScoresSummary(surveyTagScores.questions[a.questionId], surveyTagScores.tagWeights, 2) -
                calculateTagScoresSummary(surveyTagScores.questions[b.questionId], surveyTagScores.tagWeights, 2),
            defaultSortOrder: "descend" as const,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            sortDirections: ["descend", "ascend"] as any
        },
        {
            title: "Tags",
            width: surveyVersion.evaluationTags.length * 50,
            render: (text: string, node: OptionTreeNode) => {
                if (node.type === "fix") {
                    if (node.tags?.length === 0) {
                        return <Tag type="danger" message="No tags" />;
                    }
                    return (
                        <div className="flex space-x-1">
                            {surveyTagScores.questions[node.questionId]
                                .filter(tagScore => tagScore.score > 0)
                                .map(tagScore => (
                                    <Tag key={tagScore.tag} message={`${tagScore.tag}=${round(100 * tagScore.score * tagScore.ratio, 2)}`} />
                                ))}
                        </div>
                    );
                }
                return null;
            }
        },
        {
            title: "Text",
            render: (text: string, node: OptionTreeNode) => (
                <div style={{ display: "flex", width: "100%" }}>
                    {node.type === "why" && <SurveyIcons.OptionWhy />} {node.text}
                </div>
            )
        }
    ];

    const questions = query(surveyVersion)
        .getOrderedQuestions()
        .filter(question => {
            if (!selectedSection) {
                return true;
            }
            const section = surveyVersion.sections.find(section => section.id === selectedSection);
            return !section || query(section).hasQuestion(question.id);
        });
    const questionsRequiringRecommendations = questions.filter(question =>
        question.options.some(option =>
            option.evaluations.some(tagEval => tagEval.tag && [Question.Evaluation.High, Question.Evaluation.Critical].includes(tagEval.evaluation))
        )
    );
    const questionsWithRecommendations = questionsRequiringRecommendations.filter(question =>
        question.options.some(option => option.context?.why && option.context?.fixes && option.context?.fixes.length)
    );

    const optionsWithContext = questionsRequiringRecommendations
        .flatMap(question => question.options.map(option => ({ option, questionId: question.id })))
        .filter(item => item.option.context?.why || item.option.context?.fixes);

    let data: OptionTreeNode[] = [];
    if (showWhy) {
        data = optionsWithContext.map(({ option, questionId }) => {
            const target: Survey.NodeTarget = {
                property: Survey.NodeTargetProperty.WHY,
                nodeId: option.id,
                type: Survey.NodeType.OPTION
            };
            return {
                key: option.id,
                questionId,
                text: (
                    <SurveyNodeEditableCell
                        key={option.id}
                        nodeKey={option.id}
                        target={target}
                        nodeValue={getNodeTargetValue(target, surveyLang) || ""}
                        surveyLang={surveyLang}
                        onChange={(surveyLang, value) => updateSurveyLangOption(surveyLang, option.id, { why: value })}
                    />
                ),
                type: "why",
                children: option.context?.fixes
                    ?.filter(fix => {
                        if (searchValues.search) {
                            const text = surveyLang.options[option.id].fixes?.[fix.id];
                            return text && includesWithCase(text, searchValues.search);
                        }
                        return true;
                    })
                    .map(fix => {
                        const fixTarget: Survey.NodeTarget = {
                            property: Survey.NodeTargetProperty.FIXES,
                            nodeId: option.id,
                            type: Survey.NodeType.OPTION,
                            subNodeId: fix.id
                        };
                        return {
                            key: fix.id,
                            questionId,
                            tags: fix.tags,
                            text: (
                                <SurveyNodeEditableCell
                                    key={option.id}
                                    nodeKey={option.id}
                                    target={fixTarget}
                                    nodeValue={getNodeTargetValue(fixTarget, surveyLang) || ""}
                                    surveyLang={surveyLang}
                                    onChange={(surveyLang, value) => updateSurveyLangOptionFix(surveyLang, option.id, fix.id, value)}
                                />
                            ),
                            type: "fix"
                        };
                    })
            };
        });
    } else {
        data = optionsWithContext.flatMap(
            ({ option, questionId }) =>
                option.context?.fixes
                    ?.filter(fix => {
                        if (searchValues.search) {
                            const text = surveyLang.options[option.id].fixes?.[fix.id];
                            return text && includesWithCase(text, searchValues.search);
                        }
                        return true;
                    })
                    ?.map(fix => {
                        const fixTarget: Survey.NodeTarget = {
                            property: Survey.NodeTargetProperty.FIXES,
                            nodeId: option.id,
                            type: Survey.NodeType.OPTION,
                            subNodeId: fix.id
                        };
                        return {
                            key: fix.id,
                            questionId,
                            tags: fix.tags,
                            text: (
                                <SurveyNodeEditableCell
                                    key={option.id}
                                    nodeKey={option.id}
                                    target={fixTarget}
                                    nodeValue={getNodeTargetValue(fixTarget, surveyLang) || ""}
                                    surveyLang={surveyLang}
                                    onChange={(surveyLang, value) => updateSurveyLangOptionFix(surveyLang, option.id, fix.id, value)}
                                />
                            ),
                            type: "fix"
                        };
                    }) || []
        );
    }

    const sectionsOptions = [
        { label: "All", value: "" },
        ...surveyVersion.sections.map(section => ({
            label: surveyLang.sections[section.id]?.title || "Missing translation",
            value: section.id
        }))
    ];

    return (
        <div>
            <h2>
                Questions requiring recommendations: {questionsRequiringRecommendations.length}/{questions.length} (
                {Math.floor((100 * questionsRequiringRecommendations.length) / questions.length)}%) &nbsp;| Questions with recommendations:{" "}
                {questionsWithRecommendations.length}/{questionsRequiringRecommendations.length} (
                {Math.floor((100 * questionsWithRecommendations.length) / questionsRequiringRecommendations.length)}%)
            </h2>
            <h3>
                Why: {optionsWithContext.length} | Recommendations: {optionsWithContext.map(({ option }) => option.context?.fixes?.length || 0).reduce(sum, 0)}
            </h3>
            <SearchBar setSearchValues={setSearchValues} />
            <Space>
                <Input.Checkbox label="Show why" id="show-why" checked={showWhy} onChange={e => setShowWhy(e.target.checked)} />
                <Segment.Root onValueChange={newValue => setSelectedSection(newValue)} value={selectedSection}>
                    {sectionsOptions.map(sectionOption => (
                        <Segment.Item key={sectionOption.value} currentValue={sectionOption} selectedValue={selectedSection} />
                    ))}
                </Segment.Root>
            </Space>
            <SearchContext.Provider value={{ search: searchValues.search }}>
                <AntdTable
                    className="survey-recommendation-table"
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    rowClassName={(node: OptionTreeNode) => `node-${node.type}`}
                    expandable={{
                        defaultExpandAllRows: true
                    }}
                    size="small"
                />
            </SearchContext.Provider>
        </div>
    );
}
