import { USER_PROFILE_COLLECTION, UserProfile } from "@vaultinum/vaultinum-api";
import { collection, converter, doc, getFirestore, getItem, getItems } from "@vaultinum/vaultinum-sdk";

export function getUserProfile(uid: string | undefined, onUpdate: (userProfile: UserProfile | null) => void): () => void {
    if (!uid) {
        onUpdate(null);
        return () => {};
    }
    return getItem(doc(collection(getFirestore(), USER_PROFILE_COLLECTION), uid).withConverter(converter<UserProfile>()), onUpdate);
}

export function getUserProfiles(onUpdate: (userProfiles: UserProfile[]) => void): () => void {
    return getItems(collection(getFirestore(), USER_PROFILE_COLLECTION).withConverter(converter<UserProfile>()), onUpdate);
}
