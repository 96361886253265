import { loadStripe } from "@stripe/stripe-js";
import { App, CartProduct, PaymentOptions, PriceVariation, PriceVariationType } from "@vaultinum/vaultinum-api";
import Stripe from "stripe";
import { doGet, doPost, doPut } from "./apiService";

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PK || process.env.STORYBOOK_STRIPE_PK || "";
export const stripePromise = loadStripe(STRIPE_KEY);

export function createSetupIntent(accountId: string, paymentMethod: App.PaymentMethod.CARD | App.PaymentMethod.SEPA_DEBIT): Promise<Stripe.SetupIntent> {
    return doPost("payment/setupIntent", { accountId, paymentMethod });
}

export function pay(
    accountId: string,
    paymentMethodId: string,
    plans: App.PricingPlans[],
    promoCode?: string,
    paymentOptions?: PaymentOptions
): Promise<Stripe.Invoice | Stripe.Subscription> {
    return doPost("payment/pay", { accountId, paymentMethodId, plans, promoCode, options: paymentOptions });
}

export function getPrice(plan: App.PricingPlans): Promise<Stripe.Price> {
    return doGet(`payment/price/${plan}`);
}

export function getPrices(app: string): Promise<Stripe.Price[]> {
    return doGet(`payment/prices/${app}`);
}

export function getPricingPlans(app: string): Promise<App.PricingPlans[]> {
    return doGet(`payment/plans/${app}`);
}

export function getTaxRate(accountId: string): Promise<Stripe.TaxRate> {
    return doGet(`payment/taxRate/${accountId}`);
}

export function updateSubscriptionMetadata(subscriptionId: string, accountId: string, metadata: Record<string, string>): Promise<Stripe.Subscription> {
    return doPut(`payment/subscription/${subscriptionId}`, { accountId, metadata });
}

export function getPromoCode(promoCode: string): Promise<Stripe.PromotionCode> {
    return doGet(`payment/promotionCode/${promoCode}`);
}

export function getProductFromPrice(price: Stripe.Price): CartProduct {
    return {
        name: price.metadata.plan as App.PricingPlans,
        recurring: price.recurring?.interval,
        amount: (price.unit_amount || 0) / 100
    };
}

export function getDiscountFromPromotionCode(promoCode: Stripe.PromotionCode): ({ code?: string } & PriceVariation) | undefined {
    if (promoCode.coupon.amount_off) {
        return {
            code: promoCode.code,
            type: PriceVariationType.Amount,
            value: promoCode.coupon.amount_off
        };
    }
    if (promoCode.coupon.percent_off) {
        return {
            code: promoCode.code,
            type: PriceVariationType.Percent,
            value: promoCode.coupon.percent_off
        };
    }
    return undefined;
}

export function paymentHasSucceeded(paymentStatus: Stripe.Invoice.Status | Stripe.Subscription.Status | null): boolean {
    return paymentStatus !== null && ["paid", "active"].includes(paymentStatus);
}
