import { FiltersService, FiltersSortService, SortDirection } from "../services";

export class FilterController {
    private urlFilterService: FiltersService | undefined;

    private localStorageFilterSortService: FiltersSortService | undefined;

    constructor(services: { urlFilterService?: FiltersService; localStorageFilterSortService?: FiltersSortService }) {
        this.localStorageFilterSortService = services.localStorageFilterSortService;
        this.urlFilterService = services.urlFilterService;
    }

    public saveFilter(filterKey: string, filters?: unknown[]): void {
        if (this.urlFilterService) {
            this.urlFilterService.saveFilter(filterKey, filters);
        }
        if (this.localStorageFilterSortService) {
            this.localStorageFilterSortService.saveFilter(filterKey, filters);
        }
    }

    public loadFilters(): Record<string, unknown[]> {
        // If filters are present in the URL, we don't need to load them from local storage
        const filtersFromUrl = this.urlFilterService?.loadFilters() ?? {};
        if (Object.keys(filtersFromUrl).length) {
            // As the url Filters are stored as strings, we need to convert them back to null if they are "null"
            return Object.keys(filtersFromUrl).reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: filtersFromUrl[key].map(filter => (filter === "null" ? null : filter))
                }),
                {}
            );
        }

        if (this.localStorageFilterSortService) {
            const filtersFromLocalStorage = this.localStorageFilterSortService.loadFilters();
            if (Object.keys(filtersFromLocalStorage).length && this.urlFilterService) {
                // If filters are present in local storage but not in the URL, they should be saved to the URL.
                for (const [key, value] of Object.entries(filtersFromLocalStorage)) {
                    this.urlFilterService.saveFilter(key, value);
                }
            }
            return filtersFromLocalStorage;
        }

        return {};
    }

    public loadFilter(filterKey: string): unknown[] {
        return this.loadFilters()[filterKey] ?? [];
    }

    public saveSort(sortKey: string, direction: SortDirection | false): void {
        if (this.localStorageFilterSortService) {
            this.localStorageFilterSortService.saveSort(sortKey, direction);
        }
    }

    public loadSort(sortKey: string): SortDirection | false {
        if (!this.localStorageFilterSortService) {
            return false;
        }
        return this.localStorageFilterSortService.loadSort(sortKey);
    }

    public loadSorts(): Record<string, SortDirection | false> {
        if (!this.localStorageFilterSortService) {
            return {};
        }
        return this.localStorageFilterSortService.loadSorts();
    }
}
