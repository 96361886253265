import { uniq, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Chart } from "react-google-charts";
import { useLang } from "../../../../lang";
import { COLOR_SCHEME } from "../../../referentials";
import { Buttons } from "../../Button";
import { CommonChartParams } from "../config";
import { NoData } from "../shared/NoData";

type SankeyChartData = {
    from: string;
    to: string;
    weight: number;
    color?: string;
};

export type SankeyChartParams = Pick<CommonChartParams, "width" | "height"> & {
    dataset: SankeyChartData[];
    nodeColors?: Record<string, string>;
    sections?: Record<string, string[]>;
    hasBgColor?: boolean;
    showFilters?: boolean;
};

export function SankeyChart({
    dataset,
    nodeColors,
    sections,
    width = "100%",
    height = "100%",
    hasBgColor = true,
    showFilters = true
}: SankeyChartParams): JSX.Element {
    const lang = useLang();
    const definedSections = useMemo(
        () =>
            sections ?? {
                "": uniqBy([...uniqBy(dataset, d => d.from).map(({ from }) => from), ...uniqBy(dataset, d => d.to).map(({ to }) => to)], d => d)
            },
        [sections, dataset]
    );
    const [filteredSections, setFilteredSections] = useState(definedSections);
    const filteredDataset = useMemo(() => {
        const filteredValues = Object.values(filteredSections).flat();
        return filteredValues.length ? dataset.filter(({ from, to }) => filteredValues.includes(from) && filteredValues.includes(to)) : dataset;
    }, [dataset, filteredSections]);

    // Make sure to reset the filters sections when the inputed sections or dataset changes on the fly
    useEffect(() => setFilteredSections(definedSections), [definedSections]);

    const optionsCount = Object.values(definedSections).flatMap(values => values).length;
    const selectedOptionsCount = Object.values(filteredSections).flatMap(values => values).length;
    const hasFilters = 0 < selectedOptionsCount && selectedOptionsCount < optionsCount;

    const nodes = uniq(dataset.flatMap(data => [data.from, data.to]));
    const colors = nodes.map(node => nodeColors?.[node] ?? COLOR_SCHEME.blue.primary);
    return (
        <div className="flex flex-col gap-2 items-end" style={{ width, height }}>
            {showFilters && optionsCount > 0 && (
                <div>
                    {/* Extra div is needed to keep the bottom border on full size */}
                    <Buttons.Filters
                        label={lang.shared.filterBy(Object.keys(sections ?? {}).join(" / "))}
                        filters={Object.entries(definedSections).map(([name, values]) => ({
                            key: "sections",
                            label: name,
                            defaultOptions: filteredSections[name],
                            options: values.map(label => ({ value: label, label })),
                            onSubmit: (selectedValues: string[]) => setFilteredSections(prev => ({ ...prev, [name]: selectedValues }))
                        }))}
                    />
                </div>
            )}
            {filteredDataset.length ? (
                <Chart
                    chartType="Sankey"
                    width="100%"
                    height="100%"
                    options={{
                        sankey: { node: { colors, label: { fontSize: 18 } } },
                        ...(!hasBgColor && { backgroundColor: "transparent" })
                    }}
                    data={[
                        ["from", "to", "weight", { type: "string", role: "style" }],
                        ...filteredDataset.map(data => [data.from, data.to, data.weight, `color: ${data.color ?? COLOR_SCHEME.grey.light}`])
                    ]}
                />
            ) : (
                <NoData hasFilters={hasFilters} width={width} height={height} />
            )}
        </div>
    );
}
