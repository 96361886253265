import classNames from "classnames";
import { Button, ButtonProps, DropdownActionProps } from "../..";
import { BaseCardContainerInterface } from "../BaseCardContainer";
import { Dropdowns } from "../Dropdown/presets/Dropdowns";

export namespace CardCommon {
    export type WithActions = {
        actions: DropdownActionProps[];
    };

    export function Actions({ actions, "data-id": dataId }: WithActions & Pick<BaseCardContainerInterface, "data-id">): JSX.Element | undefined {
        if (!actions.length) {
            return undefined;
        }
        return <Dropdowns.Options data-id={`${dataId}-actions`} actions={actions} isLoading={false} />;
    }

    export type WithRadio<T extends string = string> = {
        radio: {
            id: T;
            selectedId?: T;
            onValueChange?: (selectedId: T) => void;
            label?: string;
        };
    };

    export function Radio<T extends string>({
        radio: { id, selectedId, onValueChange, label },
        "data-id": dataId,
        isDisabled
    }: WithRadio<T> & Pick<BaseCardContainerInterface, "data-id" | "isDisabled">): JSX.Element {
        return (
            <input
                {...(dataId && { "data-id": `${dataId}-radio` })}
                id={id}
                type="radio"
                title={label}
                checked={id === selectedId}
                onClick={() => (id !== selectedId ? onValueChange?.(id) : null)}
                disabled={isDisabled}
                readOnly
                className="accent-primary"
            />
        );
    }

    export type WithCheckbox<T extends string = string> = {
        checkbox: {
            id: T;
            isSelected: boolean;
            onValueChange: (id: T) => void;
            label?: string;
        };
    };

    export function Checkbox<T extends string>({
        checkbox: { id, isSelected, label },
        "data-id": dataId,
        isDisabled
    }: WithCheckbox<T> & Pick<BaseCardContainerInterface, "data-id" | "isDisabled">): JSX.Element {
        return (
            <input
                {...(dataId && { "data-id": `${dataId}-checkbox` })}
                id={id}
                type="checkbox"
                title={label}
                checked={isSelected}
                disabled={isDisabled}
                readOnly
                className="accent-primary"
            />
        );
    }

    export type WithButtons = {
        buttons: ButtonProps[];
    };

    export function Buttons({ buttons, "data-id": dataId }: WithButtons & Pick<BaseCardContainerInterface, "data-id">): JSX.Element {
        if (!buttons.length) {
            return <></>;
        }
        return (
            <div className="flex flex-col gap-2">
                {buttons.map(({ "data-id": buttonDataId, ...props }, i) => (
                    <Button key={buttonDataId ?? i} data-id={dataId ? `${dataId}-buttons-${buttonDataId}` : buttonDataId} {...props} />
                ))}
            </div>
        );
    }

    export type WithImage = {
        url: string;
        isCover?: boolean;
        hoveredContent?: JSX.Element;
        isLoading?: boolean;
    };

    export function Image({ url, isCover, hoveredContent }: WithImage): JSX.Element {
        return (
            <div
                className={classNames("group w-28 min-w-28 bg-top bg-no-repeat md:min-h-36 md:w-64 md:min-w-64", isCover ? "bg-cover" : "bg-contain")}
                style={{ backgroundImage: `url("${url}")` }}
            >
                {hoveredContent && (
                    <div className="flex h-full w-full items-center justify-center bg-black bg-opacity-60 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                        {hoveredContent}
                    </div>
                )}
            </div>
        );
    }

    export type WithTitle = {
        text?: string;
        position?: "center" | "left";
    };

    export function Title({ text, position, "data-id": dataId }: WithTitle & Pick<BaseCardContainerInterface, "data-id">): JSX.Element {
        return (
            <div
                data-id={dataId ? `${dataId}-title` : "title"}
                className={classNames("truncate text-lg font-semibold", { "text-center": position === "center" })}
                title={text}
                children={text}
            />
        );
    }
}
