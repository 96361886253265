import { Survey } from "@vaultinum/vaultinum-api";
import { Button, COLOR_SCHEME, PlayCircleIcon, Progress, Tag, WarningIcon } from "@vaultinum/vaultinum-sdk";
import { useMemo } from "react";
import { PRIVATE_ROOT_PAGE } from "../../../../../services/routing.service";
import { getSurveyCharacterCount, getSurveyVersionIssues } from "../HealthCheckTools";
import { KeyboardShortcutsModalButton } from "./KeyboardShortcutsModalButton";

const DOCUMENT_SIZE_LIMIT = 1_048_576;

function getSizeColor(percentage: number): string {
    if (percentage < 60) {
        return COLOR_SCHEME.green.primary;
    }
    if (percentage < 80) {
        return COLOR_SCHEME.orange.primary;
    }
    if (percentage < 90) {
        return COLOR_SCHEME.orange.dark;
    }
    if (percentage < 95) {
        return COLOR_SCHEME.red.primary;
    }
    return COLOR_SCHEME.red.dark;
}

export function HealthCheckInfoBar({ surveyVersion, surveyLang }: { surveyVersion: Survey.Version; surveyLang: Survey.Lang }) {
    const surveySize = useMemo(() => JSON.stringify(surveyLang).length, [surveyLang]);
    const healthCheckIssues = useMemo(() => getSurveyVersionIssues(surveyVersion, surveyLang), [surveyVersion, surveyLang]);
    const surveyCharacterCount = useMemo(() => getSurveyCharacterCount(surveyLang), [surveyLang]);
    const fillPercentage = (surveySize / DOCUMENT_SIZE_LIMIT) * 100;
    return (
        <div className="flex justify-between">
            <div className="flex flex-row items-center gap-3">
                <span>🩺 Health check</span>
                <span className="mx-2 text-grey-light">|</span>
                <span>Size: </span>
                <Progress
                    percent={Math.round(fillPercentage * 10) / 10}
                    size="small"
                    className="w-40"
                    status={fillPercentage >= 100 ? "exception" : "normal"}
                    strokeColor={getSizeColor(fillPercentage)}
                />
                {fillPercentage > 95 && (
                    <span className="text-xs">
                        ({surveySize.toLocaleString()} / {DOCUMENT_SIZE_LIMIT.toLocaleString()})
                    </span>
                )}
                <span className="mx-2 text-grey-light">|</span>
                <span>Characters: {surveyCharacterCount.toLocaleString()}</span>
                {healthCheckIssues.length > 0 && (
                    <>
                        <span className="mx-2 text-grey-light">|</span>
                        <Tag type="warning" message={`${healthCheckIssues.length} issues detected`} icon={WarningIcon} />
                    </>
                )}
                <Button
                    isLoading={false}
                    icon={PlayCircleIcon}
                    children="Test mode"
                    onClick={() =>
                        window.open(
                            `${process.env.REACT_APP_VAULTINUM_HOME}${PRIVATE_ROOT_PAGE}/kys/survey/preview?surveyKey=${surveyVersion.surveyKey}&version=${surveyVersion.version}&lang=${surveyLang.lang}`,
                            "_blank"
                        )
                    }
                />
            </div>
            <KeyboardShortcutsModalButton />
        </div>
    );
}
