import { IconSvgProps } from "../Icons";

export default function ExpandSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg fill={color} width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.0315 1.96876C21.862 1.79904 21.661 1.71429 21.4289 1.71429H15.4288C15.1967 1.71429 14.9959 1.79914 14.8261 1.96876C14.6567 2.13847 14.5718 2.33928 14.5718 2.5714C14.5718 2.80351 14.6566 3.00456 14.8261 3.17404L16.7545 5.10261L12.3081 9.54909C12.2188 9.63857 12.1741 9.74109 12.1741 9.85714C12.1741 9.9732 12.2188 10.0759 12.3081 10.1652L13.8349 11.692C13.9241 11.7813 14.0267 11.8259 14.1429 11.8259C14.259 11.8259 14.3616 11.7813 14.4508 11.692L18.8975 7.24555L20.8257 9.17397C20.9955 9.34368 21.1966 9.42859 21.4287 9.42859C21.6608 9.42859 21.8617 9.34356 22.0313 9.17397C22.2008 9.00442 22.2856 8.80344 22.2856 8.57133V2.57122C22.2858 2.33896 22.2015 2.1384 22.0315 1.96876ZM10.1655 12.3078C10.0762 12.2185 9.97345 12.1739 9.8574 12.1739C9.74134 12.1739 9.6386 12.2185 9.54934 12.3078L5.10286 16.7543L3.17428 14.8256C3.00464 14.6562 2.80392 14.5712 2.57164 14.5712C2.33937 14.5712 2.13849 14.6563 1.96884 14.8256C1.7992 14.9953 1.71437 15.1964 1.71437 15.4285V21.4286C1.71437 21.6607 1.79919 21.8616 1.96884 22.0312C2.13866 22.2008 2.33953 22.2857 2.57164 22.2857H8.57173C8.80384 22.2857 9.00473 22.2007 9.17437 22.0312C9.34402 21.8615 9.42884 21.6607 9.42884 21.4286C9.42884 21.1965 9.34402 20.9954 9.17437 20.8256L7.24579 18.8974L11.6923 14.4507C11.7815 14.3614 11.8261 14.2589 11.8261 14.1427C11.8261 14.0266 11.7814 13.9241 11.6923 13.8346L10.1655 12.3078Z"
                fill={color}
            />
        </svg>
    );
}
