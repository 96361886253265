import { IconSvgProps } from "../Icons";

function FileSvg({ color, size }: IconSvgProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.1767 6.01607L15.4125 0.251786C15.2517 0.0910715 15.0348 0 14.8071 0H3.42852C2.95442 0 2.57138 0.383036 2.57138 0.857143V23.1429C2.57138 23.617 2.95442 24 3.42852 24H20.5714C21.0455 24 21.4285 23.617 21.4285 23.1429V6.62411C21.4285 6.39643 21.3375 6.17679 21.1767 6.01607ZM19.4517 7.01786H14.4107V1.97679L19.4517 7.01786ZM19.5 22.0714H4.49995V1.92857H12.5892V7.71429C12.5892 8.01265 12.7078 8.2988 12.9187 8.50978C13.1297 8.72076 13.4159 8.83929 13.7142 8.83929H19.5V22.0714Z"
                fill={color}
            />
        </svg>
    );
}

export default FileSvg;
