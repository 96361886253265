import { Note } from "@vaultinum/vaultinum-api";
import { ReactNode, useState } from "react";
import * as yup from "yup";
import { Form, openNotificationWithIcon, useForm, useLang, useRequiredString } from "../../../../common";
import NoteText from "./NoteText";

export default function NoteForm({
    isReviewer,
    notes,
    header,
    accountName,
    onSave,
    onDelete
}: {
    isReviewer?: boolean;
    notes?: (Note & { userName: string })[];
    header?: ReactNode;
    accountName?: string;
    onSave: (text: string, visibility: Note.Visibility, noteId?: string) => void | Promise<void>;
    onDelete: (noteId: string) => Promise<void>;
}) {
    const lang = useLang();

    const [working, setWorking] = useState(false);

    const clientNote = notes?.find(note => note.visibility === Note.Visibility.PUBLIC);
    const staffNote = notes?.find(note => note.visibility === Note.Visibility.REVIEWERS);
    const editableNote = isReviewer ? staffNote : clientNote;
    const readOnlyNote = isReviewer ? clientNote : staffNote;
    const noteVisibility = isReviewer ? editableNote?.visibility || Note.Visibility.REVIEWERS : Note.Visibility.PUBLIC;

    const schema = yup.object({
        noteText: useRequiredString()
    });
    const form = useForm({
        schema,
        defaultValues: { noteText: editableNote?.text || "" }
    });

    const onSubmit = async ({ noteText }: { noteText: string }) => {
        try {
            setWorking(true);
            await onSave(noteText, noteVisibility, editableNote?.id);
            openNotificationWithIcon({ type: "success", description: lang.shared.saveSuccessMessage });
        } catch {
            openNotificationWithIcon({ type: "error", description: lang.shared.saveErrorMessage });
        } finally {
            setWorking(false);
        }
    };

    const handleDelete = async () => {
        if (editableNote?.id) {
            try {
                setWorking(true);
                await onDelete(editableNote.id);
                openNotificationWithIcon({ type: "success", description: "Note deleted successfully!" });
                form.setValue("noteText", "");
            } catch {
                openNotificationWithIcon({ type: "error", description: "Failed to delete note." });
            } finally {
                setWorking(false);
            }
        }
    };

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            {readOnlyNote && (
                <NoteText
                    working={working}
                    note={readOnlyNote}
                    header={header}
                    visibleBy={[
                        ...(isReviewer ? [lang.accountInformation.notes.reviewers] : []),
                        ...(accountName ? [lang.accountInformation.notes.membersOf(accountName)] : [])
                    ].join(" & ")}
                />
            )}
            <NoteText
                note={editableNote}
                header={!readOnlyNote ? header : undefined}
                editable
                visibleBy={isReviewer ? lang.accountInformation.notes.reviewers : accountName}
                working={working}
                form={form}
                onSubmit={() => onSubmit({ noteText: form.watch().noteText })}
                handleDelete={handleDelete}
            />
        </Form>
    );
}
