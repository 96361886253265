import { StaffUser, StaffUserRole, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import {
    BaseLang,
    CheckIcon,
    Column,
    ConnectIcon,
    CrossIcon,
    DeleteIcon,
    DisconnectedIcon,
    ModalButton,
    getAccountsByDomainId,
    openNotificationWithIcon,
    removeWhiteLabelDomain,
    useLang
} from "@vaultinum/vaultinum-sdk";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AccountLink from "../../../components/AccountLink";
import { getDomains } from "../../../services/domainService";
import { getAccountsLinkByDomain } from "../../../services/routing.service";
import { updateWhiteLabelDomain } from "../../../services/whiteLabelService";
import { DomainLogo } from "../components/DomainLogo";
import { StandardModelListView } from "../components/StandardModelListView";
import DomainModalWithButton from "./DomainModalWithButton";

function DomainOrganisations({ whiteLabelDomain }: { whiteLabelDomain: WhiteLabelDomain }): JSX.Element {
    const [organisationsCount, setOrganisationsCount] = useState<number>();
    useEffect(() => {
        getAccountsByDomainId(whiteLabelDomain.id, labelledAccounts => setOrganisationsCount(labelledAccounts?.length || 0));
    }, [whiteLabelDomain]);

    if (organisationsCount === undefined) {
        return <span className="text-grey-light">-</span>;
    }
    return <Link to={getAccountsLinkByDomain(whiteLabelDomain.id)} className="cursor-pointer text-accent hover:underline" children={organisationsCount} />;
}

const domainsColumns: Column<WhiteLabelDomain>[] = [
    {
        header: "Name",
        accessorKey: "name",
        cell: cell => {
            return cell.getValue() || "-";
        }
    },
    {
        header: "Fully Qualified Domain Name",
        accessorKey: "fqdn",
        cell: cell => {
            const fqdn = cell.getValue<string>();
            return (
                <a href={`https://${fqdn}`} target="_blank" rel="noreferrer" className="cursor-pointer text-accent hover:underline">
                    {fqdn}
                </a>
            );
        },
        size: 250
    },
    {
        header: "Logo",
        enableColumnFilter: false,
        enableSorting: false,
        accessorFn: row => row,
        cell: cell => {
            return <DomainLogo whiteLabelDomain={cell.getValue<WhiteLabelDomain>()} />;
        },
        size: 110
    },
    {
        header: "Preferred language",
        accessorKey: "preferredLanguage",
        cell: cell => {
            return cell.getValue() || "-";
        }
    },
    {
        header: "Access",
        accessorKey: "accessMode",
        size: 90
    },
    {
        header: "Apps",
        accessorFn: row => row.configuration && Object.keys(row.configuration),
        cell: cell => {
            const configuration = cell.getValue<string[]>();
            return configuration ? configuration.join(", ") : "-";
        }
    },
    {
        header: "Owner organisation name",
        accessorKey: "ownerAccountId",
        cell: cell => {
            const ownerAccountId = cell.getValue<string>();
            return ownerAccountId ? <AccountLink accountId={ownerAccountId} /> : "-";
        }
    },
    {
        header: "Organisations registered",
        accessorFn: row => row,
        enableColumnFilter: false,
        cell: cell => <DomainOrganisations whiteLabelDomain={cell.getValue<WhiteLabelDomain>()} />
    },
    {
        header: "Active",
        accessorKey: "isActive",
        cell: cell => {
            if (cell.getValue<boolean>()) {
                return <CheckIcon color="green" />;
            }
            return <CrossIcon color="red" />;
        },
        defaultFilteredValues: [true],
        size: 90
    }
];

export default function DomainsPage({ staffUser }: { staffUser: StaffUser }): JSX.Element {
    const lang = useLang<BaseLang>();
    const [isLoading, setIsLoading] = useState(false);

    async function deleteDomain(domain: WhiteLabelDomain) {
        setIsLoading(true);
        try {
            await removeWhiteLabelDomain(domain.id);
            openNotificationWithIcon({ type: "success", description: "Domain deleted successfully" });
        } catch {
            openNotificationWithIcon({ type: "error", description: "An error occured while deleting the domain" });
        } finally {
            setIsLoading(false);
        }
    }

    async function toggleDomain(domain: WhiteLabelDomain, isActive: boolean): Promise<void> {
        setIsLoading(true);
        try {
            await updateWhiteLabelDomain(domain.id, { isActive });
            openNotificationWithIcon({ type: "success", description: `Domain ${isActive ? "activated" : "deactivated"} successfully` });
        } catch {
            openNotificationWithIcon({ type: "error", description: `An error occured while ${isActive ? "activating" : "deactivating"} the domain` });
        } finally {
            setIsLoading(false);
        }
    }

    const editDomain = useCallback(
        (domain: WhiteLabelDomain) => {
            if (staffUser.roles.includes(StaffUserRole.ADMIN)) {
                return (
                    <>
                        <DomainModalWithButton domain={domain} staffUser={staffUser} />
                        {domain.isActive && (
                            <ModalButton.Confirm
                                title="Domain deactivation"
                                icon={DisconnectedIcon}
                                children="Are you sure you want to deactivate this domain?"
                                onConfirm={() => toggleDomain(domain, false)}
                                buttonProps={{
                                    type: "default",
                                    title: "Deactivate domain",
                                    isLoading: false,
                                    isDisabled: isLoading,
                                    icon: DisconnectedIcon
                                }}
                                lang={lang}
                            />
                        )}
                        {!domain.isActive && (
                            <ModalButton.Confirm
                                title="Domain activation"
                                icon={ConnectIcon}
                                children="Are you sure you want to activate this domain?"
                                onConfirm={() => toggleDomain(domain, true)}
                                buttonProps={{
                                    type: "default",
                                    title: "Activate domain",
                                    isLoading: false,
                                    isDisabled: isLoading,
                                    icon: ConnectIcon
                                }}
                                lang={lang}
                            />
                        )}
                        <ModalButton.Confirm
                            title="Domain deletion"
                            icon={DeleteIcon}
                            children="Are you sure you want to delete this domain?"
                            onConfirm={() => deleteDomain(domain)}
                            buttonProps={{
                                type: "default",
                                title: "Delete domain",
                                isLoading: false,
                                isDisabled: isLoading,
                                icon: DeleteIcon
                            }}
                            lang={lang}
                        />
                    </>
                );
            }
            return undefined;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isLoading, staffUser]
    );

    return (
        <>
            <div className="flex">
                <DomainModalWithButton staffUser={staffUser} />
            </div>
            <StandardModelListView<WhiteLabelDomain>
                name="Domains"
                getModelItems={getDomains}
                modelPageLink={(domain: WhiteLabelDomain) => getAccountsLinkByDomain(domain.id)}
                columns={domainsColumns}
                viewButtonLabel="View organisations"
                searchOptions={{ hideIgnored: true }}
                extraActions={editDomain}
                actionColumnSize={380}
                storageKey="table-domains"
            />
        </>
    );
}
