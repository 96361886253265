/* eslint-disable no-param-reassign */
import {
    ColumnFilter,
    ExpandedState,
    PaginationState,
    Row,
    RowSelectionState,
    SortingState,
    Table as TanstackTable,
    Updater,
    VisibilityState,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import { Virtualizer, useVirtualizer } from "@tanstack/react-virtual";
import { isDefined } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { differenceBy, isEqual, partition, sum } from "lodash";
import { Dispatch, Ref, SetStateAction, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { FilterController } from "../../../controllers";
import { useLang } from "../../../lang";
import { LocalStorageFilterSortService, SortDirection } from "../../../services";
import { Color } from "../../referentials";
import { Buttons } from "../Button";
import { ChevronDownDoubleIcon, ChevronRightDoubleIcon } from "../Icons";
import Pagination from "../Pagination/Pagination";
import { Skeletons } from "../Skeleton";
import { Spin } from "../Spin";
import { Body, VirtualizedBody } from "./Body";
import { ExpandRowButton } from "./ExpandRowButton";
import { Head } from "./Head";
import { SelectAllHeader, SelectRowCheckbox } from "./SelectRowCheckbox";
import { Column, EMPTY_VALUES, EmptyFilter, TableRow, isExpandableRow, parseColumnId } from "./TableTools";

const DEFAULT_COLUMN_SIZE = 200;
const TABLE_CLIENT_WIDTH_OVERLAP = 13;

function isRecord(value: unknown): value is Record<string, unknown> {
    return value !== null && typeof value === "object" && !!Object.keys(value).length;
}

declare module "@tanstack/table-core" {
    interface FilterFns {
        multiSelectFilter: (row: Row<unknown>, columnId: string, filterValue: unknown, columns: Column[]) => boolean; // custom filter
    }
}

function multiSelectFilter<T extends object>(row: Row<T>, columnId: string, value: unknown[], columns: Column<T>[]): boolean {
    const filteredValue = row.getValue(columnId);
    const col = columns.find(column => [column.accessorKey, column.id, column.header].includes(columnId));
    return (
        !!value?.length &&
        !!value.filter(val => {
            // Filter applied on empty values
            if (EMPTY_VALUES.includes(val as EmptyFilter)) {
                return EMPTY_VALUES.includes(filteredValue as EmptyFilter);
            }
            if (filteredValue === val) {
                return true;
            }
            // Special case where a cell value is typed as { [key: string]: boolean | string }
            if (isRecord(filteredValue) && !!filteredValue[val as string]) {
                return true;
            }
            if (Array.isArray(filteredValue)) {
                return filteredValue.includes(val);
            }
            if (col?.filters?.length) {
                return col.filters.every(filter => [true, val].includes(filter.onFilter(row.original)));
            }
            return false;
        }).length
    );
}

function ExpandHeader({ areRowsExpanded, onClick }: { areRowsExpanded: boolean; onClick: () => void }): JSX.Element {
    return (
        <div className="flex items-center justify-center" onClick={onClick}>
            <Buttons.Icon type="default" fill="link" isLoading={false} icon={areRowsExpanded ? ChevronDownDoubleIcon : ChevronRightDoubleIcon} color="slate" />
        </div>
    );
}

export enum SelectionMode {
    ROW = "row",
    CHECKBOX = "checkbox"
}

export type TableProps<T extends object, U = unknown, V = unknown> = {
    data: TableRow<T>[];
    columns: Column<T>[];
    searchText?: string;
    minSearchLength?: number;
    isVirtualized?: boolean;
    storageKey?: string;
    expandedRows?: Record<string, boolean>;
    selectedRows?: string[];
    setSelectedRows?: ((keys: string[]) => Promise<void>) | Dispatch<SetStateAction<string[]>>;
    onFilter?: (count: number, totalCount?: number) => void;
    onLoadChildren?: (row: T) => Promise<T[]>;
    itemKey?: keyof T;
    color?: Color;
    selectionMode?: SelectionMode | null;
    disableSelectAll?: boolean;
    pageSize?: number;
    onPageChanged?: (newPageIndex: number) => void;
    pageCount?: number;
    onLazyLoad?: (options: {
        pagination?: { page: number; pageSize: number };
        sort?: [string, SortDirection] | null;
        filters?: { key: U; values: unknown[] }[];
        searchText?: string;
        totalCount?: number;
    }) => Promise<{ data: TableRow<T>[]; totalResults: number; totalCount?: number } | undefined>;
    onFiltersLazyLoad?: (columnKey: V) => Promise<unknown[]>;
};

function expandRows<T>(rows: Row<T>[]): ExpandedState {
    const result: ExpandedState = {};
    for (const row of rows) {
        result[row.id] = true;
    }
    return result;
}

function formatSelection(keys: string[]): Record<string, boolean> {
    return keys.reduce(
        (acc, key) => {
            acc[key] = true;
            return acc;
        },
        {} as Record<string, boolean>
    );
}

function RowsViewer<T extends object>({
    rows,
    table,
    rowVirtualizer,
    isVirtualized
}: {
    rows: Row<T>[];
    table: TanstackTable<T>;
    rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
    isVirtualized?: boolean;
}): JSX.Element {
    const lang = useLang();
    if (!rows.length) {
        return <div className="text-grey flex h-28 items-center justify-center" children={lang.shared.noDataAvailable} />;
    }
    if (isVirtualized) {
        return <VirtualizedBody rows={rows} table={table} rowVirtualizer={rowVirtualizer} />;
    }
    return <Body rows={rows} table={table} />;
}

function TableComponent<T extends object, U = unknown, V = unknown>(
    {
        data,
        columns,
        searchText,
        expandedRows,
        selectedRows,
        isVirtualized,
        storageKey,
        setSelectedRows,
        onFilter,
        onLoadChildren,
        isTree,
        itemKey,
        color,
        selectionMode,
        disableSelectAll,
        pageSize,
        pageCount,
        onPageChanged,
        minSearchLength = 0,
        onLazyLoad,
        onFiltersLazyLoad
    }: TableProps<T, U, V> & { isTree?: boolean },
    ref?: Ref<{ getInternalLazyInfo: () => unknown } | undefined>
): JSX.Element {
    const [tableData, setTableData] = useState<TableRow<T>[]>(data);
    const [columnSize, setColumnSize] = useState(0);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState<ExpandedState>(expandedRows ?? {});
    const [rowSelection, setRowSelection] = useState<RowSelectionState>(formatSelection(selectedRows ?? []));
    const [pagination, setPagination] = useState<PaginationState | undefined>(pageSize ? { pageIndex: 0, pageSize } : undefined);

    const defaultSort = columns
        .filter(column => !!column.defaultSort)
        .map(column => ({ id: column.id || column.accessorKey || column.header?.toString() || "", desc: column.defaultSort === SortDirection.DESCENDING }));

    const lastFetchedInfo = useRef<{
        filters: { key: unknown; values: unknown[] }[];
        searchText: string | undefined;
        sorting: SortingState | undefined;
        pagination: { pageSize?: number; pageIndex?: number } | undefined;
    }>();
    const [rowCount, setRowCount] = useState(0);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [queryRowCount, setQueryRowCount] = useState(0);

    const filterController = storageKey ? new FilterController({ localStorageFilterSortService: new LocalStorageFilterSortService(storageKey) }) : undefined;

    const onSelect = (valueFn: Updater<RowSelectionState>) => {
        if (typeof valueFn === "function") {
            const updatedRowSelection = valueFn(rowSelection);
            setRowSelection(updatedRowSelection);
            void setSelectedRows?.(
                Object.entries(updatedRowSelection)
                    .filter(([, value]) => !!value)
                    .map(([key]) => key)
            );
        }
    };

    useImperativeHandle(ref, () => ({
        getInternalLazyInfo: () => lastFetchedInfo.current
    }));

    // Keep the row count in sync with the queryRowCount
    useEffect(() => {
        setRowCount(queryRowCount);
    }, [queryRowCount]);

    useEffect(() => {
        if (selectedRows) {
            setRowSelection(formatSelection(selectedRows));
        }
    }, [selectedRows]);

    useEffect(() => {
        if (expandedRows) {
            setExpanded(expandedRows);
        }
    }, [expandedRows]);

    // useEffect needed as it seems that the columnSize is not set when the table is rendered the first time
    useEffect(() => {
        const defaultFilters = columns
            .filter(column => (Array.isArray(column.defaultFilteredValues) ? !!column.defaultFilteredValues?.length : !!column.defaultFilteredValues))
            .map(column => ({
                id: column.id || column.accessorKey || column.header?.toString() || "",
                value: Array.isArray(column.defaultFilteredValues) ? column.defaultFilteredValues : [column.defaultFilteredValues]
            }));

        if (!filterController) {
            setSorting(defaultSort);
            setColumnFilters([...(columnFilters ?? []), ...defaultFilters]);
            setIsReady(true);
            return;
        }

        const storedSorts = filterController.loadSorts();
        const existingSorts = Object.entries(storedSorts)
            .map(([key, value]) => (value ? { id: key, desc: storedSorts[key] === SortDirection.DESCENDING } : null))
            .filter(isDefined);
        setSorting(existingSorts.length ? existingSorts : defaultSort);
        const storedFilters = filterController.loadFilters();
        const existingFilters = Object.entries(storedFilters).map(([key, value]) => ({
            id: key,
            value: value.map(val => (val === null ? undefined : val))
        }));
        // Merge default filters with already stored filters and remove empty filters (i.e defaultFilters overriden by user)
        setColumnFilters(
            [...(columnFilters ?? []), ...existingFilters, ...differenceBy(defaultFilters, existingFilters, "id")].filter(
                filter => !!(filter?.value as unknown[])?.length
            )
        );
        setIsReady(true);
    }, [columnSize]);

    useEffect(() => {
        if (!onLazyLoad) {
            setTableData(data);
            setRowCount(data.length);
            setTotalRowCount(data.length);
            return;
        }
        void (async function () {
            // Make sure that the filters are unique
            const filters = Array.from(
                new Map(
                    columnFilters
                        .map(filter => ({
                            key: parseColumnId<U>(filter.id),
                            values: Array.isArray(filter.value) ? filter.value : [filter.value]
                        }))
                        .map(item => [JSON.stringify(item.key), item])
                ).values()
            );
            const inputs = { filters, searchText, sorting, selectionMode, pagination };
            if (!isReady) {
                return;
            }
            if (onLazyLoad && !isEqual(lastFetchedInfo.current, inputs)) {
                if (minSearchLength && searchText?.length && searchText.length < minSearchLength) {
                    return;
                }
                setIsLoading(true);
                // If filters or search have changed, reset pagination to 0
                const { filters: previousFilters, searchText: previousSearchText } = lastFetchedInfo.current ?? {};
                const currentPage = !isEqual(previousFilters, filters) || previousSearchText !== searchText ? 0 : pagination?.pageIndex ?? 0;
                try {
                    const result = await onLazyLoad({
                        pagination: pagination?.pageSize ? { page: currentPage, pageSize: pagination.pageSize } : undefined,
                        filters,
                        searchText,
                        sort: sorting[0] ? [sorting[0].id, sorting[0].desc ? SortDirection.DESCENDING : SortDirection.ASCENDING] : null
                    });
                    if (result) {
                        if (pagination) {
                            setPagination({ ...pagination, pageIndex: currentPage });
                        }

                        if (result.totalCount) {
                            setTotalRowCount(result.totalCount);
                        }

                        setQueryRowCount(result.totalResults);
                        setTableData(result.data);
                        setIsLoading(false);
                    }
                } catch {
                    setIsLoading(false);
                } finally {
                    const outputs = { ...inputs, pagination: { ...pagination, pageIndex: currentPage } };
                    lastFetchedInfo.current = outputs;
                }
            }
        })();
    }, [data, onLazyLoad, pagination, sorting, columnFilters, searchText]);

    const columnVisibility = columns
        .filter(column => !!column.hide)
        .reduce((acc, column) => {
            acc[column.id || column.accessorKey || column.header?.toString() || ""] = false;
            return acc;
        }, {} as VisibilityState);

    function findLeaf(rows: TableRow<T>[], parent: TableRow<T>, keyPath?: keyof TableRow<T>): TableRow<T> | null {
        let result = null;
        for (const item of rows) {
            if ((keyPath && item[keyPath] === parent[keyPath]) || isEqual(item, parent)) {
                return item;
            }
            if (item.children) {
                result = findLeaf(item.children, parent, keyPath);
                if (result) {
                    return result;
                }
            }
        }
        return result;
    }

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const isExpandable = tableData.some(row => isExpandableRow(row, isTree));
    const memoizedTableData = useMemo(() => {
        const rows = isExpandable ? tableData.map((row, index) => ({ expander: index, ...row })) : tableData;
        if (onLazyLoad || !pagination || onPageChanged) {
            return rows;
        }
        return rows.slice(pagination.pageIndex * pagination.pageSize, (pagination.pageIndex + 1) * pagination.pageSize);
    }, [tableData, pagination]);

    function toggleExpandedRows(): void {
        setExpanded(expandRows(Object.keys(expanded).length > 0 ? [] : table.getFilteredRowModel().flatRows));
    }

    const tableColumn = [
        ...(selectionMode === SelectionMode.CHECKBOX
            ? [
                  {
                      id: "selectAll",
                      accessorKey: "selectAll",
                      header: !disableSelectAll ? SelectAllHeader : undefined,
                      cell: SelectRowCheckbox,
                      size: 40,
                      enableSorting: false,
                      enableColumnFilter: false
                  }
              ]
            : []),
        ...(isExpandable && !isTree
            ? [
                  {
                      id: "expander",
                      accessorKey: "expander",
                      header: () => <ExpandHeader onClick={toggleExpandedRows} areRowsExpanded={Object.keys(expanded).length > 0} />,
                      cell: ExpandRowButton,
                      size: 100,
                      enableSorting: false,
                      enableColumnFilter: false
                  }
              ]
            : []),
        ...columns
    ];

    const table = useReactTable<T & { children?: T[] }>({
        data: memoizedTableData,
        columns: tableColumn,
        rowCount: rowCount,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            globalFilter: searchText,
            expanded,
            rowSelection,
            pagination
        },
        filterFns: {
            multiSelectFilter: (row, columnId, filterValue) => multiSelectFilter<T>(row, columnId, filterValue, columns)
        },
        manualFiltering: !!onLazyLoad,
        getColumnCanGlobalFilter: () => !onLazyLoad, // Enable global filter on columns that contain data that is undefined or null
        enableColumnResizing: true,
        manualPagination: !!onLazyLoad,
        columnResizeMode: "onChange",
        defaultColumn: {
            filterFn: "multiSelectFilter",
            footer: props => props.column.id,
            minSize: 40,
            size: columnSize
        },
        meta: {
            selectionMode,
            setRowSelection: setSelectedRows ? onSelect : undefined,
            rowSelection,
            isTree,
            ...(onLoadChildren
                ? {
                      loadChildren: async (row: T) => {
                          const children = await onLoadChildren(row);
                          const parent = findLeaf(tableData, row, itemKey);
                          if (parent) {
                              parent.children = children;
                              setTableData([...tableData]);
                          }
                      }
                  }
                : {})
        },
        onExpandedChange: (value: Updater<ExpandedState>) => {
            if (typeof value === "function") {
                const updatedExpanded = value(expanded);

                setExpanded((prevExpanded: ExpandedState) => {
                    if (!updatedExpanded || typeof updatedExpanded !== "object") {
                        return prevExpanded;
                    }

                    return { ...updatedExpanded, ...expandedRows };
                });
            }
        },
        onRowSelectionChange: onSelect,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: row => isExpandableRow(row.original, isTree) || (!!isTree && !!row.original.children?.length),
        getSubRows: row => row.children,
        filterFromLeafRows: true, // search in leaf rows and keep parents displayed
        ...(itemKey && { getRowId: row => row[itemKey] as string })
    });

    const tableRows = table?.getRowModel()?.rows || [];
    // Filter out subRows in case of simple table
    // SubRows are rendered by their render method
    const rows = isTree ? tableRows : tableRows.filter(row => !row.parentId);
    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRef.current,
        estimateSize: () => 30,
        count: rows.length,
        overscan: 20
    });

    // Count the number of rows displayed after filtering
    useEffect(() => {
        const filteredRowsCount = table.getFilteredRowModel().flatRows.reduce((count, row) => count + (row.subRows.length ? 0 : 1), 0);
        let count = searchText || columnFilters.length ? filteredRowsCount : data.length;
        if (onLazyLoad) {
            count = queryRowCount;
        }
        // update results count on layout
        onFilter?.(count, totalRowCount);
        // update pagination
        setRowCount(count);
    }, [searchText, columnFilters, tableColumn, queryRowCount, totalRowCount]);

    useEffect(() => {
        if (!searchText || !isTree) {
            return;
        }
        setExpanded(expandRows(table.getFilteredRowModel().flatRows));
    }, [searchText]);

    useEffect(() => {
        const handleResize = () => {
            const [fixedColumns, defaultColumns] = partition<Column<T>>(
                tableColumn.filter((col: Column<T>) => !col.hide),
                col => col.size
            );
            const viewportWidth = tableContainerRef.current?.clientWidth ?? 0;
            if (!viewportWidth) {
                return;
            }
            const totalFixedColumns = sum(fixedColumns.map(col => col.size));
            // calculate the size of the remaining columns
            // - get the viewport width
            // - subtract the overlap
            // - subtract the fixed size columns
            // - divide by the number of remaining columns
            const size = (viewportWidth - TABLE_CLIENT_WIDTH_OVERLAP - totalFixedColumns) / defaultColumns.length;
            setColumnSize(Math.max(size, DEFAULT_COLUMN_SIZE));
        };

        handleResize();

        // Add a listener on the window to handle the resize of the table
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [tableContainerRef.current?.clientWidth, tableColumn]);

    // Table is exclusively composed of div because it may have a beneficial impact on resizing virtualized tables
    // example: https://tanstack.com/table/latest/docs/framework/react/examples/column-resizing-performant
    // comment: https://github.com/TanStack/table/issues/3685
    const tableContent = (
        <div
            ref={tableContainerRef}
            className={classNames("relative max-h-full w-full overflow-auto rounded-md", {
                "border bg-white": columnSize !== 0,
                [`border-${color}-light`]: !!color,
                "border-white": !color
            })}
        >
            {!isReady ? (
                <Spin />
            ) : (
                <div className="w-fit border-collapse">
                    <Head<T, V> table={table} columns={columns} storageKey={storageKey} onFiltersLazyLoad={onFiltersLazyLoad} />
                    {isLoading && (
                        <Skeletons.Col className="gap-0" col={Array.from({ length: rows?.length || 3 }).map(() => "w-full h-8 border-2 border-white")} />
                    )}
                    {!isLoading && <RowsViewer rows={rows} isVirtualized={isVirtualized} table={table} rowVirtualizer={rowVirtualizer} />}
                </div>
            )}
        </div>
    );
    if (pagination) {
        return (
            <div className="flex h-full flex-col gap-2 overflow-hidden">
                {tableContent}
                <Pagination
                    currentPage={pagination.pageIndex + 1}
                    onPageChange={pageIndex => {
                        const paginationIndex = pageIndex - 1;
                        table.setPageIndex(paginationIndex);
                        setPagination({ ...pagination, pageIndex: paginationIndex });
                        onPageChanged?.(paginationIndex);
                    }}
                    totalPages={pageCount ?? table.getPageCount()}
                />
            </div>
        );
    }
    return tableContent;
}

export const Table = forwardRef(TableComponent) as <T extends object, U = unknown, V = unknown>(
    props: TableProps<T, U, V> & { isTree?: boolean } & { ref?: Ref<{ getInternalLazyInfo: () => void } | undefined> | null }
) => JSX.Element;
