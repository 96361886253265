import { CodeAnalysisReport } from "@vaultinum/vaultinum-kys-api";
import { Alert, BaseLang, DeleteIcon, ModalButton, useLang } from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { handleAsyncActionWithMessages } from "../../../../../common/AsyncTools";
import { deleteCodeAnalysisUploadedFiles } from "../../../../../services/analyserService";

export default function DeleteCodeAnalysisUploadedFilesModal({ report }: { report: CodeAnalysisReport }): JSX.Element {
    const lang = useLang<BaseLang>();
    const [isLoading, setIsLoading] = useState(false);

    const onConfirm = async () => {
        setIsLoading(true);
        try {
            await handleAsyncActionWithMessages(() => deleteCodeAnalysisUploadedFiles(report));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ModalButton
            icon={DeleteIcon}
            title="Delete uploaded ZIPs"
            children={
                <div className="space-y-2">
                    <h4>Do you want to permanently delete all uploaded ZIPs for this analysis?</h4>
                    <Alert.Warning message="Ensure the audit is complete and the files are no longer needed, as analysis cannot be relaunched after confirmation." />
                </div>
            }
            onConfirm={onConfirm}
            buttonProps={{
                children: "Delete uploaded ZIPs",
                type: "default",
                isLoading: false,
                isDisabled: isLoading,
                icon: DeleteIcon
            }}
            lang={lang}
        />
    );
}
