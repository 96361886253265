import { Account } from "@vaultinum/vaultinum-api";
import { isUndefined, omitBy } from "lodash";
import { useMemo, useState } from "react";
import { Alert, Button, Buttons, Select, Spin, openNotificationWithIcon } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { SHARED_URL, SettingsKey, addQueryParamsToUrl } from "../../services";
import EmptyCTA from "../EmptyCTA";
import Step from "../Step";
import FrequencySelector from "./FrequencySelector";
import GitRepositories from "./GitRepositories";
import { SelectedGitRepositories } from "./RepositoryRow";

function goToGitSettingPage(): void {
    window.location.href = addQueryParamsToUrl(SHARED_URL.app.settings.index, { tab: SettingsKey.GIT });
}

export default function GitOperationForm({
    accountId,
    gitConnectionList,
    gitRepositories,
    onGitConnectionSelected,
    onSubmit,
    oneShotAlertLabel,
    loading,
    isSubmitting,
    hideFrequency,
    onCancel,
    disableSubmit
}: {
    accountId: string;
    gitConnectionList: Account.GitConnection[];
    gitRepositories: Account.GitRepository[];
    onGitConnectionSelected: (gitConnectionId: string) => Promise<void>;
    onSubmit: (gitOperation: Omit<Account.GitOperation, "id" | "creationDate" | "usedBy">) => void;
    oneShotAlertLabel?: string;
    loading?: boolean;
    isSubmitting?: boolean;
    hideFrequency?: boolean;
    onCancel?: () => void;
    disableSubmit?: boolean;
}): JSX.Element {
    const lang = useLang<CommonLang>();

    const [gitConnectionId, setGitConnectionId] = useState<string>();
    const [selectedRepositories, setSelectedRepositories] = useState<SelectedGitRepositories[]>([]);
    const [selectedFrequency, setSelectedFrequency] = useState<Account.GitOperation.Frequency>(Account.GitOperation.Frequency.MONTHLY);
    const [addAllRepositories, setAddAllRepositories] = useState<boolean>(false);

    const connections = gitConnectionList.map(connection => ({
        ...omitBy(connection, isUndefined),
        value: connection.id,
        label: connection.accountLogin
    }));

    const addRepository = (repository: Account.GitRepository): void => {
        setSelectedRepositories(previous => [
            ...previous,
            {
                repository,
                selectedBranch: repository.defaultBranch || ""
            }
        ]);
    };

    const selectGitConnection = async (id: string | null) => {
        if (!id) {
            return;
        }
        const selectedGitConnection = gitConnectionList.find(element => element.id === id);
        if (selectedGitConnection) {
            setSelectedRepositories([]);
            setGitConnectionId(selectedGitConnection.id);
            await onGitConnectionSelected(id);
        }
    };

    const selectRepository = (oldRepository: Account.GitRepository, newRepository: Account.GitRepository, defaultBranch?: string): void => {
        // Replace the old repository by the newest
        setSelectedRepositories(previous =>
            previous.map(element => {
                if (element.repository.id === oldRepository.id) {
                    return {
                        ...element,
                        repository: newRepository,
                        selectedBranch: defaultBranch || ""
                    };
                }
                return element;
            })
        );
    };

    const removeSelectedRepositories = (repository: Account.GitRepository): void => {
        setSelectedRepositories(previous => previous.filter(element => element.repository.id !== repository.id));
        setAddAllRepositories(false);
    };

    const selectBranch = (repositoryId: string, branch: string): void => {
        setSelectedRepositories(previous =>
            previous.map(element => {
                if (element.repository.id === repositoryId) {
                    return {
                        ...element,
                        selectedBranch: branch
                    };
                }
                return element;
            })
        );
    };

    const onAddAllRepositoriesChange = async (checked: boolean): Promise<void> => {
        try {
            if (checked) {
                setSelectedRepositories(
                    gitRepositories.map(element => ({
                        repository: element,
                        selectedBranch: element.defaultBranch || ""
                    }))
                );
            } else {
                if (gitConnectionId) {
                    await onGitConnectionSelected(gitConnectionId);
                }

                setSelectedRepositories([]);
            }

            setAddAllRepositories(checked);
        } catch {
            openNotificationWithIcon({ type: "error", message: lang.git.repositoriesError });
        }
    };

    const submitForm = () => {
        if (gitConnectionId) {
            onSubmit({
                accountId,
                gitConnectionId,
                isArchived: false,
                frequency: selectedFrequency === Account.GitOperation.Frequency.ONE_SHOT || hideFrequency ? undefined : selectedFrequency,
                repositories: selectedRepositories.map(element => ({
                    id: element.repository.id,
                    name: element.repository.name,
                    branch: element.selectedBranch
                }))
            });
        }
    };

    const steps = useMemo(
        () => [
            ...(!hideFrequency
                ? [
                      (index: number) => (
                          <Step
                              index={index}
                              title={lang.git.selectFrequency}
                              children={
                                  <>
                                      <FrequencySelector selectedFrequency={selectedFrequency} setSelectedFrequency={setSelectedFrequency} />
                                      {selectedFrequency !== Account.GitOperation.Frequency.ONE_SHOT && !hideFrequency && oneShotAlertLabel && (
                                          <Alert.Danger message={oneShotAlertLabel} />
                                      )}
                                  </>
                              }
                          />
                      )
                  ]
                : []),
            (index: number) => (
                <Step
                    index={index}
                    title={lang.git.selectSource}
                    children={
                        <div className="space-y-6">
                            <Select.Basic label={lang.git.source} options={connections} value={gitConnectionId || "-"} onChange={selectGitConnection} />
                        </div>
                    }
                />
            ),
            ...(gitConnectionId
                ? [
                      (index: number) => (
                          <Step
                              title={lang.git.selectRepository}
                              index={index}
                              children={
                                  <>
                                      {loading && (
                                          <div className="flex flex-col">
                                              <Spin />
                                          </div>
                                      )}
                                      {!loading && (
                                          <GitRepositories
                                              selectedRepositories={selectedRepositories}
                                              gitRepositories={gitRepositories}
                                              onRepositorySelected={selectRepository}
                                              addRepository={addRepository}
                                              removeRepository={removeSelectedRepositories}
                                              onBranchSelected={selectBranch}
                                              addAllRepositories={addAllRepositories}
                                              onAddAllRepositoriesChange={onAddAllRepositoriesChange}
                                          />
                                      )}
                                  </>
                              }
                          />
                      )
                  ]
                : [])
        ],
        [
            gitConnectionId,
            selectedFrequency,
            selectedRepositories,
            hideFrequency,
            loading,
            gitRepositories,
            oneShotAlertLabel,
            addAllRepositories,
            gitConnectionList
        ]
    );

    return (
        <>
            {!gitConnectionList.length && (
                <div className="rounded border bg-white p-8 shadow-md">
                    <EmptyCTA
                        title={lang.git.notConfigured}
                        content={lang.git.notConfiguredDetails}
                        buttonProps={{
                            onClick: goToGitSettingPage,
                            children: lang.git.setup,
                            isLoading: false
                        }}
                    />
                </div>
            )}
            {!!gitConnectionList.length && (
                <div className="space-y-6">
                    {steps.map((element, index) => (
                        <div key={index}>{element(index + 1)}</div>
                    ))}
                    {onCancel && (
                        <div className="my-6 flex justify-between">
                            <Buttons.Cancel onClick={onCancel} isLoading={false} />
                            <Button
                                onClick={submitForm}
                                isLoading={!!isSubmitting}
                                isDisabled={!selectedRepositories.length || disableSubmit}
                                children={lang.shared.upload}
                            />
                        </div>
                    )}
                    {!onCancel && (
                        <div className="flex justify-end">
                            <Buttons.Next onClick={submitForm} isDisabled={!selectedRepositories.length || disableSubmit} isLoading={!!isSubmitting} />
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
