import { ActiveElement, CartesianScaleTypeRegistry, ChartEvent, ChartTypeRegistry, Point, ScaleOptionsByType, TooltipItem } from "chart.js";
import Color from "color";
import { Chart } from "react-chartjs-2";
import { CommonLang, useLang } from "../../../../lang";
import { COLOR_SCHEME } from "../../../referentials";
import { CommonChartParams } from "../config";
import { NoData } from "../shared/NoData";

const MAX_TRAIL_OPACITY = 0.4;

type FormatValue = (value: number) => string | number;

function formatValue(value: number, format: FormatValue | undefined): string | number {
    return format ? format(value) : value;
}

type AxisParams = {
    title: string | Date;
    axisFormat?: FormatValue;
    tooltipFormat?: FormatValue;
};

export type ScatterChartDatasetPoint = {
    x: number | null;
    y: number | null;
    label?: string;
    color?: string;
    size?: number;
    trail?: { x: number; y: number; size?: number; color?: string }[];
    key?: string;
};

export type ScatterChartParams = Omit<CommonChartParams, "dataset"> & {
    dataset: ScatterChartDatasetPoint[];
    xAxis: AxisParams;
    yAxis: AxisParams;
    tooltipMaxLines?: number;
    showTrendLine?: boolean;
    trendLineColor?: string;
    onClick?: (data: ScatterChartDatasetPoint) => void;
};

type ScaleTitle = ScaleOptionsByType<ChartTypeRegistry["scatter"]["scales"]>["title"];
type ScaleTicks = ScaleOptionsByType<ChartTypeRegistry["scatter"]["scales"]>["ticks"];
type ScaleType = ScaleOptionsByType<ChartTypeRegistry["scatter"]["scales"]>["type"];

function getScale(
    title: string | Date,
    axisFormat: FormatValue | undefined,
    type: keyof CartesianScaleTypeRegistry = "linear"
): {
    beginAtZero: boolean;
    title: Pick<ScaleTitle, "display" | "text">;
    ticks: Pick<ScaleTicks, "callback">;
    type?: ScaleType;
} {
    return {
        beginAtZero: true,
        title: {
            display: true,
            text: typeof title === "string" ? title : title.toDateString()
        },
        ticks: {
            callback: value => (typeof value === "number" ? formatValue(value, axisFormat) : value)
        },
        ...(type && { type })
    };
}

function getDataWithSameCoordinates(dataset: ScatterChartParams["dataset"], x: number, y: number): ScatterChartParams["dataset"] {
    return dataset.filter(set => set.x === x && set.y === y);
}

function calculateTrendLine(points: { x: number; y: number }[]): { x: number; y: number }[] {
    const n = points.length;
    const sumX = points.reduce((sum, p) => sum + p.x, 0);
    const sumY = points.reduce((sum, p) => sum + p.y, 0);
    const sumXY = points.reduce((sum, p) => sum + p.x * p.y, 0);
    const sumX2 = points.reduce((sum, p) => sum + p.x * p.x, 0);

    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    // Use only the minimum and maximum x values for the trend line
    const maxX = Math.max(...points.map(p => p.x));

    return [
        { x: 0, y: intercept },
        { x: maxX, y: slope * maxX + intercept }
    ];
}

export function ScatterChart({
    dataset,
    xAxis,
    yAxis,
    width = "100%",
    height = "auto",
    tooltipMaxLines = 5,
    showTrendLine,
    trendLineColor,
    onClick
}: ScatterChartParams): JSX.Element {
    const lang = useLang<CommonLang>();

    if (!dataset.length) {
        return <NoData width={width} height={height} />;
    }

    const data = dataset.map(el => ({
        x: el.x ?? 0,
        y: el.y ?? 0,
        label: el.label ?? "",
        color: el.color ?? COLOR_SCHEME.slate.primary,
        size: el.size ?? 6
    }));
    const colors = data.map(({ color }) => color);

    const trendLine = showTrendLine ? calculateTrendLine(data) : [];
    const trailLines = dataset
        .filter(e => e.trail)
        .map(({ x, y, size, color, label, trail }) => [
            { x, y, size: size ?? 6, color: color ?? COLOR_SCHEME.slate.primary },
            ...(trail?.map(trailPoint => ({
                label,
                x: trailPoint.x ?? 0,
                y: trailPoint.y ?? 0,
                size: (trailPoint.size ?? size ?? 4) * 2, // x2 needed as in the case of "points" its the radius, here its the width
                color: trailPoint.color ?? color ?? COLOR_SCHEME.slate.primary
            })) || [])
        ]);

    function handleClick(_event: ChartEvent, elements: ActiveElement[]): void {
        if (onClick && elements.length && elements[0]?.datasetIndex === 0 && dataset[elements[0].index]) {
            onClick(dataset[elements[0].index]);
        }
    }

    return (
        <div className="flex justify-center items-center" style={{ width, height }}>
            <Chart
                type="scatter"
                options={{
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            mode: "nearest",
                            axis: "xy",
                            intersect: true,
                            callbacks: {
                                label: (context: TooltipItem<"scatter">) => {
                                    if (context.datasetIndex !== 0) {
                                        const point = context.dataset.data[context.dataIndex] as Point;
                                        return `(${formatValue(point.x, xAxis.tooltipFormat)}, ${formatValue(point.y, yAxis.tooltipFormat)})`;
                                    }

                                    const { label, x, y } = data[context.dataIndex];
                                    return `${label}: (${formatValue(x, xAxis.tooltipFormat)}, ${formatValue(y, yAxis.tooltipFormat)})`;
                                },
                                ...(!!tooltipMaxLines && {
                                    footer: (context: TooltipItem<"scatter">[]) => {
                                        const dataWithSamePoints = getDataWithSameCoordinates(dataset, context[0].parsed.x, context[0].parsed.y);
                                        const dataOffLimit = dataWithSamePoints.splice(tooltipMaxLines);
                                        if (dataOffLimit.length) {
                                            return `(${dataOffLimit.length + 1} ${lang.shared.others})`;
                                        }
                                        return [];
                                    }
                                })
                            },
                            ...(!!tooltipMaxLines && {
                                filter: (_: TooltipItem<"scatter">, index: number, array: TooltipItem<"scatter">[]) => {
                                    const dataWithSamePoints = getDataWithSameCoordinates(dataset, array[0].parsed.x, array[0].parsed.y);
                                    const dataOffLimit = dataWithSamePoints.splice(tooltipMaxLines);
                                    if (dataOffLimit.length) {
                                        return index < tooltipMaxLines - 1;
                                    }
                                    return true;
                                }
                            })
                        }
                    },
                    scales: {
                        x: getScale(xAxis.title, xAxis.axisFormat),
                        y: getScale(yAxis.title, yAxis.axisFormat)
                    },
                    onClick: handleClick,
                    responsive: true,
                    maintainAspectRatio: false
                }}
                data={{
                    datasets: [
                        {
                            type: "scatter",
                            data,
                            pointBackgroundColor: colors,
                            pointBorderColor: colors,
                            pointRadius: data.map(el => el.size)
                        },
                        ...(showTrendLine
                            ? [
                                  {
                                      type: "line",
                                      data: trendLine,
                                      borderColor: trendLineColor ?? COLOR_SCHEME.pink.primary,
                                      borderWidth: 1,
                                      borderDash: [4, 2]
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  } as any
                              ]
                            : []),
                        ...trailLines.map((trailLine, index) => ({
                            type: "line",
                            data: trailLine,
                            borderColor: Color(data[index].color).alpha(0.4).hexa(),
                            borderWidth: trailLine[0].size,
                            borderCapStyle: "round",
                            pointRadius: 0,
                            segment: {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                borderColor: (ctx: any) =>
                                    Color(ctx.p0?.raw.color)
                                        .alpha(Math.max(0, MAX_TRAIL_OPACITY - (ctx.p0DataIndex * MAX_TRAIL_OPACITY) / trailLine.length))
                                        .hexa(),
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                borderWidth: (ctx: any) => ctx.p1?.raw.size
                            }
                        }))
                    ]
                }}
            />
        </div>
    );
}
