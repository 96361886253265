import { IconSvgProps } from "../Icons";

export default function UnlinkSvg({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M22.551 1.45315C20.6175 -0.484097 17.4716 -0.484472 15.5392 1.45353L11.1982 5.81028C10.9023 6.10728 10.9023 6.5884 11.1982 6.88465C11.4941 7.18165 11.9733 7.18165 12.2692 6.88465L16.6106 2.5279C17.9523 1.18203 20.136 1.18278 21.4796 2.5279C22.8213 3.8749 22.8213 6.06603 21.4796 7.4119L15.7451 13.164C15.4492 13.4607 15.4492 13.9418 15.7451 14.2384C15.8932 14.3869 16.0864 14.4612 16.2806 14.4612C16.4745 14.4612 16.668 14.3873 16.8161 14.2384L22.551 8.48665C24.483 6.5479 24.483 3.39303 22.551 1.45315Z" />
            <path d="M11.6306 17.214L7.3886 21.4687C6.7391 22.1212 5.87435 22.4801 4.95485 22.4801C4.03497 22.4801 3.17022 22.1212 2.5211 21.4687C1.17935 20.1225 1.17935 17.9317 2.5211 16.5851L8.2541 10.8341C8.54997 10.5371 8.54997 10.0564 8.2541 9.75974C7.95822 9.46274 7.47897 9.46274 7.1831 9.75974L1.44972 15.5104C-0.483403 17.4495 -0.483403 20.6047 1.44972 22.5431C2.3861 23.4829 3.63072 24 4.95485 24C6.27897 24 7.52397 23.4829 8.4596 22.5431L12.7012 18.2887C12.9971 17.9921 12.9971 17.5114 12.7012 17.2144C12.4053 16.9174 11.9265 16.9174 11.6306 17.214Z" />
            <path d="M23.2491 14.184L20.4839 13.7527C20.0721 13.689 19.6833 13.9717 19.618 14.3869C19.5546 14.8012 19.837 15.1901 20.2506 15.2542L23.0151 15.6859C23.0549 15.6919 23.0946 15.6945 23.1336 15.6945C23.5004 15.6945 23.8221 15.4264 23.8806 15.0514C23.9451 14.637 23.662 14.2485 23.2491 14.184Z" />
            <path d="M19.041 17.3344C18.7657 17.0186 18.288 16.9864 17.9722 17.2616C17.6568 17.5376 17.6242 18.018 17.8998 18.3337L19.1655 19.788C19.3147 19.9601 19.5251 20.0479 19.7366 20.0479C19.9132 20.0479 20.0902 19.9864 20.2338 19.8604C20.5496 19.5844 20.5818 19.1044 20.3066 18.7882L19.041 17.3344Z" />
            <path d="M15.48 19.3357C15.3821 18.9274 14.9737 18.6791 14.5672 18.7736C14.16 18.8719 13.9084 19.2814 14.0062 19.6897L14.7064 22.6237C14.7896 22.9725 15.1005 23.2069 15.4432 23.2069C15.5002 23.2069 15.5602 23.2001 15.6199 23.1855C16.0267 23.088 16.2776 22.6777 16.1801 22.2697L15.48 19.3357Z" />
            <path d="M9.44766 4.38937C9.47879 4.38937 9.50841 4.38712 9.53954 4.38337C9.95504 4.33312 10.2509 3.95474 10.201 3.53887L9.86729 0.751869C9.81741 0.335244 9.43791 0.0389936 9.02466 0.0881186C8.60954 0.138369 8.31291 0.516744 8.36354 0.932994L8.69766 3.71962C8.74341 4.10549 9.07041 4.38937 9.44766 4.38937Z" />
            <path d="M5.66743 6.03674C5.81668 6.22086 6.0353 6.31574 6.25468 6.31574C6.4238 6.31574 6.59293 6.26024 6.73355 6.14436C7.05755 5.87886 7.10555 5.39999 6.84043 5.07486L5.70943 3.68624C5.44318 3.36074 4.96618 3.31274 4.64255 3.57861C4.31855 3.84411 4.27168 4.32299 4.53643 4.64811L5.66743 6.03674Z" />
            <path d="M1.16139 9.04687L4.06051 9.85162C4.12801 9.87037 4.19514 9.87899 4.26226 9.87899C4.59526 9.87899 4.89976 9.65774 4.99239 9.32174C5.10414 8.91749 4.86751 8.49899 4.46402 8.38687L1.56526 7.58212C1.16289 7.47074 0.744765 7.70774 0.633015 8.11237C0.522015 8.51662 0.75789 8.93474 1.16139 9.04687Z" />
        </svg>
    );
}
