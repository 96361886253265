import * as Switcher from "@radix-ui/react-switch";
import classNames from "classnames";
import { Fragment, useMemo } from "react";
import { Size } from "../../referentials";
import { Label } from "./Label";

type SwitchComponentProps = {
    isChecked: boolean;
    onClick: () => void;
    labelSize?: Extract<Size, "xs" | "sm" | "md">;
    labelPosition?: "top" | "right";
} & (
    | {
          label: React.ReactNode;
          id: string;
      }
    | {
          label?: never;
          id?: never;
      }
);

export default function Switch({ id, isChecked, onClick, label, labelSize = "md", labelPosition = "right" }: SwitchComponentProps): JSX.Element {
    const labelComponent = label ? <Label size={labelSize} children={label} htmlFor={id} /> : null;
    const switchComponent = (
        <Switcher.Root
            id={id}
            className={classNames("h-6 w-10 rounded-full", {
                "bg-slate-primary": isChecked,
                "bg-grey-light": !isChecked
            })}
            onClick={onClick}
        >
            <Switcher.Thumb
                className={classNames("block h-5 w-5 rounded-full bg-white duration-100", {
                    "translate-x-1": !isChecked,
                    "translate-x-4": isChecked
                })}
            />
        </Switcher.Root>
    );

    const components = useMemo(() => {
        if (label && labelPosition === "top") {
            return [labelComponent, switchComponent];
        }
        return [switchComponent, labelComponent];
    }, [label, labelPosition, labelSize, id, isChecked]);

    return (
        <div
            className={classNames({
                "flex items-center gap-2": label,
                "flex-col": labelPosition === "top"
            })}
        >
            {components.map((component, index) => (
                <Fragment key={index} children={component} />
            ))}
        </div>
    );
}
