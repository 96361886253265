import { Notification, UserProfile } from "@vaultinum/vaultinum-api";
import { Column } from "@vaultinum/vaultinum-sdk";
import { useCallback } from "react";
import { getStatusTag, getTagLevel } from "../../../common/TagTools";
import { getUserProfileNotifications } from "../../../services/notificationService";
import { StandardModelListView } from "../components/StandardModelListView";

const columns: Column<Notification>[] = [
    { header: "Key", accessorKey: "notificationKey" },
    { header: "Type", accessorKey: "type" },
    {
        header: "Level",
        accessorKey: "level",
        cell: cell => getTagLevel(cell.getValue<Notification.Level>())
    },
    {
        header: "Creator",
        accessorKey: "createdBy",
        cell: cell => {
            const createdBy = cell.getValue<Notification.User>();
            if (!createdBy) {
                return null;
            }
            return `${createdBy.type} ${createdBy.uid || ""}`;
        }
    },
    {
        header: "App",
        accessorKey: "targetApp"
    },
    {
        header: "Feature",
        accessorKey: "targetFeature",
        cell: cell => {
            const targetFeature = cell.getValue<Notification.TargetFeature>();
            if (!targetFeature) {
                return null;
            }
            return (
                <ul>
                    <li>feature → {targetFeature.feature}</li>
                    {targetFeature.subFeature && <li>subFeature → {targetFeature.subFeature}</li>}
                    {!!targetFeature.ids?.length && <li>ids → {JSON.stringify(targetFeature?.ids)}</li>}
                </ul>
            );
        }
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: cell => getStatusTag(cell.getValue<Notification.Status>())
    }
];

export function UserProfileNotifications({ userProfile }: { userProfile: UserProfile }) {
    const loadData = useCallback((onUpdate: (events: Notification[]) => void) => getUserProfileNotifications(userProfile.id, onUpdate), [userProfile.id]);
    return <StandardModelListView<Notification> name="Notifications" getModelItems={loadData} searchPlaceholder="Search by key" columns={columns} />;
}
