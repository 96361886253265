import { Audit } from "@vaultinum/vaultinum-kys-api";
import { useEffect, useState } from "react";
import { getAudit } from "../services/fullAuditService";

export function useFullAudit(fullAuditId: string | undefined): Audit | null | undefined {
    const [fullAudit, setFullAudit] = useState<Audit | null | undefined>();

    useEffect(() => {
        if (!fullAuditId) {
            setFullAudit(undefined);
            return () => {};
        }
        return getAudit(fullAuditId, setFullAudit);
    }, [fullAuditId]);

    return fullAudit;
}
