import * as ToggleGroup from "@radix-ui/react-toggle-group";
import classNames from "classnames";
import { ChangeEvent, useEffect, useState } from "react";
import {
    Button,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    DropdownActionProps,
    Dropdowns,
    EyeIcon,
    EyeInvisibleIcon,
    LockIcon,
    MailIcon,
    SearchIcon,
    Select
} from "../..";
import { startYear } from "../../../helpers";
import { CommonLang, plural, useLang } from "../../../lang";
import { BaseInputs } from "./BaseInput";
import { DatePickerHeader } from "./DatePickerHeader";
import { ErrorMessage } from "./ErrorMessage";
import { TextAreaProps } from "./InputProps";
export namespace Input {
    export function Text(props: BaseInputs.TextProps) {
        return <BaseInputs.Text type="text" {...props} />;
    }

    export function Regex(props: Omit<BaseInputs.TextProps, "onChange" | "value"> & { value: RegExp | undefined; onChange?: (value: RegExp) => void }) {
        const lang = useLang<CommonLang>();
        const [regex, setRegex] = useState<string>(props.value && !props.value?.test("") ? props.value.source : "");
        const [hasError, setHasError] = useState<boolean>(false);
        const [flags, setFlags] = useState<string[] | null>(props.value?.flags?.split("") ?? []);

        function manageFlags(flag: string) {
            setFlags(prev => {
                if (prev?.includes(flag)) {
                    return prev.filter(f => f !== flag);
                }
                return [...(prev ?? []), flag];
            });
        }

        const avalaibleFlags: DropdownActionProps[] = [
            [lang.inputs.regex.global, "g"],
            [lang.inputs.regex.insensitive, "i"],
            [lang.inputs.regex.multiLine, "m"],
            [lang.inputs.regex.singleLine, "s"],
            [lang.inputs.regex.unicode, "u"]
        ].map(([label, flag]) => ({
            label,
            icon: flags?.includes(flag) ? CheckIcon : undefined,
            onClick: () => manageFlags(flag)
        }));

        function onChange(val: string = regex) {
            try {
                props.onChange?.(new RegExp(val, (flags ?? []).join("")));
                setHasError(false);
            } catch {
                setHasError(true);
            }
        }

        useEffect(() => {
            onChange();
        }, [flags]);

        return (
            <BaseInputs.Text
                type="text"
                {...props}
                value={regex}
                onChange={e => setRegex(e.target.value)}
                innerRightChildren="/"
                innerLeftChildren="/"
                addonAfter={(flags?.toSorted() ?? []).join("")}
                debounce={{
                    wait: 400,
                    callback: e => onChange(e.target.value)
                }}
                rightChildren={<Dropdowns.Options isLoading={false} actions={avalaibleFlags} />}
                errorMessage={hasError ? lang.shared.invalidRegex : props.errorMessage}
            />
        );
    }

    export function Email(props: Omit<BaseInputs.TextProps, "icon">) {
        return <BaseInputs.Text type="email" icon={MailIcon} {...props} />;
    }

    export function Password(props: Omit<BaseInputs.TextProps, "icon">) {
        const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

        const togglePasswordVisibility = () => {
            setIsPasswordVisible(prev => !prev);
        };

        let icon;
        if (!props.value) {
            icon = LockIcon;
        } else if (isPasswordVisible) {
            icon = EyeIcon;
        } else {
            icon = EyeInvisibleIcon;
        }

        return (
            <BaseInputs.Text
                icon={icon}
                type={isPasswordVisible ? "text" : "password"}
                {...(props.value && {
                    onIconClick: togglePasswordVisibility
                })}
                {...props}
            />
        );
    }

    export function Search({ navigation, ...props }: Omit<BaseInputs.SearchProps, "icon">) {
        return (
            <BaseInputs.Text
                type="search"
                {...props}
                icon={SearchIcon}
                {...(navigation && {
                    innerRightChildren: (
                        <div className="flex">
                            <Button onClick={navigation.onPrevious} type="default" fill="link" size="sm" icon={ChevronUpIcon} isLoading={false} />
                            <Button onClick={navigation.onNext} type="default" fill="link" size="sm" icon={ChevronDownIcon} isLoading={false} />
                        </div>
                    )
                })}
            />
        );
    }

    export function Checkbox(props: BaseInputs.ChoiceProps) {
        return <BaseInputs.Choice type="checkbox" {...props} />;
    }

    export function Radio(props: BaseInputs.ChoiceProps) {
        return <BaseInputs.Choice type="radio" {...props} />;
    }

    export function RadioButton({
        currentValue,
        selectedValue
    }: {
        currentValue: {
            label: string;
            value: string;
        };
        selectedValue: string;
    }) {
        return (
            <ToggleGroup.Item
                key={currentValue.value}
                value={currentValue.value}
                className={classNames("flex items-center justify-center px-4 py-2 text-sm first:ml-0 first:rounded-l last:rounded-r", {
                    "bg-slate-primary text-white transition-colors hover:bg-slate-light": selectedValue === currentValue.value,
                    "bg-white text-grey-dark hover:text-slate-primary": selectedValue !== currentValue.value
                })}
                style={{ marginLeft: 1 }}
            >
                {currentValue.label}
            </ToggleGroup.Item>
        );
    }

    function TextAreaSubFooter({
        displayCount,
        errorMessage,
        maxLength,
        value
    }: {
        displayCount?: boolean;
        errorMessage?: string;
        maxLength?: number;
        value?: string;
    }) {
        const lang = useLang<CommonLang>();
        const remainingCharacters = (maxLength ?? 0) - (value?.length ?? 0);

        return (
            <div
                className={classNames("flex", {
                    "justify-between": errorMessage && displayCount,
                    "justify-end": !errorMessage && displayCount
                })}
            >
                {errorMessage && <ErrorMessage message={errorMessage} />}
                {displayCount && maxLength && <span className="text-xs text-grey-primary">{plural(lang.shared.characters, remainingCharacters)}</span>}
            </div>
        );
    }

    export function TextArea({ displayCount, errorMessage, ...props }: TextAreaProps & BaseInputs.AdditionalTextAreaProps) {
        return (
            <div className="w-full">
                <BaseInputs.TextArea {...props} />
                <TextAreaSubFooter displayCount={displayCount} errorMessage={errorMessage} maxLength={props.maxLength} value={props.value as string} />
            </div>
        );
    }

    export function TextAreaWithFooter({ displayCount, errorMessage, ...props }: TextAreaProps & BaseInputs.TextAreaWithFooterProps) {
        return (
            <div className="w-full">
                <BaseInputs.TextAreaWithFooter {...props} />
                <TextAreaSubFooter displayCount={displayCount} errorMessage={errorMessage} maxLength={props.maxLength} value={props.value as string} />
            </div>
        );
    }

    export function DatePicker(props: BaseInputs.DatePickerProps) {
        return (
            <BaseInputs.DatePicker
                {...props}
                renderCustomHeader={headerProps => (
                    <DatePickerHeader {...headerProps} range={props.maxDate ? [startYear, props.maxDate.getFullYear()] : props.yearRange} />
                )}
                isClearable
                showMonthDropdown
                showYearDropdown
            />
        );
    }

    export function RangePicker(props: BaseInputs.DatePickerRangeProps) {
        return (
            <BaseInputs.RangePicker {...props} renderCustomHeader={headerProps => <DatePickerHeader {...headerProps} />} showMonthDropdown showYearDropdown />
        );
    }

    export function Phone({
        countriesPhoneData,
        value = {},
        defaultValue = {},
        label,
        onChange,
        errorMessage
    }: BaseInputs.PhoneProps & Pick<BaseInputs.TextProps, "label" | "errorMessage">): JSX.Element {
        const triggerChange = (changedValue: BaseInputs.InputPhoneValue) => {
            onChange?.({ target: { value: { ...value, ...changedValue } } });
        };

        const handleCountryCallingCodeChange = (newValue: string | null) => {
            if (newValue) {
                triggerChange({ countryCode: newValue });
            }
        };

        const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
            triggerChange({ phoneNumber: event.target.value });
        };

        return (
            <div className="w-full">
                <div className="flex items-end gap-2">
                    <Select.CountriesCode
                        data={countriesPhoneData}
                        value={value.countryCode}
                        onChange={handleCountryCallingCodeChange}
                        defaultValue={defaultValue.countryCode}
                        label={label}
                    />
                    <Text
                        data-id="phone-input"
                        className="w-full"
                        onChange={handlePhoneNumberChange}
                        value={value.phoneNumber}
                        defaultValue={defaultValue.phoneNumber}
                    />
                </div>
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </div>
        );
    }
}
