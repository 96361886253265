/* eslint-disable no-case-declarations */
import { useStripe } from "@stripe/react-stripe-js";
import { App } from "@vaultinum/vaultinum-api";
import { useState } from "react";
import { Modal, openNotificationWithIcon } from "../../../../design-system";
import { CommonLang, useLang } from "../../../../lang";
import { PaymentMethodFormProvider, usePaymentMethodFormContext } from "../../../contexts";
import { createSetupIntent } from "../../../services";
import PaymentMethodForm from "./PaymentMethodForm";

type PaymentMethodAddModalInterface = {
    accountId: string;
    visible: boolean;
    doClose: () => void;
    onSuccessCallback?: () => void;
};

function Content({ accountId, onSuccessCallback, visible, doClose }: PaymentMethodAddModalInterface): JSX.Element {
    const lang = useLang<CommonLang>();
    const stripe = useStripe();
    const [isLoading, setIsLoading] = useState(false);
    const { isFormValid, setIsDisabled, paymentMethodType, cardName, cardElement, accountHolderName, accountHolderEmail, ibanElement } =
        usePaymentMethodFormContext();

    async function onOk() {
        setIsDisabled(true);
        setIsLoading(true);
        try {
            switch (paymentMethodType) {
                case App.PaymentMethod.CARD:
                    if (!stripe || !cardElement) {
                        throw new Error(lang.shared.failMessage);
                    }
                    const { client_secret: clientSecretCard } = await createSetupIntent(accountId, paymentMethodType);
                    if (clientSecretCard) {
                        const { error } = await stripe.confirmCardSetup(clientSecretCard, {
                            payment_method: {
                                card: cardElement,
                                billing_details: {
                                    name: cardName
                                }
                            }
                        });
                        if (error) {
                            throw new Error(error.message);
                        }
                    }
                    break;
                case App.PaymentMethod.SEPA_DEBIT:
                    if (!stripe || !ibanElement || !accountHolderName || !accountHolderEmail) {
                        throw new Error(lang.shared.failMessage);
                    }
                    const { client_secret: clientSecretSepaDebit } = await createSetupIntent(accountId, paymentMethodType);
                    if (clientSecretSepaDebit) {
                        const { error } = await stripe.confirmSepaDebitSetup(clientSecretSepaDebit, {
                            payment_method: {
                                sepa_debit: ibanElement,
                                billing_details: {
                                    name: accountHolderName,
                                    email: accountHolderEmail
                                }
                            }
                        });
                        if (error) {
                            throw new Error(error.message);
                        }
                    }
                    break;
                default:
                    throw new Error("No valid payment method selected");
            }
            onSuccessCallback?.();
            doClose();
        } catch (error) {
            openNotificationWithIcon({ type: "error", description: error.message || error.response?.data });
        } finally {
            setIsDisabled(false);
            setIsLoading(false);
        }
    }

    function onCancel() {
        doClose();
    }

    return (
        <Modal
            title={lang.payment.payment.method}
            isOpen={visible}
            onConfirm={onOk}
            okText={lang.shared.add}
            cancelText={lang.shared.cancel}
            onClose={onCancel}
            children={<PaymentMethodForm />}
            isLoading={isLoading}
            isDisabled={!isFormValid}
            lang={lang}
            size="md"
        />
    );
}

export default function PaymentMethodAddModal(props: PaymentMethodAddModalInterface): JSX.Element {
    return (
        <PaymentMethodFormProvider>
            <Content {...props} />
        </PaymentMethodFormProvider>
    );
}
