import { ClientInputMethod } from "@vaultinum/vaultinum-api";
import { useState } from "react";
import { ColCards, ColCardWithRadioProps } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";

export default function UploadModeSelector({
    setUploadMethod,
    availableMethods
}: {
    setUploadMethod: (mode: ClientInputMethod) => void;
    availableMethods: ColCardWithRadioProps<ClientInputMethod>[];
}): JSX.Element {
    const lang = useLang<CommonLang>();
    const [selectedId, setSelectedId] = useState<ClientInputMethod>(ClientInputMethod.UPLOAD);

    function onMethodSelected(id: ClientInputMethod) {
        setSelectedId(id);
        const foundMethod = availableMethods.find(method => method.radio.id === id);
        if (foundMethod) {
            setUploadMethod(foundMethod.radio.id);
        }
    }

    return (
        <div className="flex flex-col gap-3">
            <p className="text-base font-bold text-primary">{lang.shared.uploadMethod}</p>
            <div className="flex gap-4 pb-4">
                {availableMethods.map(method => {
                    return (
                        <ColCards.WithRadio<ClientInputMethod>
                            key={method.radio.id}
                            icon={method.icon}
                            title={{
                                text: method.title ?? "-",
                                position: "center"
                            }}
                            children={method.children}
                            radio={{
                                id: method.radio.id,
                                selectedId,
                                onValueChange: id => onMethodSelected(id)
                            }}
                            isDisabled={method.isDisabled}
                        />
                    );
                })}
            </div>
        </div>
    );
}
