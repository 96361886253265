import { App } from "@vaultinum/vaultinum-api";
import { useMemo } from "react";
import { ColCards, ColCardWithRadioProps } from "../../../../design-system";
import { CommonLang, useLang } from "../../../../lang";
import { usePaymentMethodFormContext } from "../../../contexts";
import { getPaymentMethodDetails } from "../../../tools/PaymentTools";
import CreditCardForm from "./CreditCardForm";
import SepaForm from "./SepaForm";

const AVAILABLE_METHODS = [App.PaymentMethod.CARD, App.PaymentMethod.SEPA_DEBIT] as const;

export default function PaymentMethodForm(): JSX.Element {
    const lang = useLang<CommonLang>();
    const { paymentMethodType, setPaymentMethodType, isDisabled } = usePaymentMethodFormContext();

    const items: ColCardWithRadioProps<(typeof AVAILABLE_METHODS)[number]>[] = useMemo(() => {
        return AVAILABLE_METHODS.map(method => {
            const { icon, title } = getPaymentMethodDetails(method, lang);
            return {
                icon,
                children: title,
                isSelected: method === paymentMethodType,
                radio: {
                    id: method
                }
            };
        });
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
                <div className="flex gap-4">
                    {items.map(item => (
                        <ColCards.WithRadio<(typeof AVAILABLE_METHODS)[number]>
                            key={item.radio.id}
                            icon={item.icon}
                            title={{
                                text: item.title,
                                position: "center"
                            }}
                            children={item.children}
                            radio={{
                                id: item.radio.id,
                                selectedId: paymentMethodType,
                                onValueChange: setPaymentMethodType
                            }}
                            isDisabled={isDisabled}
                        />
                    ))}
                </div>
                <div className="flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
                    {paymentMethodType === App.PaymentMethod.CARD && <CreditCardForm />}
                    {paymentMethodType === App.PaymentMethod.SEPA_DEBIT && <SepaForm />}
                </div>
            </div>
        </div>
    );
}
