import { nth } from "lodash";
import { ReactGoogleChartEvent } from "react-google-charts";

export type ChartData = {
    label: string;
    data: (string | number)[];
    color?: string;
};

type LegendPosition = "top" | "bottom" | "left" | "right" | "none" | "labeled";
type LegendAlignment = "top" | "left" | "bottom" | "right" | "center";

export type ChartColumnDefinition =
    | string
    | {
          role: "style" | "tooltip";
          type: "string" | "number";
          p?: {
              html: boolean;
          };
      };

export type LegendConfig = {
    alignment: LegendAlignment;
    position: LegendPosition;
    maxLines: number;
    textStyle?: {
        fontSize?: number;
        margin?: number;
    };
};

export type CommonChartParams = {
    dataset: ChartData[];
    height?: number | `${string}%` | "auto";
    width?: number | `${string}%`;
    legendPosition?: LegendPosition;
    tooltip?: (item: { label: string; values: (string | number)[] }, index: number) => string;
};

export const commonAxisOption = {
    gridlines: {
        multiple: 1
    },
    minorGridlines: {
        count: 0
    },
    minValue: 5
};

export function getLegendConfig(
    position: LegendPosition,
    alignment: LegendAlignment = "center",
    textStyle?: { fontSize?: number; margin?: number }
): LegendConfig {
    return {
        position,
        alignment,
        maxLines: 4,
        ...(textStyle && { textStyle })
    };
}

export function getOnClickEvent(onClick: (item: { row: number | undefined; column: number | undefined }) => unknown): ReactGoogleChartEvent {
    return {
        eventName: "select",
        callback: event => {
            const selection = event.chartWrapper?.getChart().getSelection();
            if (!selection || selection.length === 0) {
                return;
            }
            const item = nth(selection, -1);
            if (!item) {
                return;
            }
            onClick(item as { row: number | undefined; column: number | undefined });
        }
    };
}
