import { Overwrite, SupportedLanguageCode, Survey, SURVEY_VERSION_REVISION_COLLECTION, Update } from "@vaultinum/vaultinum-api";
import {
    collection,
    CollectionReference,
    converter,
    DateAsTimestamp,
    DEFAULT_GRADE_SCORE_RANGE,
    deleteDoc,
    DocumentReference,
    doPost,
    endBefore,
    getDoc,
    getDocs,
    getFirestore,
    getItems,
    getSurvey,
    getSurveyRecordsBySurveyKey,
    increment,
    limit,
    NewWithoutDate,
    orderBy,
    query,
    REQUEST_LIMIT,
    serverTimestamp,
    setDoc,
    startAfter,
    surveyDoc,
    surveyLangDoc,
    surveyRecordGroupCollection,
    surveyVersionCollection,
    surveyVersionDoc,
    Unsubscribe,
    updateDoc,
    where,
    WriteBatch,
    writeBatch
} from "@vaultinum/vaultinum-sdk";
import { doDelete } from "./apiService";

const SURVEY_ENDPOINT = "admin-survey";

const incrementAndGetLatestSurveyVersion = async (surveyKey: string): Promise<number> => {
    await updateDoc(surveyDoc(surveyKey), { latestVersion: increment(1) });
    const survey = await getSurvey(surveyKey);
    return survey?.latestVersion ?? 0;
};

export async function uploadSurvey({ version, ...surveyData }: Survey.Version, surveyLangs: Survey.Lang[], uploaderUID: string): Promise<typeof version> {
    const newVersion = await incrementAndGetLatestSurveyVersion(surveyData.surveyKey);
    await setSurveyVersion(surveyData.surveyKey, newVersion, { ...surveyData, updatedByUID: uploaderUID });

    // Upload all survey langs in sub-collection
    const surveyLangsBatch = writeBatch(getFirestore());
    surveyLangs.forEach(surveyLang =>
        batchSetSurveyLang(surveyLangsBatch, surveyData.surveyKey, newVersion, surveyLang.lang, { ...surveyLang, updatedByUID: uploaderUID })
    );
    await surveyLangsBatch.commit();

    return version;
}

async function isSurveyExisting(surveyKey: string): Promise<boolean> {
    return (await getDoc(surveyDoc(surveyKey))).exists();
}

function setSurvey(surveyKey: string, survey: NewWithoutDate<Survey>): Promise<void> {
    return setDoc(surveyDoc(surveyKey), { ...survey, creationDate: serverTimestamp() } as unknown as Survey);
}

export async function newSurvey(surveyKey: string, props: Pick<Survey, "name" | "scope">): Promise<void> {
    if (await isSurveyExisting(surveyKey)) {
        throw new Error(`Survey with key=${surveyKey} already exists`);
    }
    return setSurvey(surveyKey, {
        visible: false,
        latestVersion: 0,
        gradeScoreRange: DEFAULT_GRADE_SCORE_RANGE,
        ...props
    });
}

export function updateSurvey(surveyKey: string, props: Update<Pick<Survey, "visible" | "name" | "gradeScoreRange" | "scope">>): Promise<void> {
    return setDoc(surveyDoc(surveyKey).withConverter(converter<Survey>()), props, { merge: true });
}

export function setSurveyVisible(surveyKey: string, visible: boolean): Promise<void> {
    return updateSurvey(surveyKey, { visible });
}

export function setSurveyGradeScoreRange(surveyKey: string, gradeScoreRange: Survey.GradeScoreRange): Promise<void> {
    return updateSurvey(surveyKey, { gradeScoreRange });
}

export async function publishSurveyVersionLang(surveyVersion: Survey.Version, publisherUID: string, lang: SupportedLanguageCode): Promise<void> {
    return updateDoc(surveyVersionDoc(surveyVersion.surveyKey, surveyVersion.version), {
        publishedLangs: [...surveyVersion.publishedLangs, lang],
        updatedByUID: publisherUID,
        updatedDate: serverTimestamp()
    });
}

export function updateSurveyVersion({ version, ...surveyVersion }: Survey.Version, updaterUID: string) {
    const newSurveyVersion = {
        ...surveyVersion,
        updatedByUID: updaterUID
    };
    return setSurveyVersion(surveyVersion.surveyKey, version, newSurveyVersion);
}

export async function deleteSurveyWithKey(surveyKey: string) {
    const records = await getSurveyRecordsBySurveyKey(surveyKey);
    if (records.length > 0) {
        throw Error("Can't delete, this survey is used by other records");
    }
    await doDelete(`${SURVEY_ENDPOINT}/${surveyKey}`);
}

function setSurveyVersion(surveyKey: string, version: number, surveyVersion: NewWithoutDate<Survey.Version>): Promise<void> {
    return setDoc(surveyVersionDoc(surveyKey, version), {
        surveyKey,
        ...surveyVersion,
        updatedDate: serverTimestamp(),
        sections: JSON.stringify(surveyVersion.sections)
        // Document stored in db is not exactly a Survey.Version (no field "version", "sections" is an array)
    } satisfies Overwrite<typeof surveyVersion, { sections: string }> & { surveyKey: string } & DateAsTimestamp as unknown as Survey.Version);
}

export function getSurveyVersions(surveyKey: string): Promise<Survey.Version[]>;
export function getSurveyVersions(surveyKey: string, onUpdate: (survey: Survey.Version[]) => void): Unsubscribe;
export function getSurveyVersions(surveyKey: string, onUpdate?: (survey: Survey.Version[]) => void): Promise<Survey.Version[]> | Unsubscribe {
    if (onUpdate) {
        return getItems(surveyVersionCollection(surveyKey), onUpdate);
    }
    return getItems(surveyVersionCollection(surveyKey));
}

export async function deleteSurveyVersion(surveyVersion: Survey.Version, alsoDeleteSurveyRecords?: boolean) {
    await doDelete(`${SURVEY_ENDPOINT}/${surveyVersion.surveyKey}/version/${surveyVersion.version}`);
    if (alsoDeleteSurveyRecords) {
        const surveyRecordDocs = await getDocs(
            query(surveyRecordGroupCollection(), where("surveyKey", "==", surveyVersion.surveyKey), where("surveyVersion", "==", surveyVersion.version))
        );
        const removeBatch = writeBatch(getFirestore());
        surveyRecordDocs.docs.forEach(doc => removeBatch.delete(doc.ref));
        await removeBatch.commit();
    }
}

export async function setSurveyAsLatest(surveyKey: string, latestVersion: number) {
    const surveyVersionsDocs = await getDocs(surveyVersionCollection(surveyKey));
    const batch = writeBatch(getFirestore());
    surveyVersionsDocs.docs.forEach(surveyVersionDoc =>
        batch.update(surveyVersionDoc.ref, {
            latest: surveyVersionDoc.id === String(latestVersion)
        })
    );
    await batch.commit();
}

export function newDraftSurveyVersion(surveyKey: string) {
    return doPost(`${SURVEY_ENDPOINT}/${surveyKey}/new`, {}, process.env.REACT_APP_API_HOST);
}

export function copySurveyVersion(surveyKey: string, version: number, targetSurveyKey: string) {
    return doPost(`${SURVEY_ENDPOINT}/${surveyKey}/${version}/copy`, { targetSurveyKey }, process.env.REACT_APP_API_HOST);
}

export function nodeTypeToGroupKey(nodeType: Survey.NodeType): string {
    switch (nodeType) {
        case Survey.NodeType.SECTION:
            return "sections";
        case Survey.NodeType.QUESTION:
            return "questions";
        case Survey.NodeType.OPTION:
            return "options";
        case Survey.NodeType.VARIANT:
            return "variants";
        default:
            return "";
    }
}

export function groupKeyToNodeType(groupKey: string): Survey.NodeType | null {
    switch (groupKey) {
        case "sections":
            return Survey.NodeType.SECTION;
        case "questions":
            return Survey.NodeType.QUESTION;
        case "options":
            return Survey.NodeType.OPTION;
        case "variants":
            return Survey.NodeType.VARIANT;
        default:
            return null;
    }
}

// Survey Lang

function refAndData(
    surveyKey: string,
    surveyVersion: number,
    lang: SupportedLanguageCode,
    surveyLang: NewWithoutDate<Survey.Lang>
): [DocumentReference<Survey.Lang>, Survey.Lang] {
    return [
        surveyLangDoc(surveyKey, surveyVersion, lang),
        { ...surveyLang, surveyKey, surveyVersion, updatedDate: serverTimestamp() } satisfies typeof surveyLang & DateAsTimestamp as unknown as Survey.Lang
    ];
}

export function setSurveyLang(surveyKey: string, surveyVersion: number, lang: SupportedLanguageCode, surveyLang: NewWithoutDate<Survey.Lang>): Promise<void> {
    return setDoc(...refAndData(surveyKey, surveyVersion, lang, surveyLang));
}

function batchSetSurveyLang(
    batch: WriteBatch,
    surveyKey: string,
    surveyVersion: number,
    lang: SupportedLanguageCode,
    surveyLang: NewWithoutDate<Survey.Lang>
): WriteBatch {
    return batch.set(...refAndData(surveyKey, surveyVersion, lang, surveyLang));
}

export function newSurveyLang(surveyKey: string, surveyVersion: number, lang: SupportedLanguageCode, updatedByUID: string): Promise<void> {
    return setSurveyLang(surveyKey, surveyVersion, lang, {
        surveyKey,
        surveyVersion,
        evaluationTag: {},
        options: {},
        questions: {},
        sections: {},
        survey: {
            title: "",
            description: ""
        },
        updatedByUID
    });
}

export function deleteSurveyLang(surveyKey: string, surveyVersion: number, lang: SupportedLanguageCode): Promise<void> {
    return deleteDoc(surveyLangDoc(surveyKey, surveyVersion, lang));
}

// Survey Revision

function surveyVersionRevisionCollection(surveyKey: string, version: number): CollectionReference<Survey.Version.Revision> {
    return collection(surveyVersionDoc(surveyKey, version), SURVEY_VERSION_REVISION_COLLECTION).withConverter(converter<Survey.Version.Revision>());
}

export function getSurveyVersionRevisions(
    surveyKey: string,
    version: number,
    onUpdate: (surveyRevisions: Survey.Version.Revision[]) => void,
    { searchAfter, lang }: { searchAfter?: Date; lang?: SupportedLanguageCode | null } = {}
): () => void {
    const q = query(
        surveyVersionRevisionCollection(surveyKey, version),
        orderBy("changedDate", "desc"),
        limit(REQUEST_LIMIT),
        searchAfter ? startAfter(searchAfter) : endBefore(0),
        ...(lang ? [where("lang", "==", lang)] : [])
    );
    return getItems(q, onUpdate);
}
