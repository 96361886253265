import { LANG_EN } from "@vaultinum/vaultinum-api";
import { Button, Buttons, Column, CopyIcon, copyToClipboard, formatDateTime, SortDirection, Spin, Table } from "@vaultinum/vaultinum-sdk";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchBar, { SearchBarOptions, SearchBarValues } from "../../../components/SearchBar";

export function StandardModelListView<T extends { id: string }>({
    name,
    getModelItems,
    applySearchOptions,
    columns,
    creationDateIndex = "creationDate",
    searchOptions,
    searchPlaceholder = "Search by id or name...",
    modelPageLink,
    extraActions,
    viewButtonLabel = "View",
    actionColumnSize,
    isVirtualized = false,
    storageKey
}: {
    name: string;
    getModelItems: null | ((onUpdate: (items: T[]) => void) => (() => void) | Promise<void> | void);
    applySearchOptions?: (item: T, searchValues: SearchBarValues) => boolean;
    columns?: Column<T>[];
    creationDateIndex?: string;
    searchOptions?: SearchBarOptions;
    searchPlaceholder?: string;
    modelPageLink?: (item: T) => string;
    extraActions?: (item: T) => React.ReactNode;
    viewButtonLabel?: string;
    actionColumnSize?: number;
    isVirtualized?: boolean;
    storageKey?: string;
}): JSX.Element {
    const navigate = useNavigate();
    const [modelItems, setModelItems] = useState<T[] | null>(null);
    const [searchValues, setSearchValues] = useState<SearchBarValues>({ search: "" });
    const filteredModelItems = useMemo(
        () => (applySearchOptions ? modelItems?.filter(modelItem => applySearchOptions(modelItem, searchValues)) : modelItems),
        [modelItems, searchValues, applySearchOptions]
    );
    const [count, setCount] = useState<number | undefined>(filteredModelItems?.length);

    useEffect(() => {
        void (() => getModelItems?.(setModelItems))();
    }, [getModelItems]);

    function getFilteredCount(): string | number {
        if (count === undefined || count === filteredModelItems?.length) {
            return filteredModelItems?.length || 0;
        }
        return `${count} of ${filteredModelItems?.length}`;
    }

    const renderActionsColumn = (modelItem: T) => {
        return (
            <div className="flex gap-2">
                <Buttons.Icon isLoading={false} type="default" title={`Copy ${name} Id`} onClick={() => copyToClipboard(modelItem.id)} icon={CopyIcon} />
                {modelPageLink && <Button isLoading={false} onClick={() => navigate(modelPageLink(modelItem))} children={viewButtonLabel} />}
                {extraActions && extraActions(modelItem)}
            </div>
        );
    };

    return (
        <div className="flex h-full flex-col gap-2 overflow-auto">
            <SearchBar placeholder={searchPlaceholder} setSearchValues={setSearchValues} options={searchOptions} />
            <p className="font-bold">
                {name}: {getFilteredCount()}
            </p>
            {!filteredModelItems && <Spin />}
            {filteredModelItems && (
                <Table<T>
                    searchText={searchValues.search}
                    onFilter={(filteredCount: number) => setCount(filteredCount)}
                    columns={[
                        {
                            header: "Created",
                            accessorKey: creationDateIndex,
                            sortingFn: "datetime",
                            cell: cell => {
                                const date = cell.getValue<Date>();
                                return date ? formatDateTime(date, LANG_EN) : "N/A";
                            },
                            defaultSort: SortDirection.DESCENDING,
                            size: 200
                        },
                        ...(columns || []),
                        {
                            header: "Actions",
                            accessorKey: "id",
                            enableSorting: false,
                            enableColumnFilter: false,
                            cell: cell => renderActionsColumn(cell.row.original),
                            ...(actionColumnSize ? { size: actionColumnSize } : {})
                        }
                    ]}
                    data={filteredModelItems}
                    isVirtualized={isVirtualized}
                    storageKey={storageKey}
                />
            )}
        </div>
    );
}
