import { useState } from "react";
import { Button, CardWithFeedback, Controller, Form, Input, login, message, useForm, useLang, useRequiredString, yup } from "../../../../common";
import { BrandLogo } from "../BrandLogo";

type Inputs = {
    email: string;
    password: string;
    "remember-me": boolean;
};

export default function HiddenLoginPage(): JSX.Element {
    const [working, setWorking] = useState(false);
    const schema = yup.object().shape({
        email: useRequiredString(),
        password: useRequiredString()
    });
    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<Inputs>({
        schema,
        mode: "onChange"
    });
    const lang = useLang();

    async function doLogin({ email, password, "remember-me": rememberMe }: Inputs) {
        try {
            setWorking(true);
            await login(email, password, rememberMe);
        } catch (error) {
            if (error.response) {
                const { data } = error.response;
                if (data.redirectTo) {
                    window.location.href = data.redirectTo;
                } else {
                    void message.error(lang.shared.loginFailed);
                }
            } else {
                void message.error(error.message);
            }
        } finally {
            setWorking(false);
        }
    }

    return (
        <CardWithFeedback extra={<BrandLogo />} title={lang.shared.signIn}>
            <Form onSubmit={handleSubmit(doLogin)}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <Input.Email {...field} placeholder={lang.shared.email} autoFocus disabled={working} errorMessage={errors.email?.message} />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => <Input.Password {...field} placeholder="Password" disabled={working} errorMessage={errors.password?.message} />}
                />
                <Controller
                    control={control}
                    name="remember-me"
                    render={({ field: { value, onChange } }) => (
                        <Input.Checkbox id="remember-me" value={value?.toString()} label="Remember me" onChange={onChange} />
                    )}
                />
                <div className="flex flex-wrap justify-between pt-6">
                    <Button htmlType="submit" isLoading={working} children={lang.shared.signIn} />
                </div>
            </Form>
        </CardWithFeedback>
    );
}
