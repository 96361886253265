import { LANG_EN } from "@vaultinum/vaultinum-api";
import { CodeAnalysisReport } from "@vaultinum/vaultinum-kys-api";
import { BreadcrumbItems, Spin, formatDateFullText } from "@vaultinum/vaultinum-sdk";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ModelItemPageHeader from "../../../components/ModelItemPageHeader";
import { CodeAuditContext } from "../../../contexts/CodeAuditContext";
import { useFullAudit } from "../../../hooks/useFullAudit";
import { getFullAuditCodeAnalysisReport } from "../../../services/codeAnalysisReportService";
import { getCodeAnalysisReportFiles } from "../../../services/codeReportFilesService";
import { ABSOLUTE_KYS2_PAGE } from "../../../services/routing.service";
import AnalyserLauncher from "../components/analyser/AnalyserLauncher";
import DeleteCodeAnalysisUploadedFilesModal from "../components/analyser/modals/DeleteCodeAnalysisUploadedFilesModal";
import ReOpenCodeUploadModal from "../components/analyser/modals/ReOpenCodeUploadModal";

export default function CodeAuditPage(): JSX.Element {
    const { fullAuditId, reportId } = useParams<{ fullAuditId: string; reportId: string }>();
    const fullAudit = useFullAudit(fullAuditId);
    const [report, setReport] = useState<CodeAnalysisReport | null>();
    const [reportFiles, setReportFiles] = useState<CodeAnalysisReport.File[]>([]);

    useEffect(() => {
        if (fullAudit?.reportIds.codeAuditReportId) {
            return getFullAuditCodeAnalysisReport(fullAudit.id, reportId, setReport);
        }
        return () => {};
    }, [fullAudit, reportId]);

    useEffect(() => {
        if (report) {
            return getCodeAnalysisReportFiles(report, setReportFiles);
        }
        return () => {};
    }, [report]);

    const contextValue = useMemo(() => ({ report, reportFiles }), [report, reportFiles]);

    if (fullAudit === undefined || report === undefined || !contextValue) {
        return <Spin />;
    }
    if (fullAudit === null || report === null) {
        return <h1>Audit or report not found</h1>;
    }

    const breadcrumbItems: BreadcrumbItems = [
        {
            label: "Code analysis requests",
            href: ABSOLUTE_KYS2_PAGE
        },
        {
            label: `${fullAudit.product.name} (${formatDateFullText(report.creationDate, LANG_EN)})`
        }
    ];

    return (
        <CodeAuditContext.Provider value={contextValue}>
            <div className="flex flex-col gap-6">
                <div className="flex items-center justify-between">
                    <ModelItemPageHeader breadcrumbItems={breadcrumbItems} />
                    <div className="flex gap-2">
                        {reportFiles?.some(reportFile => reportFile.hasData) && <DeleteCodeAnalysisUploadedFilesModal report={report} />}
                        {!report.isArchived && <ReOpenCodeUploadModal />}
                    </div>
                </div>
                <AnalyserLauncher />
            </div>
        </CodeAuditContext.Provider>
    );
}
