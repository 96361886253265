/* eslint-disable */
import { StoryFn } from "@storybook/react";
import { Account, AccountUser, LANG_EN, StaffUserRole, SupportedLanguageCode, UserProfile, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import { uniqueId } from "lodash";
import { ReactNode, useMemo } from "react";
import { copyToClipboard } from "../helpers";
import { CommonLang, DEFAULT_LANG, enCommon, getLangContext } from "../lang";
import { AppMenuItem, AppProvider, AuthContextProvider, AuthProps, BrandContextProvider, WhiteLabelContextProvider } from "../sdk";
import { SortDirection } from "../services";
import { DepositLogo, EscrowLogo, FullAuditLogo, IconSvgProps, LogoSize, LogoSvgProps, PersonLogo, SortProps } from "./components";

export const userProfile: UserProfile = {
    id: "1",
    firstName: "Arthur",
    lastName: "Dent",
    email: "arthur.dent@42.com",
    birthDate: new Date("01/01/2001"),
    accountIds: ["1", "2"],
    cellPhone: { countryCode: "", phoneNumber: "" },
    nationality: "Unknown",
    creationDate: new Date(),
    settings: {
        preferredLang: LANG_EN
    }
};

export const accountUser: AccountUser = {
    accountId: "1",
    creationDate: new Date(),
    email: userProfile.email,
    firstName: userProfile.firstName,
    id: userProfile.id,
    lastName: userProfile.lastName,
    lastUpdated: new Date(),
    rights: []
};

export const templateForComponent =
    <P,>(Component: (props: P) => any) =>
    (props: P): StoryFn<P> => {
        const template = (args: JSX.IntrinsicAttributes & P) => <Component {...args} />;
        const story: any = template.bind({});
        story.args = props;
        return story;
    };

export function MockedLangContextProvider({ children }: { children: ReactNode }): JSX.Element {
    const LangContext = getLangContext<CommonLang>();
    const value = useMemo(() => ({ ...enCommon, app: { itemName: "item", itemsName: "items", allItemsName: "all items" } }), []);
    return <LangContext.Provider value={value} children={children} />;
}

function LogoSpinnerSvg(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            {...props}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="126px"
            height="96px"
            viewBox="0 0 126 96"
            enableBackground="new 0 0 96 96"
        >
            <circle className="outter" cx="63" cy="48" r="41" fill="grey" />
            <svg id="cog-path" width="70px" height="70px" viewBox="0 0 96 96" x="28" y="13" fill="white">
                <path
                    stroke="lightgrey"
                    strokeWidth="1"
                    style={{ transformOrigin: "3rem 3rem" }}
                    d="M96,55.919V40.081c-3.104-0.646-6.545-1.228-10.255-1.739c-0.927-3.629-2.353-7.049-4.217-10.189
        c2.281-3.012,4.307-5.865,6.013-8.494L76.342,8.459c-2.629,1.706-5.482,3.731-8.493,6.013c-3.138-1.861-6.552-3.286-10.175-4.213
        C57.157,6.517,56.571,3.065,55.919,0H40.081c-0.652,3.065-1.238,6.517-1.755,10.259c-3.623,0.927-7.037,2.352-10.175,4.213
        c-3.01-2.281-5.864-4.307-8.493-6.013L8.459,19.658c1.706,2.629,3.731,5.482,6.013,8.494c-1.861,3.137-3.286,6.551-4.213,10.174
        C6.517,38.843,3.065,39.429,0,40.081v15.838c3.065,0.653,6.517,1.238,10.259,1.755c0.927,3.623,2.352,7.037,4.213,10.175
        c-2.281,3.011-4.307,5.864-6.013,8.493l11.199,11.199c2.629-1.706,5.483-3.731,8.493-6.013c3.138,1.861,6.552,3.286,10.175,4.213
        c0.517,3.742,1.103,7.193,1.755,10.259h15.838c0.652-3.065,1.238-6.517,1.755-10.259c3.628-0.928,7.047-2.355,10.188-4.221
        c2.985,2.261,5.829,4.282,8.48,6.021l11.199-11.199c-1.738-2.651-3.76-5.495-6.021-8.48c1.867-3.145,3.297-6.568,4.225-10.203"
                />
            </svg>
            <text x="35" y="50" fontWeight="lighter" fill="black" fontSize="6px" fontFamily="Segoe UI, sans-serif">
                Mocked logo spinner
            </text>
        </svg>
    );
}

export function MockedBrandContextProvider({ children }: { children: ReactNode }): JSX.Element {
    const value = useMemo(() => {
        const logoPath = "https://vaultinum.com/assets/img/vaultinum-logo-300x300.png";
        const logoWhitePath = "https://vaultinum.com/assets/img/vaultinum-logo-silver-300x300.svg";
        return { logoPath, logoWhitePath, LogoSpinnerSvg };
    }, []);
    return <BrandContextProvider value={value} children={children} />;
}
/* eslint-enable */

export const MOCKED_APPS_INFOS = {
    account: {
        appCode: "account" as const,
        baseUrl: "/account",
        logo: PersonLogo
    },
    foo: {
        appCode: "foo" as const,
        baseUrl: "/foo",
        logo: PersonLogo
    },
    bar: {
        appCode: "bar" as const,
        baseUrl: "/bar",
        logo: PersonLogo
    }
};

export function MockedWhiteLabelContextProvider({ children }: { children: ReactNode }): JSX.Element {
    const value = useMemo(
        () => ({
            domainLogo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRm2njQXuyrjcDsd0e9X8k11_SfWWc8mES28g&s",
            whiteLabelDomain: {
                name: "whiteLabel",
                id: "1",
                fqdn: "https://storybook.vaultinum.com",
                accessMode: WhiteLabelDomain.AccessMode.OPEN,
                configuration: {
                    [Object.keys(MOCKED_APPS_INFOS)[0]]: {
                        isEnabled: true,
                        isCodeScanAllowed: true
                    },
                    [Object.keys(MOCKED_APPS_INFOS)[1]]: {
                        isEnabled: true
                    }
                },
                creationDate: new Date(),
                createdByUID: "1",
                preferredLanguage: DEFAULT_LANG as SupportedLanguageCode
            }
        }),
        []
    );
    return <WhiteLabelContextProvider value={value} children={children} />;
}

export function MockedAppContextProvider({ children, appCode }: { children: ReactNode; appCode: "foo" | "bar" }): JSX.Element {
    const baseUrl = window.location.href.split("#")[0];
    window.location.href = `${baseUrl}#${MOCKED_APPS_INFOS[appCode].baseUrl}`;
    return <AppProvider children={children} value={{ appsInfos: MOCKED_APPS_INFOS, appCode }} />;
}

const mockedAccount1: Account = {
    id: "1",
    companyName: "The big Apple company",
    companyAddress: { line1: "", country: "", city: "", postalCode: "" },
    companyIndustry: "Space",
    companyNationality: "Unknown",
    companyRegistrationNumber: "",
    creationDate: new Date(),
    whiteLabelDomainId: null
};

const mockedAccount2: Account = {
    id: "2",
    companyName: "Vaultinum",
    companyAddress: { line1: "", country: "", city: "", postalCode: "" },
    companyIndustry: "Legal",
    companyNationality: "France",
    companyRegistrationNumber: "",
    creationDate: new Date(),
    whiteLabelDomainId: null
};

const mockedAccount3: Account = {
    id: "3",
    companyName: "A very very very very very very long company name",
    companyAddress: { line1: "", country: "", city: "", postalCode: "" },
    companyIndustry: "Military",
    companyNationality: "Space",
    companyRegistrationNumber: "",
    creationDate: new Date(),
    whiteLabelDomainId: null
};

export function MockedLoggedContextProvider({
    children,
    accounts,
    logged
}: {
    children: React.ReactNode;
    accounts?: Account[];
    logged?: boolean;
}): JSX.Element {
    const authSettings: AuthProps = useMemo(
        () => ({
            accountUser: logged ? accountUser : null,
            claims: { staffRoles: [StaffUserRole.ADMIN] },
            user: null,
            userProfile: logged
                ? {
                      ...userProfile,
                      accounts: accounts ?? [mockedAccount1, mockedAccount2, mockedAccount3]
                  }
                : null,
            selectedAccount: mockedAccount1
        }),
        [logged]
    );

    return <AuthContextProvider value={authSettings} children={children} />;
}

export const appsMenuItems: AppMenuItem[] = [
    {
        name: "Deposit",
        href: "#",
        logo: DepositLogo
    },
    {
        name: "Escrow",
        href: "#",
        logo: EscrowLogo
    },
    {
        name: "Due Diligence",
        href: "#",
        logo: FullAuditLogo
    }
];

export const countriesPhoneData: {
    name: string;
    countryCode: string;
}[] = [
    {
        name: "France",
        countryCode: "+33"
    },
    {
        name: "United Kingdom",
        countryCode: "+44"
    },
    {
        name: "Germany",
        countryCode: "+49"
    },
    {
        name: "United States",
        countryCode: "+1"
    },
    {
        name: "Bahamas",
        countryCode: "+1242"
    }
];

export type SortObject = {
    id: string;
    name: string;
    description: string;
    creationDate: Date;
};

function createSortObject(id: string, name: string, description: string, creationDate: Date): SortObject {
    return {
        id,
        name,
        description,
        creationDate
    };
}

export const SortObjects: SortObject[] = [
    createSortObject("1", "B Company", "B Company desc", new Date("2021-01-01")),
    createSortObject("2", "A Company", "A Company desc", new Date("2021-01-02")),
    createSortObject("3", "C Company", "C Company desc", new Date("2020-03-02"))
];

export const sorts: SortProps<SortObject>[] = [
    {
        key: "creationDate",
        label: "Creation date",
        onSort: (a: SortObject, b: SortObject, direction?: SortDirection | false) => {
            if (direction === SortDirection.ASCENDING) {
                return a.creationDate.getTime() - b.creationDate.getTime();
            }
            return b.creationDate.getTime() - a.creationDate.getTime();
        }
    },
    {
        key: "alphabetical",
        label: "Product name",
        onSort: (a: SortObject, b: SortObject, direction?: SortDirection | false) => {
            if (direction === SortDirection.ASCENDING) {
                return a.name.localeCompare(b.name);
            } else {
                return b.name.localeCompare(a.name);
            }
        },
        directionLabel: { asc: "A-Z", desc: "Z-A" }
    }
];

const randomText = ["Lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit"];

function generateSomeEmptyStrings(index: number) {
    if (index % 5 === 0) {
        return undefined;
    }
    if (index % 3 === 0) {
        return null;
    }
    if (index % 2 === 0) {
        return "";
    }
    return "Text defined";
}

function generateData(index: number, depth?: number, maxDepth = 5): Record<string, unknown> {
    const baseData = {
        col1: depth ? Array.from(new Array(10), () => randomText[Math.floor(Math.random() * randomText.length)]).join(" ") : generateSomeEmptyStrings(index),
        col2: new Date(),
        col3: Array.from(new Array(10), () => randomText[Math.floor(Math.random() * randomText.length)]).join(" "),
        col4: `This is a large column ${index}`,
        col5: index % 5 === 0 ? undefined : "defined text",
        col6: index % 5 === 0,
        col7: `Text ${index}`,
        col8: `Text ${index}`,
        col9: `Text ${index}`,
        col10: `Text ${index}`,
        col11: `Text ${index}`,
        col12: `Text ${index}`,
        col13: `Text ${index}`,
        col14: `Text ${index}`,
        col15: `Text ${index}`
    };
    if (depth) {
        return {
            ...baseData,
            key: uniqueId(`${index}-`),
            children: depth < maxDepth ? buildDataWithDepth(5, depth + 1, maxDepth) : []
        };
    }
    return baseData;
}

export function buildData(size: number): Record<string, unknown>[] {
    return [...Array(size)].map((_elem, index) => generateData(index));
}

export function buildDataWithDepth(size: number, currentDepth?: number, maxDepth = 5): Record<string, unknown>[] {
    const depth = currentDepth ?? 1;
    return [...Array(size)].map((_elem, index) => generateData(index, depth, maxDepth));
}

export async function tableLazyLoad(
    rows: Record<string, unknown>[],
    options: {
        pagination?: { page: number; pageSize: number };
        sort?: [string, SortDirection] | null;
        filters?: { key: unknown; values: unknown[] }[];
        searchText?: string;
    },
    totalCount?: number
) {
    let data = rows;
    // wait 1.5 second to simulate a server request
    await new Promise(resolve => setTimeout(resolve, 1500));

    if (options.sort) {
        const [key, order] = options.sort;
        data.sort((a, b) => {
            if (order === SortDirection.ASCENDING) {
                return String(a[key] ?? "").localeCompare(String(b[key] ?? ""));
            }
            return String(b[key] ?? "").localeCompare(String(a[key] ?? ""));
        });
    }
    if (options.filters) {
        const filters = options.filters;
        data = data.filter(row => filters.every(filter => filter.values.includes(row[filter.key as string])));
    }
    if (options.searchText) {
        const text = options.searchText.toLowerCase();
        data = data.filter(row => {
            return Object.values(row).some(value => {
                return String(value).toLowerCase().includes(text);
            });
        });
    }
    data = options.pagination
        ? data.slice(options.pagination.page * options.pagination.pageSize, (options.pagination.page + 1) * options.pagination.pageSize)
        : data;

    return { data, totalResults: data.length, totalCount };
}

export const DisplayLogos = (logos: React.FC<LogoSvgProps>[]): JSX.Element => {
    return (
        <div className="grid grid-cols-6 gap-2">
            {logos.map(LogoComponent => (
                <div
                    className="flex flex-col items-center p-4 hover:cursor-pointer hover:bg-slate-300"
                    onClick={() => copyToClipboard(LogoComponent.displayName)}
                    key={LogoComponent.displayName}
                >
                    <LogoComponent size={LogoSize.md} />
                    <span>{LogoComponent.displayName}</span>
                </div>
            ))}
        </div>
    );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const DisplayIcons = (icons: any[], props: IconSvgProps): JSX.Element => {
    return (
        <div className="grid grid-cols-6 gap-2">
            {icons.map(IconComponent => {
                const iconName = IconComponent.displayName.replace("Svg", "Icon");
                return (
                    <div
                        className="flex flex-col items-center p-4 hover:cursor-pointer hover:bg-slate-300"
                        onClick={() => copyToClipboard(iconName)}
                        key={iconName}
                    >
                        <IconComponent {...props} />
                        <span>{iconName}</span>
                    </div>
                );
            })}
        </div>
    );
};
