import { Chart } from "react-google-charts";
import { ChartColumnDefinition, ChartData, CommonChartParams, getLegendConfig, getOnClickEvent } from "../config";
import { NoData } from "../shared/NoData";

export type PieChartData = Omit<ChartData, "data"> & {
    value: number;
};

export type PieChartParams = Omit<CommonChartParams, "dataset" | "tooltip"> & {
    dataset: PieChartData[];
    isPercentage?: boolean;
    tooltip?: (values: PieChartData, index: number) => string;
    onClick?: (selectedItem: PieChartData) => void;
};

export function PieChart({ dataset, width = "100%", height = "90%", isPercentage, tooltip, legendPosition = "labeled", onClick }: PieChartParams): JSX.Element {
    if (!dataset.length) {
        return <NoData width={width} height={height} />;
    }
    const columnDefintions: ChartColumnDefinition[] = ["key", "value"];
    if (tooltip) {
        columnDefintions.push({ role: "tooltip", type: "string", p: { html: true } });
    }
    return (
        <Chart
            chartType="PieChart"
            chartEvents={onClick ? [getOnClickEvent(({ row }) => (row !== undefined ? onClick(dataset[row]) : undefined))] : []}
            width={width}
            height={height}
            options={{
                legend: getLegendConfig(legendPosition, "center", { fontSize: 14 }),
                pieSliceText: isPercentage ? "percentage" : "value",
                chartArea: {
                    left: 10,
                    right: 10,
                    top: legendPosition === "top" ? 30 : 10,
                    bottom: legendPosition === "bottom" ? 30 : 10
                },
                slices: dataset.map(slice => ({ color: slice.color, textStyle: { fontSize: 14 } })),
                tooltip: {
                    isHtml: !!tooltip
                }
            }}
            data={[columnDefintions, ...dataset.map((slice, index) => [slice.label, slice.value, ...(tooltip ? [tooltip(slice, index)] : [])])]}
            className="pie-chart"
        />
    );
}
