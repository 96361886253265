import { getNodeTargetValue, LANG_EN, Survey } from "@vaultinum/vaultinum-api";
import {
    Comment,
    Controller,
    EnterIcon,
    Form,
    formatDateFromNow,
    Input,
    NegativeTwoToneIcon,
    PositiveTwoToneIcon,
    RowCard,
    Space,
    Tooltip,
    useForm,
    User
} from "@vaultinum/vaultinum-sdk";
import { useContext, useRef, useState } from "react";
import { IconColors } from "../../../../common/Global";
import StaffUserAvatar from "../../../../components/StaffUserAvatar";
import StaffUserName from "../../../../components/StaffUserName";
import StringDiffDisplay from "../../../../components/StringDiffDisplay";
import { addSurveyCommentReply, resolveComment } from "../../../../services/surveyCommentService";
import { SurveyBuilderContext } from "../survey-builder/SurveyBuilderContext";
import "./CommentCard.css";
import { applyCommentChangeSuggestion } from "./SurveyCommentTools";

const CommentAuthor = ({ comment }: { comment: Survey.Comment | Survey.Comment.Reply }) => {
    return (
        <Space>
            <StaffUserName uid={comment.authorUID} />•<span title={comment.creationDate?.toString()}>{formatDateFromNow(comment.creationDate, LANG_EN)}</span>
            {"updatedDate" in comment && comment.updatedDate && (
                <i title={comment.updatedDate?.toString()}>- updated {formatDateFromNow(comment.updatedDate, LANG_EN)}</i>
            )}
        </Space>
    );
};

export default function CommentCard({ user, comment, selectedSurveyLang }: { user: User; comment: Survey.Comment; selectedSurveyLang: Survey.Lang }) {
    const [showReply, setShowReply] = useState(false);
    const { handleSubmit, control, reset } = useForm();
    const { suggestionMode } = useContext(SurveyBuilderContext);
    const cardRef = useRef(null);
    let actions = [];

    const displayReply = () => setShowReply(true);
    const hideReply = () => setShowReply(false);

    if (comment.changeSuggestion) {
        actions = [
            <Tooltip
                key="comment-basic-like"
                title={suggestionMode ? "Switch to editing mode to apply suggestion and resolve" : "Apply suggestion and resolve"}
            >
                {!suggestionMode && (
                    <span
                        onDoubleClick={async () => {
                            applyCommentChangeSuggestion(comment, selectedSurveyLang, user.uid);
                            await resolveComment(comment, user.uid);
                        }}
                    >
                        <PositiveTwoToneIcon isTwoToneColor /> Apply suggestion
                    </span>
                )}
                {suggestionMode && (
                    <span style={{ color: IconColors.gray }}>
                        <PositiveTwoToneIcon isTwoToneColor color="grey" /> Apply suggestion
                    </span>
                )}
            </Tooltip>,
            <Tooltip key="comment-basic-dislike" title="Discard suggestion and resolve">
                <span onDoubleClick={() => resolveComment(comment, user.uid)}>
                    <NegativeTwoToneIcon isTwoToneColor color="red" /> Reject suggestion
                </span>
            </Tooltip>
        ];
    } else {
        actions = [
            <Tooltip key="comment-basic-like" title="Apply suggestion and resolve">
                <span onDoubleClick={() => resolveComment(comment, user.uid)}>
                    <PositiveTwoToneIcon /> <span className="comment-action">Resolve</span>
                </span>
            </Tooltip>
        ];
    }
    if (!comment.replies.length && !showReply) {
        actions = [
            ...actions,
            <span key="comment-basic-reply-to" onClick={displayReply}>
                Reply <EnterIcon color="grey" shade="light" />
            </span>
        ];
    }
    return (
        <div ref={cardRef}>
            <RowCard>
                <Comment
                    className="comment-card"
                    author={<CommentAuthor comment={comment} />}
                    avatar={<StaffUserAvatar uid={comment.authorUID} />}
                    content={
                        <>
                            <p>{comment.comment}</p>
                            {comment.changeSuggestion && (
                                <div className="suggestion">
                                    <span className="title">Change suggestion:</span>
                                    <br />
                                    <StringDiffDisplay
                                        value={getNodeTargetValue(comment.target, selectedSurveyLang) || ""}
                                        other={comment.changeSuggestion}
                                        useWebWorker
                                    />
                                </div>
                            )}
                        </>
                    }
                    actions={actions}
                >
                    {comment.replies.map((reply, index) => (
                        <Comment
                            key={index}
                            author={<CommentAuthor comment={reply} />}
                            avatar={<StaffUserAvatar uid={reply.authorUID} />}
                            content={<p>{reply.comment}</p>}
                            actions={
                                index === comment.replies.length - 1 && !showReply
                                    ? [
                                          <span key="comment-basic-reply-to" onClick={displayReply}>
                                              Reply <EnterIcon color="grey" shade="light" />
                                          </span>
                                      ]
                                    : []
                            }
                        />
                    ))}
                    {showReply && (
                        <Form
                            onSubmit={handleSubmit(async ({ value }) => {
                                await addSurveyCommentReply(comment, user.uid, value);
                                reset();
                            })}
                        >
                            <Controller
                                name="value"
                                control={control}
                                render={({ field }) => <Input.Text {...field} placeholder="Reply..." autoFocus onBlur={hideReply} />}
                            />
                        </Form>
                    )}
                </Comment>
            </RowCard>
        </div>
    );
}
