import { FiltersSortService, SortDirection } from "./filter-sort.service";

export class LocalStorageFilterSortService implements FiltersSortService {
    private storageKey: string;

    constructor(storageKey: string) {
        this.storageKey = storageKey;
    }

    private getFiltersKey(): string {
        return `filters-${this.storageKey}`;
    }

    private getSortKey(): string {
        return `sort-${this.storageKey}`;
    }

    public saveFilter(filterKey: string, filters?: unknown[]): void {
        const savedFilters = localStorage.getItem(this.getFiltersKey());
        const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};
        localStorage.setItem(this.getFiltersKey(), JSON.stringify({ ...parsedFilters, [filterKey]: filters }));
    }

    public loadFilters(): Record<string, unknown[]> {
        const data = localStorage.getItem(this.getFiltersKey());
        if (!data) {
            return {};
        }
        return JSON.parse(data);
    }

    public loadFilter(filterKey: string): unknown[] {
        return this.loadFilters()[filterKey] ?? [];
    }

    public saveSort(sortKey: string, direction: string | boolean): void {
        localStorage.setItem(this.getSortKey(), JSON.stringify({ [sortKey]: direction }));
    }

    public loadSorts(): Record<string, SortDirection | false> {
        const data = localStorage.getItem(this.getSortKey());
        if (!data) {
            return {};
        }
        return JSON.parse(data);
    }

    public loadSort(sortKey: string): SortDirection | false {
        return this.loadSorts()[sortKey] || false;
    }
}
