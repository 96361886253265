import { Survey } from "@vaultinum/vaultinum-api";
import { createContext } from "react";
import { SurveyTreeNode } from "./nodes/TreeNode";

interface SurveyEditableNodesContextProps {
    selectedNodeKeys?: string[];
    setSelectedNodeKeys?: (selectedNodeKeys: string[]) => void;
    ctrlPressed?: boolean;
    copiedNodes?: { op: "cut" | "copy"; nodes: SurveyTreeNode[] };
    toggleExpandedRowKey?: (rowKey: string, expanded: boolean) => void;
    editedTarget?: Survey.NodeTarget;
    setEditedTarget?: (editingNode: Survey.NodeTarget | undefined) => void;
}

export const SurveyEditableNodesContext = createContext<SurveyEditableNodesContextProps>({} as SurveyEditableNodesContextProps);
