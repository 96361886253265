import { IconSvgProps } from "../Icons";

function CalendarSVG({ color, size }: IconSvgProps): JSX.Element {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M19.7778 3.11121H17.5555V2.0001C17.5555 1.70542 17.4385 1.42281 17.2301 1.21443C17.0218 1.00606 16.7391 0.888992 16.4445 0.888992C16.1498 0.888992 15.8671 1.00606 15.6587 1.21443C15.4505 1.42281 15.3334 1.70542 15.3334 2.0001V3.11121H8.66669V2.0001C8.66669 1.70542 8.54963 1.42281 8.34125 1.21443C8.13288 1.00606 7.85027 0.888992 7.55559 0.888992C7.26089 0.888992 6.97828 1.00606 6.76991 1.21443C6.56153 1.42281 6.44447 1.70542 6.44447 2.0001V3.11121H4.22225C3.33819 3.11121 2.49035 3.46241 1.86523 4.08753C1.24011 4.71265 0.888916 5.56049 0.888916 6.44455V19.7779C0.888916 20.662 1.24011 21.5097 1.86523 22.1349C2.49035 22.76 3.33819 23.1112 4.22225 23.1112H19.7778C20.6618 23.1112 21.5097 22.76 22.1349 22.1349C22.7599 21.5097 23.1111 20.662 23.1111 19.7779V6.44455C23.1111 5.56049 22.7599 4.71265 22.1349 4.08753C21.5097 3.46241 20.6618 3.11121 19.7778 3.11121ZM20.8889 19.7779C20.8889 20.0725 20.7718 20.3552 20.5634 20.5636C20.3551 20.772 20.0725 20.8889 19.7778 20.8889H4.22225C3.92756 20.8889 3.64495 20.772 3.43657 20.5636C3.2282 20.3552 3.11113 20.0725 3.11113 19.7779V12.0001H20.8889V19.7779ZM20.8889 9.77789H3.11113V6.44455C3.11113 6.14986 3.2282 5.86725 3.43657 5.65888C3.64495 5.4505 3.92756 5.33344 4.22225 5.33344H6.44447V6.44455C6.44447 6.73923 6.56153 7.02185 6.76991 7.23022C6.97828 7.43859 7.26089 7.55566 7.55559 7.55566C7.85027 7.55566 8.13288 7.43859 8.34125 7.23022C8.54963 7.02185 8.66669 6.73923 8.66669 6.44455V5.33344H15.3334V6.44455C15.3334 6.73923 15.4505 7.02185 15.6587 7.23022C15.8671 7.43859 16.1498 7.55566 16.4445 7.55566C16.7391 7.55566 17.0218 7.43859 17.2301 7.23022C17.4385 7.02185 17.5555 6.73923 17.5555 6.44455V5.33344H19.7778C20.0725 5.33344 20.3551 5.4505 20.5634 5.65888C20.7718 5.86725 20.8889 6.14986 20.8889 6.44455V9.77789Z" />
        </svg>
    );
}

export default CalendarSVG;
