import { FULL_AUDIT_COLLECTION, FULL_AUDIT_REPORTS_COLLECTION, FULL_AUDIT_REPORTS_PLUGINS_COLLECTION } from "@vaultinum/vaultinum-api";
import { CodeAnalysisReport, Plugin } from "@vaultinum/vaultinum-kys-api";
import { collection, deleteField, doc, getDoc, getFirestore, updateDoc } from "@vaultinum/vaultinum-sdk";
import { kysAnalyser } from "./apiService";

export function deleteCodeAnalysisUploadedFiles(report: CodeAnalysisReport): Promise<string> {
    return kysAnalyser.doDelete(`report/${report.fullAuditId}/${report.id}/files`);
}

export async function resetPluginStatus(report: CodeAnalysisReport, pluginKey: string): Promise<void> {
    const pluginDoc = await getDoc(
        doc(
            collection(
                getFirestore(),
                FULL_AUDIT_COLLECTION,
                report.fullAuditId,
                FULL_AUDIT_REPORTS_COLLECTION,
                report.id,
                FULL_AUDIT_REPORTS_PLUGINS_COLLECTION
            ),
            pluginKey
        )
    );
    if (!pluginDoc.exists()) {
        throw new Error("Plugin not found");
    }
    return updateDoc(pluginDoc.ref, {
        status: Plugin.Status.IDLE,
        insightsCreated: deleteField(),
        linksCreated: deleteField(),
        execDetails: deleteField(),
        execTime: deleteField()
    });
}
