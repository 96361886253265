import { Referential, REFERENTIAL, SupportedLanguageCode, SURVEY_INDUSTRY_STANDARDS } from "@vaultinum/vaultinum-api";
import { Kys, NON_DISCLOSURE_AGREEMENT, NON_DISCLOSURE_AGREEMENT_VERSIONS, NonDisclosureAgreement } from "@vaultinum/vaultinum-kys-api";
import {
    collection,
    converter,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    orderBy,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    writeBatch
} from "@vaultinum/vaultinum-sdk";

function ndaVersionsCollection(): ReturnType<typeof collection> {
    return collection(getFirestore(), REFERENTIAL, NON_DISCLOSURE_AGREEMENT, NON_DISCLOSURE_AGREEMENT_VERSIONS).withConverter(
        converter<NonDisclosureAgreement>()
    );
}

export function getIndustryStandardForSurvey(surveyKey: string, onUpdate: (referential: Referential.SurveyStandard | undefined) => void): () => void {
    return onSnapshot(doc(collection(getFirestore(), REFERENTIAL), SURVEY_INDUSTRY_STANDARDS), querySnapshot => {
        if (querySnapshot.exists()) {
            const standards = querySnapshot.data();
            return onUpdate(standards?.surveys?.[surveyKey]);
        } else {
            return () => {};
        }
    });
}

export function updateIndustryStandardForSurvey(surveyKey: string, referential: Referential.SurveyStandard) {
    return updateDoc(doc(collection(getFirestore(), REFERENTIAL), SURVEY_INDUSTRY_STANDARDS), { [`surveys.${surveyKey}`]: referential });
}

export function createNewNDAVersion(version: number, langs: Kys.Lang<string>): Promise<void> {
    return setDoc(doc(ndaVersionsCollection(), version.toString()), { creationDate: serverTimestamp(), latest: false, langs, version });
}

export function getNDAVersions(onUpdate: (nda: NonDisclosureAgreement[]) => void): () => void {
    return onSnapshot(query(ndaVersionsCollection(), orderBy("creationDate", "desc")).withConverter(converter<NonDisclosureAgreement>()), querySnapshot => {
        onUpdate(querySnapshot.docs.map(doc => ({ ...doc.data(), version: parseInt(doc.id) })));
    });
}

export async function getNDAVersion(version: string): Promise<NonDisclosureAgreement | null> {
    const snapshot = await getDoc(doc(ndaVersionsCollection(), version).withConverter(converter<NonDisclosureAgreement>()));

    if (!snapshot.exists()) {
        return null;
    }

    return snapshot.data();
}

export function updateNDAVersion(version: number, lang: SupportedLanguageCode | null, newCOU: string): Promise<void> {
    return updateDoc(doc(ndaVersionsCollection(), version.toString()), { [`langs.${lang}`]: newCOU });
}

export async function publishNDAVersion(version: number): Promise<void> {
    const ndaVersionDocs = await getDocs(ndaVersionsCollection());
    const batch = writeBatch(getFirestore());
    ndaVersionDocs.docs.forEach(doc => {
        batch.update(doc.ref, { latest: doc.id === version.toString() });
    });
    await batch.commit();
}
