import { Button, EyeInvisibleIcon, Input, Select } from "@vaultinum/vaultinum-sdk";
import { useDebounce } from "ahooks";
import classNames from "classnames";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { LONG_DEBOUNCE, QUICK_DEBOUNCE } from "../constants";

const MIN_SEARCH_LENGTH = 2;

export type SearchBarValues = {
    search: string;
    hideIgnored?: boolean;
};

export type SearchBarOptions = {
    hideIgnored?: boolean;
};

function SearchBarSuffix({
    hideIgnored,
    searchMode,
    setSearchMode,
    setHideIgnored,
    options,
    ignoredTitle,
    setReplace
}: {
    hideIgnored: boolean;
    searchMode: "simple" | "replace" | null;
    setSearchMode: Dispatch<SetStateAction<"simple" | "replace" | null>>;
    setHideIgnored: Dispatch<SetStateAction<boolean>>;
    options?: SearchBarOptions;
    ignoredTitle?: string;
    setReplace?: Dispatch<SetStateAction<string>>;
}) {
    return (
        <>
            {!options?.hideIgnored && (
                <div
                    title={ignoredTitle || "Hide ignored accounts"}
                    onClick={() => setHideIgnored(prev => !prev)}
                    className={classNames("button mx-1 cursor-pointer rounded p-1 shadow", { "bg-primary text-white": hideIgnored })}
                >
                    <EyeInvisibleIcon size="xs" color={hideIgnored ? "white" : "slate"} />
                </div>
            )}
            {setReplace && (
                <Select.Basic
                    className="w-40"
                    value={searchMode}
                    onChange={setSearchMode}
                    options={[
                        {
                            value: "simple",
                            label: "Search"
                        },
                        {
                            value: "replace",
                            label: "Search and replace"
                        }
                    ]}
                />
            )}
        </>
    );
}

function SearchAndReplace({
    isLoading,
    internalSearch,
    internalReplace,
    searchAndReplace
}: {
    isLoading: boolean;
    internalSearch: string;
    internalReplace: string;
    searchAndReplace: () => void;
}) {
    return (
        <Button isLoading={isLoading} isDisabled={!internalSearch || !internalReplace} onClick={searchAndReplace}>
            Submit
        </Button>
    );
}

export default function SearchBar({
    setSearchValues,
    placeholder,
    setReplace,
    onReplace,
    ignoredTitle,
    options
}: {
    setSearchValues: (searchOptions: SearchBarValues) => void;
    placeholder?: string;
    setReplace?: Dispatch<SetStateAction<string>>;
    onReplace?: (searchValue: string, replaceValue: string) => void;
    ignoredTitle?: string;
    options?: SearchBarOptions;
}) {
    const [searchMode, setSearchMode] = useState<"simple" | "replace" | null>("simple");
    const [hideIgnored, setHideIgnored] = useState(true);
    const [internalSearch, setInternalSearch] = useState("");
    const [internalReplace, setInternalReplace] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const debouncedSearch = useDebounce(internalSearch, { wait: LONG_DEBOUNCE });
    const debouncedReplace = useDebounce(internalReplace, { wait: QUICK_DEBOUNCE });

    useEffect(() => {
        let effectiveSearch = debouncedSearch.toLowerCase();
        effectiveSearch = effectiveSearch.length >= MIN_SEARCH_LENGTH ? effectiveSearch : "";
        setSearchValues?.({ search: effectiveSearch, hideIgnored });
    }, [debouncedSearch, hideIgnored, setSearchValues]);

    useEffect(() => {
        if (setReplace) {
            setReplace(debouncedReplace ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedReplace]);

    const searchAndReplace = () => {
        if (onReplace && setReplace) {
            setIsLoading(true);
            try {
                onReplace(debouncedSearch, debouncedReplace);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="my-2 flex w-full items-start">
            <div className="w-full space-y-2">
                <Input.Search
                    value={internalSearch}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setInternalSearch(e.target.value)}
                    placeholder={placeholder || "Search..."}
                    className="flex w-full"
                />
                {searchMode === "replace" && setReplace && (
                    <>
                        <Input.Search
                            value={internalReplace}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setInternalReplace(e.target.value)}
                            placeholder="Replace by..."
                            className="flex"
                        />
                        <SearchAndReplace
                            isLoading={isLoading}
                            internalSearch={internalSearch}
                            internalReplace={internalReplace}
                            searchAndReplace={searchAndReplace}
                        />
                    </>
                )}
            </div>
            <SearchBarSuffix
                hideIgnored={hideIgnored}
                searchMode={searchMode}
                setSearchMode={setSearchMode}
                setHideIgnored={setHideIgnored}
                options={options}
                ignoredTitle={ignoredTitle}
                setReplace={setReplace}
            />
        </div>
    );
}
