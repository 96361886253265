import { ColumnDef } from "@tanstack/react-table";
import { ReactNode } from "react";
import { SortDirection } from "../../../services";

export type { CellContext, Row, Table as TableDef } from "@tanstack/react-table";

export type Column<T extends object = object> = ColumnDef<T> & {
    accessorKey?: string;
    defaultSort?: SortDirection;
    defaultFilteredValues?: unknown;
    filters?: ColumnFilter<T>[];
    hide?: boolean;
};

export type ColumnFilter<T extends object = object> = {
    onFilter: (row: T) => boolean;
    label: string;
    value: unknown;
};

export type CustomRow = {
    getValue: () => boolean;
    getLabel: () => string;
};

export type CustomFilter<T extends object> = ColumnFilter<T> & { getValue: () => unknown; getLabel: () => string };

export function isCustomRow(value: unknown): value is CustomRow {
    return typeof value === "object" && value !== null && "getLabel" in value;
}

export function isCustomFilter<T extends object>(value: ReactNode | CustomFilter<T> | ColumnFilter<T>): value is CustomFilter<T> {
    return typeof value === "object" && value !== null && "onFilter" in value;
}

export function isColumnFilter<T extends object>(value: ReactNode | CustomFilter<T> | ColumnFilter<T>): value is ColumnFilter<T> {
    return typeof value === "object" && value !== null && "onFilter" in value;
}

export type TableRow<T> = T & { key?: keyof T | string; disabled?: boolean; render?: (isSelected?: boolean) => JSX.Element; children?: TableRow<T>[] };

export type EmptyFilter = string | null | undefined;

export const EMPTY_VALUES = ["", null, undefined, []];

export function isExpandableRow<T extends object>(obj: T, isTree?: boolean): obj is TableRow<T> {
    return !isTree && "children" in obj && obj.children !== undefined;
}

export function parseColumnId<T = unknown>(columnId: string): T {
    try {
        return JSON.parse(columnId);
    } catch {
        return columnId as T;
    }
}
