import { ReactNode } from "react";
import { CommonLang, useLang } from "../../lang";
import { useWhiteLabelContext } from "../contexts";
import { getDomainLogo } from "../tools";
import { BrandLogo } from "./BrandLogo";

export default function AuthenticationLogo<TAppCodeEnum extends Record<string, string>>({ logoSubtitle }: { logoSubtitle?: ReactNode }): JSX.Element {
    const lang = useLang<CommonLang<TAppCodeEnum>>();
    const { whiteLabelDomain, domainLogo } = useWhiteLabelContext();

    return (
        <div className="flex flex-col items-center">
            {getDomainLogo(domainLogo, whiteLabelDomain, <BrandLogo />, "w-72")}
            <h1 className="max-w-sm text-center text-lg uppercase text-slate-dark font-arial">{logoSubtitle || lang.register.slogan}</h1>
        </div>
    );
}
