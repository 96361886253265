import { Account, EMAIL_REGEX } from "@vaultinum/vaultinum-api";
import { useEffect, useState } from "react";
import { Controller, Form, Input, Modal, Separator } from "../../../design-system";
import { useLang } from "../../../lang";
import { useAuthContext } from "../../contexts";
import { useRequiredString } from "../../hooks";
import { yup } from "../../services";
import { useForm } from "../../tools";

type PartnerInvitation = {
    email: string;
    shouldNotifyByEmail: boolean;
};

export default function PartnerInvitationModalForm({
    isOpen,
    account,
    onFinish,
    partners,
    receivedInvitations,
    sentInvitations,
    okText,
    onClose
}: {
    isOpen: boolean;
    account: Account;
    onFinish: (email: string, shouldNotifyByEmail: boolean) => Promise<void> | void;
    partners?: Account.Partner[];
    receivedInvitations?: Account.PartnerInvitation[];
    sentInvitations?: Account.PartnerInvitation[];
    okText?: string;
    onClose?: () => void;
}): JSX.Element {
    const lang = useLang();
    const { userProfile } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = yup.object({
        email: useRequiredString()
            .trim()
            .matches(EMAIL_REGEX, lang.shared.invalidEmail)
            .when("userProfile", (_, schema) => {
                if (userProfile) {
                    return schema.test("notEqual", lang.shared.emailMustBeDifferentFromYours, function (value) {
                        return value !== userProfile.email;
                    });
                }
                return schema;
            })
            .when("partners", (_, schema) => {
                if (partners) {
                    return schema.test("notEqual", lang.parties.alreadyPartner, function (value) {
                        return !partners?.find(partner => partner.mainContactEmail === value);
                    });
                }
                return schema;
            })
            .when("receivedInvitations", (_, schema) => {
                if (receivedInvitations) {
                    return schema.test("notEqual", lang.parties.alreadyReceivedInvitation, function (value) {
                        return !receivedInvitations?.find(invitation => invitation.sentToEmail === value);
                    });
                }
                return schema;
            })
            .when("sentInvitations", (_, schema) => {
                if (sentInvitations) {
                    return schema.test("notEqual", lang.parties.alreadySentInvitation, function (value) {
                        return !sentInvitations?.find(invitation => invitation.sentToEmail === value);
                    });
                }
                return schema;
            })
    });

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        reset
    } = useForm<PartnerInvitation>({
        schema: validationSchema,
        mode: "onChange"
    });

    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen]);

    async function onConfirm({ email, shouldNotifyByEmail }: PartnerInvitation): Promise<void> {
        try {
            setIsLoading(true);
            await onFinish(email, shouldNotifyByEmail);
        } catch {
            throw new Error(lang.shared.inviteErrorMessage);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal
            lang={lang}
            isOpen={isOpen}
            size="md"
            title={lang.parties.newParty}
            onClose={onClose}
            isLoading={isLoading}
            onConfirm={handleSubmit(onConfirm)}
            isDisabled={!isValid}
            okText={okText || lang.parties.sendInvitation}
            children={
                <div className="m-4 flex flex-col space-y-4">
                    <span>{lang.parties.invitation(account.companyName)}</span>
                    <Separator />
                    <span>{lang.parties.inputSupplierEmail}</span>
                    <Form onSubmit={handleSubmit(onConfirm)}>
                        <Controller
                            name="email"
                            control={control}
                            data-id="email-control"
                            render={({ field }) => (
                                <Input.Email placeholder={lang.shared.email} disabled={isLoading} errorMessage={errors.email?.message} {...field} />
                            )}
                        />
                        <div className="flex place-items-center justify-between">
                            <Controller
                                name="shouldNotifyByEmail"
                                control={control}
                                data-id="notify-by-email-control"
                                render={({ field: { onChange } }) => (
                                    <Input.Checkbox id="notify-by-email" onChange={onChange} disabled={isLoading} label={lang.parties.sendInvitationByEmail} />
                                )}
                            />
                        </div>
                    </Form>
                </div>
            }
        />
    );
}
