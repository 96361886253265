import { Account, InvitationStatus, LANG_EN } from "@vaultinum/vaultinum-api";
import { Column, formatDateTime, Tag, TagInterface } from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import TableLayout from "../../../components/TableLayout";
import { getPartnerInvitations } from "../../../services/accountService";

function invitationStatusToType(status: InvitationStatus): TagInterface["type"] {
    switch (status) {
        case InvitationStatus.ACCEPTED:
            return "success";
        case InvitationStatus.PENDING:
            return "warning";
        case InvitationStatus.ACCEPTED_NOT_REGISTERED:
        case InvitationStatus.REJECTED:
            return "danger";
        case InvitationStatus.CANCELED:
        default:
            return "default";
    }
}

const partnerInvitationColumns: Column<Account.PartnerInvitation>[] = [
    {
        header: "Sent to mail",
        accessorKey: "sentToEmail"
    },
    {
        header: "Status",
        accessorKey: "status",
        cell: cell => {
            const status = cell.getValue<InvitationStatus>();
            return <Tag type={invitationStatusToType(status)} message={status} />;
        }
    },
    {
        header: "Sent date",
        accessorKey: "sentDate",
        cell: cell => formatDateTime(cell.getValue<Date>(), LANG_EN)
    }
];

export default function AccountPartnerInvitations({ account }: { account: Account }) {
    const [partnerInvitations, setPartnerInvitations] = useState<Account.PartnerInvitation[]>();
    useEffect(() => getPartnerInvitations(account.id, setPartnerInvitations), [account.id]);

    return <TableLayout<Account.PartnerInvitation> title="Partner Invitations" items={partnerInvitations} columns={partnerInvitationColumns} />;
}
