import { Account } from "@vaultinum/vaultinum-api";
import { isUndefined, omitBy } from "lodash";
import { Button, Select, TrashIcon } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";

export type SelectedGitRepositories = {
    repository: Account.GitRepository;
    selectedBranch?: string;
};

export default function RepositoryRow({
    repositories,
    selectedRepository,
    onRepositorySelected,
    onBranchSelected,
    removeRepository,
    selectedRepositories,
    selectedBranch
}: {
    repositories: Account.GitRepository[];
    selectedRepository: Account.GitRepository;
    onRepositorySelected: (oldRepo: Account.GitRepository, newRepo: Account.GitRepository, defaultBranch?: string) => void;
    onBranchSelected: (repositoryId: string, branch: string) => void;
    removeRepository: (repo: Account.GitRepository) => void;
    selectedRepositories: SelectedGitRepositories[];
    selectedBranch?: string;
}): JSX.Element {
    const lang = useLang<CommonLang>();
    const repoMenu = repositories
        .filter(
            repository =>
                !selectedRepositories
                    .filter(element => element.repository.id !== selectedRepository.id)
                    .map(element => element.repository.id)
                    .includes(repository.id)
        )
        .map(repository => ({
            ...omitBy(repository, isUndefined),
            value: repository.id,
            label: repository.name
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const branches: { value: string; label: string }[] =
        selectedRepository?.branches?.map(branch => ({
            value: branch,
            label: branch === selectedRepository?.defaultBranch ? `${branch} (${lang.git.defaultBranch})` : branch
        })) || [];

    function onItemSelected(gitRepositoryId: string | null) {
        const newRepo = repositories.find(element => element.id === gitRepositoryId);
        if (newRepo) {
            onRepositorySelected(selectedRepository, newRepo, newRepo.defaultBranch);
        }
    }

    const onDelete = () => {
        removeRepository(selectedRepository);
    };

    const selectBranch = (branch: string | null) => {
        if (!branch) {
            return;
        }
        onBranchSelected(selectedRepository.id, branch);
    };

    return (
        <div className="rounded border border-l-4 p-2 px-4 md:flex md:flex-row md:space-x-2">
            <div className="flex w-full gap-2">
                <Select.Search
                    isDisabled={repositories.length === selectedRepositories.length}
                    options={repoMenu}
                    value={selectedRepository.id}
                    label={lang.git.codeRepository}
                    onChange={onItemSelected}
                    className="w-1/2"
                />
                <Select.Search
                    options={branches}
                    label={lang.git.selectBranch}
                    value={selectedBranch || selectedRepository.defaultBranch}
                    onChange={selectBranch}
                    isDisabled={!selectedRepository}
                    className="w-1/2"
                    {...(selectedRepositories.length > 1 && {
                        rightChildren: <Button isLoading={false} fill="link" icon={TrashIcon} onClick={onDelete} />
                    })}
                />
            </div>
        </div>
    );
}
