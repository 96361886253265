import { SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import { Kys, NonDisclosureAgreement } from "@vaultinum/vaultinum-kys-api";
import {
    Alert,
    BaseLang,
    Button,
    Column,
    EditIcon,
    EyeIcon,
    Modal,
    ModalButton,
    openNotificationWithIcon,
    Spin,
    StarTwoToneIcon,
    Table,
    Tag,
    useLang,
    useModal
} from "@vaultinum/vaultinum-sdk";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Flag from "../../../components/Flag";
import { useNDAVersions } from "../../../hooks/useNDAVersions";
import { removeFullAuditAgreements } from "../../../services/fullAuditService";
import { createNewNDAVersion, publishNDAVersion } from "../../../services/referentialService";
import { EDIT_NDA_PAGE } from "../../../services/routing.service";

function getColumns({
    isEditable,
    onEdit,
    onPublish,
    isPublishInProgress,
    lang,
    doOpen,
    doClose,
    isOpen
}: {
    isEditable: (nda: NonDisclosureAgreement) => boolean;
    onEdit: (nda: NonDisclosureAgreement) => void;
    onPublish: (nda: NonDisclosureAgreement) => Promise<void>;
    isPublishInProgress: boolean;
    lang: BaseLang;
    doOpen: () => void;
    doClose: () => void;
    isOpen: boolean;
}): Column<NonDisclosureAgreement>[] {
    return [
        {
            header: "Version",
            accessorKey: "version",
            cell: cell => {
                const nda = cell.row.original;
                return (
                    <div className="flex items-center space-x-2">
                        <div children={nda.version} />
                        {isEditable(nda) && <Tag type="warning" message="DRAFT" />}
                        {nda.latest && <Tag type="info" icon={StarTwoToneIcon} />}
                    </div>
                );
            }
        },
        {
            header: "Available languages",
            accessorKey: "langs",
            cell: ({ getValue }) => {
                const publishedLangs = getValue<SupportedLanguageCode>();
                if (!publishedLangs) {
                    return null;
                }

                return (
                    <div className="flex gap-2">
                        {Object.keys(publishedLangs).map(lang => (
                            <Flag key={lang} countryCode={lang as SupportedLanguageCode} />
                        ))}
                    </div>
                );
            }
        },
        {
            header: "Actions",
            enableSorting: false,
            enableColumnFilter: false,
            cell: cell => {
                const nda = cell.row.original;
                return (
                    <div className="flex gap-2">
                        <Button onClick={() => onEdit(nda)} isLoading={false} icon={isEditable(nda) ? EditIcon : EyeIcon} />
                        {isEditable(nda) && (
                            <>
                                <Button type="default" onClick={doOpen} isLoading={false} children="Publish" />
                                <Modal
                                    isOpen={isOpen}
                                    onClose={doClose}
                                    lang={lang}
                                    title="Publish NDA version"
                                    children={
                                        <Alert.Warning message="This will publish the selected NDA version and will dismiss all acceptances of the previous versions. All the KYS users will have to re-accept the new NDA version when entering an audit" />
                                    }
                                    onConfirm={() => onPublish(nda)}
                                    isLoading={isPublishInProgress}
                                />
                            </>
                        )}
                    </div>
                );
            }
        }
    ];
}

export default function NonDisclosureAgreementView(): JSX.Element {
    const lang = useLang<BaseLang>();
    const { ndaVersions, latestPublishedNDA } = useNDAVersions();
    const [isLoading, setIsLoading] = useState(false);
    const [isPublishInProgress, setIsPublishInProgress] = useState(false);
    const { isOpen, doOpen, doClose } = useModal();

    const isEditable = (nda: NonDisclosureAgreement): boolean => !nda.latest && nda.version === (latestPublishedNDA?.version ?? 0) + 1;

    const navigate = useNavigate();

    async function onConfirm() {
        try {
            setIsLoading(true);
            const createArgs: [number, Kys.Lang<string>] = latestPublishedNDA ? [latestPublishedNDA.version + 1, latestPublishedNDA.langs] : [1, { en: "" }];
            await createNewNDAVersion(...createArgs);
        } catch (err) {
            openNotificationWithIcon({ type: "error", message: "Failed to create a new NDA version" });
        } finally {
            setIsLoading(false);
        }
    }

    function onEdit(nda: NonDisclosureAgreement): void {
        navigate(`${EDIT_NDA_PAGE}/${nda.version}`);
    }

    async function onPublish(nda: NonDisclosureAgreement): Promise<void> {
        try {
            setIsPublishInProgress(true);
            await publishNDAVersion(nda.version);
            await removeFullAuditAgreements();
        } catch (e) {
            openNotificationWithIcon({ type: "error", message: "An error occurred publishing the new version" });
        } finally {
            setIsPublishInProgress(false);
        }
    }

    if (latestPublishedNDA === undefined) {
        return <Spin />;
    }

    const columns = getColumns({
        isEditable,
        onEdit,
        onPublish,
        isPublishInProgress,
        lang,
        doOpen,
        doClose,
        isOpen
    });
    return (
        <div className="h-full w-full flex-col space-y-2">
            <ModalButton.Confirm
                buttonProps={{
                    children: "New version",
                    isLoading,
                    isDisabled: !!ndaVersions?.length && ndaVersions.length !== latestPublishedNDA?.version
                }}
                title="New NDA version"
                children={
                    ndaVersions?.length ? "This will create an unpublished copy of the latest version" : "This will create the first empty version of the NDA"
                }
                onConfirm={onConfirm}
                lang={lang}
            />
            <div className="h-full overflow-hidden">
                <Table<NonDisclosureAgreement> data={ndaVersions} columns={columns} />
            </div>
        </div>
    );
}
