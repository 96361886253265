import classNames from "classnames";
import { ReactNode } from "react";
import { Size } from "../../referentials";
import { Tooltip } from "../Tooltip/Tooltip";

export type KeyFactBaseProps = {
    title?: string;
    value?: null | ReactNode;
    prefix?: ReactNode;
    tooltipText?: string;
    grow?: boolean;
    size?: Extract<Size, "xs" | "sm" | "md">;
    displayFocus?: "value" | "title";
    onClick?: () => void;
};

export default function KeyFact({ title, value, prefix, tooltipText, grow, size, displayFocus, onClick }: KeyFactBaseProps): JSX.Element {
    const refDisplayFocus = displayFocus ?? "value";
    const titleClasses = classNames("text-slate-dark leading-5 truncate", {
        underline: !!onClick,
        "font-light": refDisplayFocus === "value",
        "font-bold": refDisplayFocus === "title",
        "text-lg": refDisplayFocus === "title" && size === "md"
    });
    const content = (
        <div className={classNames("flex items-center gap-2", { "flex-1": grow })}>
            {prefix}
            <div>
                {title && (
                    <div className="flex items-center gap-1">
                        {onClick ? <button className={titleClasses} children={title} onClick={onClick} /> : <div className={titleClasses} children={title} />}
                    </div>
                )}
                {value !== null && (
                    <div
                        className={classNames("text-slate-primary text-left", {
                            "text-xxs": refDisplayFocus === "title" && size === "xs",
                            "text-xs leading-5": refDisplayFocus === "title" && size === "sm",
                            "text-lg leading-5": refDisplayFocus === "value" && size === "sm",
                            "text-3xl": refDisplayFocus === "value" && size === "md",
                            "font-bold": refDisplayFocus === "value"
                        })}
                        children={value ?? "--"}
                    />
                )}
            </div>
        </div>
    );
    if (tooltipText) {
        return <Tooltip children={content} title={tooltipText} />;
    }
    return content;
}
