import { isDefined, UserProfile } from "@vaultinum/vaultinum-api";
import { message } from "antd";
import { groupBy, uniq } from "lodash";
import { ElementType, Fragment, useEffect, useState } from "react";
import { formatDateWithMonthDay, formatTime } from "../../../helpers";
import { useLang } from "../../../lang";
import { formatName } from "../../helpers";
import { getUserProfileFromUID } from "../../services";

export interface EventItem {
    date: Date;
    label: string;
    icon: ElementType;
    userUID?: string;
}

function EventItem({ author, label, icon, date }: { author: string; label: string; icon: ElementType; date: Date }) {
    const lang = useLang();
    const Icon: ElementType = icon;

    return (
        <div className="flex items-center">
            <div className="absolute flex items-center justify-center w-6 h-6 rounded-full -left-[11px] bg-white border-2 border-primary">
                <Icon size="xs" />
            </div>
            <div className="flex items-center gap-2 text-primary">
                <span className="text-sm font-light">{formatTime(date, lang.code)}</span>
                <span className="text-sm font-bold">{author}</span>
                <span className="text-sm">{label}</span>
            </div>
        </div>
    );
}

export default function VerticalTimeline({ events }: { events: EventItem[] }): JSX.Element {
    const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
    const lang = useLang();

    const groupedEvents = Object.entries(groupBy(events, event => formatDateWithMonthDay(event.date, lang.code)))
        .map(([date, eventItems]) => ({
            date,
            events: eventItems.sort((a, b) => b.date.getTime() - a.date.getTime())
        }))
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    useEffect(() => {
        (async () => {
            const uids = uniq(events.map(event => event.userUID).filter(isDefined));
            if (!uids.length) {
                return;
            }

            try {
                const profiles = (await Promise.all(uids.map(uid => getUserProfileFromUID(uid)))).filter(isDefined);
                setUserProfiles(profiles);
            } catch {
                message.error("Failed to fetch user profiles");
            }
        })();
    }, [events]);

    return (
        <div className="flex flex-col relative ml-16">
            <ul className="border-s-2 border-primary flex flex-col gap-4">
                {groupedEvents.map(groupedEvent => (
                    <Fragment key={groupedEvent.date}>
                        <li className="flex items-center justify-center w-32 h-8 -ml-16 bg-primary rounded-full text-white font-semibold">
                            {groupedEvent.date}
                        </li>
                        {groupedEvent.events.map(event => {
                            const userProfile = userProfiles.find(({ id }) => id === event.userUID);
                            const author = userProfile ? formatName(userProfile) : event.userUID ?? "-";
                            return (
                                <li key={event.date.getTime()} className="ml-6">
                                    <EventItem key={groupedEvent.date} author={author} date={event.date} icon={event.icon} label={event.label} />
                                </li>
                            );
                        })}
                    </Fragment>
                ))}
            </ul>
        </div>
    );
}
