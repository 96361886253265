import { Deposit, LANG_EN, sum } from "@vaultinum/vaultinum-api";
import { Button, Column, formatDateTime } from "@vaultinum/vaultinum-sdk";
import fileSize from "filesize";
import { useNavigate } from "react-router-dom";
import TableLayout from "../../../components/TableLayout";
import { DEPOSIT_DETAIL_PAGE, ESCROW_DEPOSIT_DETAIL_PAGE } from "../../../services/routing.service";
import { DepositStoreWithDeposits } from "../../../services/vaultService";

function renderActionsCell(depositStore: DepositStoreWithDeposits, redirectToDeposit: (depositStoreId: string, escrowId?: string) => void) {
    return (
        <div className="flex space-x-1">
            <Button isLoading={false} size="sm" onClick={() => redirectToDeposit(depositStore.id, depositStore.escrowId)}>
                View
            </Button>
        </div>
    );
}

export default function DepositStores({ depositStores }: { depositStores: DepositStoreWithDeposits[] }): JSX.Element {
    const navigate = useNavigate();

    const redirectToDeposit = (depositStoreId: string, escrowId: string | undefined) => {
        if (escrowId) {
            navigate(ESCROW_DEPOSIT_DETAIL_PAGE.replace(":escrowId", escrowId).replace(":depositStoreId", depositStoreId));
        } else {
            navigate(DEPOSIT_DETAIL_PAGE.replace(":depositStoreId", depositStoreId));
        }
    };

    const columns: Column<DepositStoreWithDeposits>[] = [
        {
            header: "Creation date",
            accessorKey: "creationDate",
            cell: cell => formatDateTime(cell.getValue<Date>(), LANG_EN)
        },
        {
            header: "Iddn",
            accessorFn: row => row.deposits[0].iddn
        },
        {
            header: "Name",
            accessorFn: row => row.deposits[0].name
        },
        {
            header: "Size",
            accessorFn: row =>
                fileSize(
                    row.deposits
                        .filter(deposit => [Deposit.Status.SEALED].includes(deposit.status))
                        .map(deposit => deposit.size || 0)
                        .reduce(sum, 0) || 0
                )
        },
        {
            header: "Actions",
            enableSorting: false,
            enableColumnFilter: false,
            cell: cell => renderActionsCell(cell.row.original, redirectToDeposit)
        }
    ];

    return <TableLayout<DepositStoreWithDeposits> title="Deposits" items={depositStores} columns={columns} />;
}
